import React, { type FunctionComponent, type MouseEvent } from 'react'
import './buysellbutton.scss'

interface BuySellProps {
  buy: boolean
  f: (buy: boolean) => void
}

const BuySellButton: FunctionComponent<BuySellProps> = ({ buy, f }) => {
  const handleClick = (e: MouseEvent, buyOrSell: boolean) => {
    e.preventDefault() // So this can be used in form element
    f(buyOrSell)
  }

  return (
    <div className="buysell">
      <button
        className={buy ? 'active' : ''}
        onClick={(event) => {
          handleClick(event, true)
        }}
      >
        Buy
      </button>
      <button
        className={!buy ? 'active' : ''}
        onClick={(event) => {
          handleClick(event, false)
        }}
      >
        Sell
      </button>
    </div>
  )
}

export default BuySellButton
