import { makeAutoObservable, reaction } from 'mobx'
import { Stomp } from '@stomp/stompjs'
import { toast } from 'react-toastify'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SockJS = require('sockjs-client')

export class SocketStore {
  public socket = Stomp.over(
    () => new SockJS(`${process.env.REACT_APP_API_URI}/ws`)
  )

  public connected = false
  public token: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.socket.debug = () => {}

    reaction(
      () => this.token,
      (token, prev) => {
        if (this.connected) return
        if (token !== null) {
          this.socket.connect(
            { Authorization: `Bearer ${token}` },
            this.onConnected,
            this.onError,
            this.onClose
          )
        }
      }
    )
  }

  onConnected = () => {
    this.connected = true
  }

  onError = () => {
    /* Do nothing */
  }

  onClose = () => {
    this.connected = false
    toast.error('Connection to backend lost, refresh window.', {
      autoClose: false,
    })
  }

  setToken = (token: string | null) => {
    this.token = token
  }
}
