import { useEffect, useState } from 'react'
import { type OrderEvent } from '../../classes/Order'
import { type Portfolio } from '../../classes/Portfolio'
import { getOrders } from '../../actions/Orders'
import { useSubscribe } from '../../hooks/useSubscribe'
import { type Order } from '../../classes/OrderBook'
import { Ownership } from '../../classes/Ownership'


/** This interface holds a map of newOrderId -> OrderEvent and a map from original order id -> newOrderId */
interface OrderState {
  // Map from newOrderId to OrderEvent
  orders: Map<string, OrderEvent>
  // Map from original order id to new order id
  originalToNew: Map<string, string>
}

/** Contains the logic for handling adding or deleting an order from the order map */
const addOrder = (prevState: OrderState, order: OrderEvent): OrderState => {
  // We check if we already have an instance of the same order.
  // Meaning, we take the orderId, to see if it exists in originalToMap. If it does, and we have a previous order, we check
  // If the new order should replace it. Otherwise, we add the order

  const { orders, originalToNew } = prevState

  // Check if order is active. If not we want to remove it or 'not' add it
  const isActive = order.state === 'ACTI'
  const prevOrderId = originalToNew.get(order.orderId)
  const prev = prevOrderId ? orders.get(prevOrderId) : undefined

  // If there was no previous order
  if (!prev) {
    // Only add if order is active
    if (isActive) {
      return {
        orders: new Map(orders).set(order.newOrderId, order),
        originalToNew: new Map(originalToNew).set(order.orderId, order.newOrderId)
      }
    }
    // No previous order, but it is not active, so we discard it
    return prevState
  }

  // If there exists an order, we check to see if we replace the old one.
  // We do it only if the order id is higher, or if the sequence number as become higher
  const orderIdIsUpdated = order.newOrderId.localeCompare(prev.newOrderId)
  const sequenceIdIsUpdated = order.newOrderId === prev.newOrderId && order.sequenceNo > prev.sequenceNo
  const shouldReplace = orderIdIsUpdated || sequenceIdIsUpdated

  if (!shouldReplace) return prevState

  // It should be replaced, so either way we need to delete the order newOrderId -> OrderEvent entry
  const ordersCopy = new Map(orders)
  ordersCopy.delete(prev.newOrderId)

  // Order is active we put it into map, otherwise we delete the order entry from our map
  if (isActive) {
    return {
      orders: ordersCopy.set(order.newOrderId, order),
      originalToNew: new Map(originalToNew).set(order.orderId, order.newOrderId)
    }
  }

  // Get new copied objects
  const originalToNewIdMapCopy = new Map(originalToNew)
  originalToNewIdMapCopy.delete(prev.orderId)
  return { orders: ordersCopy, originalToNew: originalToNewIdMapCopy }
}

// Default empty order state
const defaultOrderState: () => OrderState = () => {
  return { orders: new Map(), originalToNew: new Map() }
}

/** Given a contractId (product) and a portfolio, keep a map of all orders we have out */
export const useProductOrders = (contractId: string, portfolio: Portfolio) => {
  const [orders, setOrders] = useState<OrderState>(defaultOrderState)
  const [update, setUpdate] = useState(1)

  useEffect(() => {
    setOrders(defaultOrderState)
    setUpdate(prev => prev + 1)
  }, [contractId, portfolio])

  useEffect(() => {
    const controller = new AbortController()
    getOrders(controller.signal).then(allOrders => {
        const filtered = allOrders.filter(o => o.contractId === contractId && o.deliveryAreaId === portfolio.eic)
        filtered.forEach(order => { setOrders(prev => addOrder(prev, order)) })
    })
    return () => { controller.abort() }
  }, [update])

  useSubscribe('/topic/order-updates', (o: OrderEvent) => {
    if (o.contractId !== contractId || o.deliveryAreaId !== portfolio.eic) return
    setOrders(prev => addOrder(prev, o))
  }, [contractId, portfolio])

  // We return the map that goes newOrderId -> OrderEvent
  return orders.orders
}


/** Determine the ownership of an epex trade */
export const getOwnershipEpex = (orders: Map<string, OrderEvent>, strategyId: string, handle: string) => (order: Order): Ownership => {
  // Only do real check if order is ours
  const privateOrder = orders.get(order.orderId)

  // If it exists it is either OWN or COMPANY
  if (privateOrder) {
    if (privateOrder.strategyId === strategyId) return Ownership.OWN
    if (privateOrder.text === handle) return Ownership.OWN

    return Ownership.COMPANY
  }

  return Ownership.OTHER
}