import { createContext } from 'react'
import { UIStore } from './UIStore'
import { ConfigStore } from './ConfigStore'
import { OrderStore } from './OrderStore'
import { SocketStore } from './SocketStore'
import { ExecutorStore } from './ExecutorStore'
import { SpotStore } from './SpotStore'
import { TimingStore } from './TimingStore'
import { OrderBookStore } from './OrderBookStore'
import { ManualOrderPlacerStore } from './ManualOrderPlacerStore'
import { ConnectionStore } from './ConnectionStore'
import { UserStore } from './UserStore'

const configStore = new ConfigStore()
const socketStore = new SocketStore()
export const rootStoreContext = createContext({
  uiStore: new UIStore(),
  configStore,
  orderStore: new OrderStore(),
  socketStore,
  executorStore: new ExecutorStore(),
  spotStore: new SpotStore(configStore),
  timingStore: new TimingStore(),
  orderBookStore: new OrderBookStore(),
  manualOrderPlacerStore: new ManualOrderPlacerStore(),
  connectionStore: new ConnectionStore(socketStore),
  userStore: new UserStore()
})
