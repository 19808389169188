import { type Authority } from '../actions/User'
import { makeAutoObservable } from 'mobx'

// This store contains the authority of the user, that is, can the user write or read
export class UserStore {

  // Defaults to READ, the lower authority
  public authority: Authority = 'READ'

  constructor() {
    makeAutoObservable(this)
  }

  setAuthority = (authority: Authority) => {
    this.authority = authority
  }

  get canTrade() {
    return this.authority === 'WRITE'
  }
}
