import axiosInstance from '../axiosInstance'
import { type OrderEvent, type TradeEvent } from '../classes/Order'

// Get all trades
export const getTrades = async (): Promise<TradeEvent[]> => await axiosInstance
  .get<TradeEvent[]>('api/trades')
  .then(res => res.data)

// Get all orders
export const getOrders = async (signal?: AbortSignal): Promise<OrderEvent[]> => await axiosInstance
  .get<OrderEvent[]>('api/trades/orders', { signal })
  .then(res => res.data)