import React, { type FC } from 'react'
import { type Moment } from 'moment'

interface DeliveryPeriodProps {
  start: Moment
  end: Moment
}

// Formats a delivery period nicely
export const DeliveryPeriod: FC<DeliveryPeriodProps> = ({ start, end }) => {
  const format = 'DD.MM.YYYY HH.mm'
  const deliveryStart = start.format(format)
  const deliveryEnd = end.format(format)

  // If the first 10 chars (the date) are the same, only use the time part of 'end', else use full date string
  const deliveryPeriod = deliveryStart.substring(0, 10) === deliveryEnd.substring(0, 10) || deliveryEnd.includes('00.00')
    ? `${deliveryStart}-${deliveryEnd.substring(11)}`
    : `${deliveryStart}-${deliveryEnd}`

  return <div className='delivery-period'>
    <div className='info-header'>Delivery period</div>
    <div>{deliveryPeriod}</div>
  </div>
}