import './auctions.scss'
import { type BaseAuction, isAllocation, isAuction } from '../../classes/Auction'
import { useState } from 'react'
import AuctionsBar from './AuctionsBar/AuctionsBar'
import AuctionMenu from './AuctionMenu/AuctionMenu'
import AllocationMenu from './AllocationMenu/AllocationMenu'

const getMenu = (auction: BaseAuction | undefined) => {
  if (!auction) return null
  else if (isAllocation(auction)) return <AllocationMenu auction={auction} />
  else if (isAuction(auction)) return <AuctionMenu auction={auction} />
}

const Auctions = () => {
  const [auction, setAuction] = useState<BaseAuction | undefined>(undefined)

  return <div className='auctions'>
    <AuctionsBar auction={auction} setAuction={setAuction} />
    {getMenu(auction)}
  </div>
}

export default Auctions