import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../axiosInstance'
import OrderBookTable from '../../components/OrderBookTable/OrderBookTable'
import './orderBookPage.scss'
import { id, type Portfolio } from '../../classes/Portfolio'
import OrderBookSelector, { formatName } from '../../components/OrderBookSelector/OrderBookSelector'
import { fetchStrategies } from '../../components/Orders/Orders'
import { toast } from 'react-toastify'
import { STRATEGY_SETTINGS, TRADE_HANDLE } from '../../stores/ConfigStore'
import OrderBookSettings from '../../components/OrderBookSettings/OrderBookSettings'
import { v4 as uuidv4 } from 'uuid'
import { getContracts, getPortfolios } from '../../actions/Portfolios'
import { type DeliveryArea } from '../../classes/DeliveryArea'
import { getOrders, getTrades } from '../../actions/Orders'
import { useSubscribe } from '../../hooks/useSubscribe'
import useNotifications from '../../hooks/useNotifications'

export const MAX_NUMBER_OF_TABLES = 4

export class OrderBookEntry {
  constructor(
    public portfolio: Portfolio,
    public contractId: string,
    public orderBookName: string,
    public id: string = uuidv4() // This ID is only used as key for the react component
  ) {}
}

const fetchAreas = async () => await axiosInstance.get<DeliveryArea[]>('api/config/areas')

export enum Relation {
  ABOVE = -1,
  BELOW = 1,
}

const OrderBookPage = observer(() => {
  const { configStore, orderStore, connectionStore } = useStores()

  const [orderBooks, setOrderBooks] = useState<OrderBookEntry[]>([])
  const [inserting, setInserting] = useState<boolean>(true)
  const { portfolios } = configStore
  const handleNotification = useNotifications()

  /**
   * This function produces a function that swaps an order book with the one above or below it.
   * @param orderBookEntry order book to swap
   * @returns A function with the signature (relation: Relation) => void
   */
  function swapOrderBooks(orderBookEntry: OrderBookEntry) {
    function aux(relation: Relation) {
      const aIndex = orderBooks.indexOf(orderBookEntry)
      const bIndex = aIndex + relation
      const b = orderBooks[bIndex]

      if (b === undefined) return

      const newOrderBooks = [...orderBooks]
      newOrderBooks[aIndex] = b
      newOrderBooks[bIndex] = orderBookEntry
      setOrderBooks(newOrderBooks)
    }

    return aux
  }

  useEffect(() => {
    getPortfolios().then(portfolios => { configStore.setPortfolios(portfolios) })
  }, [])

  useEffect(() => {
    getOrders()
      .then(orders => {
        orders.forEach(order => {
          orderStore.updateOrders(order)
        })
      })
      .catch(() => toast.error('Could not fetch orders'))
  }, [])

  useEffect(() => {
    fetchStrategies()
      .then((resp) => {
        resp.data.forEach((invocation) => {
          orderStore.addStrategyInvocation(invocation)
        })
      })
      .catch(() => toast.error('Could not fetch current running strategies'))
  }, [])

  useEffect(() => {
    getTrades()
      .then(trades => {
        trades.forEach(trade => {
          orderStore.updateTrades(trade)
        })
      })
      .catch(() => toast.error('Could not fetch trades'))
  }, [])

  useEffect(() => {
    fetchAreas()
      .then((resp) => {
        const areas = resp.data
        configStore.setAreas(areas)
      })
      .catch(() => toast.error('Could not fetch areas'))
  }, [])

  useSubscribe(`/topic/user/${configStore.handle}`, handleNotification)
  useSubscribe('/topic/order-updates', orderStore.updateOrders)
  useSubscribe('/topic/strategies', orderStore.addStrategyInvocation)
  useSubscribe('/topic/trade', orderStore.updateTrades)

  /**
   * Insert a new order book into the orderBooks array.
   * @param portfolio the portfolio of the order book
   * @param contractId Contract id of the order book
   * @param portfolioId Portfolio id of the order book
   * @param name Name to be displayed in the order book
   */
  function insert(
    portfolio: Portfolio,
    contractId: string,
    name: string
  ) {
    setInserting(false)
    setOrderBooks((prev) => {
      if (prev.length === MAX_NUMBER_OF_TABLES) return prev

      const alreadyExists =
        prev.find((ob) => id(ob.portfolio) === id(portfolio) && ob.contractId === contractId) !==
        undefined

      if (alreadyExists) return prev

      return [...prev, new OrderBookEntry(portfolio, contractId, name)]
    })
  }

  /**
   * This function produces a function that changes the product of an order book.
   * @param idx The index of the order book entry in the orderBooks array
   * @param orderBookEntry The order book entry to change the product of
   * @returns An async function returned has the signature (next: boolean) => void, where the param next
   * referes to whether the next or previous product should be selected.
   */
  function changeProduct(
    idx: number,
    orderBookEntry: OrderBookEntry
  ): (next: boolean) => void {
    return async (next: boolean) => {
      const { portfolio } = orderBookEntry
      const currentContractId = orderBookEntry.contractId

      const contracts = await getContracts(orderBookEntry.portfolio)
      contracts.sort((a, b) => a.name.localeCompare(b.name))

      const currentContractIdx = contracts.findIndex(
        (c) => c.contractId === currentContractId
      )
      if (currentContractIdx === -1) {
        toast.error('Could not find current contract')
        return
      }

      const nextIndex = next
        ? (currentContractIdx + 1) % contracts.length
        : (currentContractIdx - 1 + contracts.length) % contracts.length

      const newContract = contracts[nextIndex]

      const newName = formatName(
        portfolios.find((p) => id(p) === id(portfolio)) as Portfolio, // Cast is relatively safe, since the portfolioId is taken from the orderBookEntry
        newContract
      )

      setOrderBooks((prev) => {
        const newOrderBooks = [...prev]
        newOrderBooks[idx] = new OrderBookEntry(
          orderBookEntry.portfolio,
          newContract.contractId,
          newName
        )
        return newOrderBooks
      })
    }
  }

  function removeOrderbook(portfolio: Portfolio, contractId: string) {
    setOrderBooks((prev) =>
      prev.filter((orderBook) => !(id(orderBook.portfolio) === id(portfolio) && orderBook.contractId === contractId))
    )

    // If the last order book is removed, assume the user wants to insert a new
    // one by setting {inserting} to true
    if (orderBooks.length !== 1) return
    setInserting(true)
  }
  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e
    if (key === TRADE_HANDLE) {
      configStore.setTradeHandle(newValue, false)
    }

    if (key === STRATEGY_SETTINGS) {
      const settings = JSON.parse(newValue)

      configStore.setStrategySettings(settings)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate)
    return () => {
      window.removeEventListener('storage', onStorageUpdate)
    }
  }, [])

  return (
    <div className="order-book-page">
      <div className="order-book-page-container">
        <OrderBookSettings />
        {orderBooks.map((orderBookEntry, i) => (
          <OrderBookTable
            key={`${orderBookEntry.id}${connectionStore.backendConnectionChanged}`}
            orderBookEntry={orderBookEntry}
            close={() => {
              removeOrderbook(orderBookEntry.portfolio, orderBookEntry.contractId)
            }}
            swap={swapOrderBooks(orderBookEntry)}
            isFirst={i === 0}
            changeProduct={changeProduct(i, orderBookEntry)}
          />
        ))}

        {portfolios.length === 0 ? (
          <h2>Loading Portfolios...</h2>
        ) : !inserting ? (
          orderBooks.length < MAX_NUMBER_OF_TABLES && (
            <button
              className="insert-button"
              onClick={() => {
                setInserting(true)
              }}
            >
              Insert Contract
            </button>
          )
        ) : (
          <OrderBookSelector
            portfolios={portfolios}
            cancel={() => {
              setInserting(false)
            }}
            insert={insert}
          />
        )}
      </div>
    </div>
  )
})

export default OrderBookPage
