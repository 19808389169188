import { type Auction } from '../../../classes/Auction'
import { type FC, Fragment, useEffect, useRef, useState } from 'react'
import { type OrderPlan, type OrderPlanStatus } from '../../../classes/OrderPlan'
import { getOrderPlans, updatePlanState } from '../../../actions/Auctions'
import { useResizeObserver } from '../../../hooks/useResizeObserver'
import { lowerCaseAllButFirst } from '../../../components/Executor/Executor'
import CurveTable from '../../../components/CurveTable/CurveTable'
import { toast } from 'react-toastify'
import './auction-menu.scss'
import { CanceledError } from 'axios'
import { useStores } from '../../../use-stores'
import { AuctionHeader } from './AuctionHeader'
import { AcceptOrReject } from './AcceptOrReject'

// Importance of different status
export const order: OrderPlanStatus[]  = ['PENDING', 'SUBMITTED', 'DISCARDED', 'REPLACED']

const planSorting = (p1: OrderPlan, p2: OrderPlan) => {
  if (p1.status === p2.status) {
    return -1 * p1.createdTime.localeCompare(p2.createdTime)
  }
  return order.indexOf(p1.status) - order.indexOf(p2.status)
}

interface AuctionMenuProps {
  auction: Auction
}

const AuctionMenu: FC<AuctionMenuProps> = ({ auction }) => {
  // Get this once on startup
  const { uiStore } = useStores()
  // Height of curve table. Equal to height of this component minus the height of the headers
  const [height, setHeight] = useState(600)

  const headerRef = useRef<any>()
  const menuRef = useResizeObserver((e) => {
    const headersHeight = headerRef.current.getBoundingClientRect().height ?? 0
    setHeight(e.contentRect.height - (headersHeight + 1))
  })

  const [plans, setPlans] = useState<OrderPlan[]>([])
  const [plan, setPlan] = useState<OrderPlan | undefined>(undefined)

  useEffect(() => {
    const controller = new AbortController()
    getPlans(controller.signal)

    return () => { controller.abort() }
  }, [auction.status, auction.auctionId, auction.latestOrderPlanCreatedTime])

  useEffect(() => {
    if (plans.length > 0) setPlan(plans[0])
    else setPlan(undefined)
  }, [plans])

  const getPlans = (signal: AbortSignal | undefined = undefined) => {
    getOrderPlans(auction, signal)
      .then(data => { setPlans(data.sort(planSorting)) })
      .catch(err => {
        if (err instanceof CanceledError) return
        setPlans([])
        toast.error('Could not get plans for auction')
      })
  }

  // Accept or reject a pending plan
  const acceptOrReject = (planId: string, state: boolean) => (done: () => void) => {
    updatePlanState(planId, state)
      .then(() => {
        toast.success('Successfully updated plan')
        getPlans()
      })
      .catch(() => { toast.error('Could not update plan state') })
      .finally(() => { done() })
  }

  return (
    <div className='auction-menu' ref={menuRef}>
      <div className='headers-wrapper' ref={headerRef}>
        <AuctionHeader auction={auction} />
        <OrderPlanHeader plan={plan}>
          <Fragment>
            {plan?.status === 'PENDING' && (<AcceptOrReject
              acceptAction={acceptOrReject(plan?.planId, true)}
              rejectAction={acceptOrReject(plan?.planId, false)}
            />)}
          </Fragment>
        </OrderPlanHeader>
      </div>
      <div className='table-container'>
        {plan && <CurveTable curves={plan.curves} style={{ useColoring: uiStore.useColoredAuctionCells }} height={height}/>}
      </div>
    </div>
  )
}

interface PlanHeaderProps {
  plan?: OrderPlan
  children: JSX.Element
}

const OrderPlanHeader: FC<PlanHeaderProps> = ({ plan, children }) => {
  if (!plan) return null

  return <div className='plan-header'>
    <div className='plan-header-elem' >
      <div style={{fontWeight: 'bold'}}>Created at:</div>
      <div>{new Date(plan.createdTime).toLocaleString()}</div>
    </div>
    {plan.submittedTime && <div className='plan-header-elem'>
      <div style={{fontWeight: 'bold'}}>Submitted at:</div>
      <div>{new Date(plan.submittedTime).toLocaleString()}</div>
    </div>}
    <div className='plan-header-elem'>
      <div>Plan status:</div>
      <div>{lowerCaseAllButFirst(plan.status)}</div>
    </div>
    {children}
  </div>
}

export default AuctionMenu