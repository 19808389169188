import React, { type FC } from 'react'

interface AuctionMenuTextInputProps {
  label: string
  value: string | number
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const AuctionMenuTextInput: FC<AuctionMenuTextInputProps> = ({ label, value, onChange }) => {
  return (
    <div className="auction-config-elem">
      <label>{label}:</label>
      <input type="text" value={value} onChange={onChange} />
    </div>
  )
}

export default AuctionMenuTextInput
