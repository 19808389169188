import React, { type FC } from 'react'
import './header.scss'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import useTimings from '../Settings/useTimings'
import { type ConnectionStatusResponse } from '../../stores/ConnectionStore'
import { Spinner } from '../Icons/Icons'
import { lowerCaseAllButFirst } from '../Executor/Executor'
import { shortPortfolioName } from '../../Util'

const logout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect().catch((e) => {
    console.log(e)
  })
}
interface ConnectionComponentProps { status: ConnectionStatusResponse }
const ConnectionComponent: FC<ConnectionComponentProps> = ({ status }) => {
  if (status.status === 'CONNECTING') {
    return <div
      title={status.title}
      style={{ display: 'flex' }}
    >
      <Spinner />
      <div style={{ marginLeft: 'unset' }}>{'Connecting...'}</div>
    </div>
  }
  if (status.status === 'DISCONNECTED') {
    return <div title={status.title}>{'Not connected'}</div>
  }

  return <div>{lowerCaseAllButFirst(status.status)}</div>
}

// Input trading tag.
const HandleInputMenu = observer(() => {
  const { configStore} = useStores()

  return <div className="handle" style={{ }}>
    {configStore.handle !== '' && <label>Trade handle</label>}
    <input
      placeholder='handle'
      type='text'
      value={configStore.handle}
      onChange={(e) => {
        configStore.setTradeHandle(e.target.value)
      }}
    />
  </div>
})

const Header = observer(() => {
  const { uiStore, connectionStore, userStore, configStore } = useStores()
  const { instance } = useMsal()
  const { sync: syncTimings } = useTimings()
  const { connectionStatus } = connectionStore

  const toggleSettings = () => {
    syncTimings()
    uiStore.toggleSettings()
  }

  // Do not show the trade handle menu if user cannot trade.
  const handleMenu = userStore.canTrade ? <HandleInputMenu /> : null

  const portfolio = configStore.getPortfolio
  const portfolioName = portfolio ? `(${shortPortfolioName(portfolio)})` : ''
  const title = `BD Energy TradeHelper ${portfolioName}`

  return (
    <div className="header">
      <div className="left">{title}</div>
      <div className="right">
        {handleMenu}
        <ConnectionComponent key={connectionStatus.status} status={connectionStatus} />
        <div className="settings-toggle" onClick={toggleSettings}>
          Settings
        </div>
        <div
          className="logout"
          onClick={() => {
            logout(instance)
          }}
        >
          Log out
        </div>
      </div>
    </div>
  )
})

export default Header
