import CapacityOverview from './CapacityOverview'
import './capacity-page.scss'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { type Portfolio } from '../../classes/Portfolio'
import { Cross } from '../../components/Icons/Icons'
import { getPortfolios } from '../../actions/Portfolios'

const CapacityPage = () => {
  // Get areas from url as starting point
  const { areas } = useParams()

  // Save list of areas (portfolios) we show
  const [areaIds, setAreaIds] = useState(areas?.split('|') ?? [])
  const [pathname, setPathname] = useState(useLocation().pathname)

  // Display add button when we hover at the bottom of the screen
  // Display portfolios when we click the add area button
  const [displayAdd, setDisplayAdd] = useState(false)
  const [displayPortfolios, setDisplayPortfolios] = useState(false)

  const [portfolios, setPortfolios] = useState<Portfolio[]>([])

  const addablePortfolios = portfolios.filter(a => !areaIds.includes(a.eic) && a.active)

  useEffect(() => {
    getPortfolios().then(portfolios => { setPortfolios(portfolios) })
  }, [])

  useEffect(() => {
    window.history.pushState({}, '', `/#${pathname}`)
  }, [pathname])

  // If the list is empty, close the window
  if (areaIds.length === 0) window.close()

  // We add area to the path state we hold, so we can add correctly to it later
  // Also to the actual path, so we keep same areas on refresh
  // And finally to areaIds, so we actually render the area
  const addArea = (eic: string) => {
    setPathname(prev => `${prev}|${eic}`)
    setAreaIds(prev =>  [...prev, eic])
  }

  const removeArea = (eic: string) => {
    setPathname(prev => prev
      .replace(`${eic}|`, '') // It is the first
      .replace(`|${eic}`, '') // It was anywhere else
    )
    setAreaIds(prev => prev.filter(a => a !== eic))
  }

  // Viewport height
  const size =  100 / areaIds.length

  const addAreaText = displayPortfolios ? <Cross /> : <div className='empty'>{'Add capacity area'}</div>

  return (
    <div className='yoyo'>
      {areaIds.map(id => (
        <CapacityOverview
          key={`area-${id}`}
          height={`calc(${size}vh - 1px)`}
          eic={id}
          remove={removeArea}
        />
      ))}
      <div
        className='overlay'
        style={{ visibility: (addablePortfolios.length > 0) ? 'visible' : 'hidden' }}
        onMouseEnter={() => { setDisplayAdd(true) }}
        onMouseLeave={() => {
          setDisplayAdd(false)
          setTimeout(() => { setDisplayPortfolios(false) }, 300) // Wait until transition is done
        }}
      >
        <div
          className={`add-capacity-area-btn${displayAdd ? ' displayed' : ' hidden'}`}
          onClick={() => { setDisplayPortfolios(prev => !prev)}}
        >
          {addAreaText}
          {displayPortfolios && (
            <div className='portfolio-list'>
              {addablePortfolios
                .map(p => (
                  <div
                    key={p.name}
                    onClick={() => { addArea(p.eic) }}
                  >{p.name.split('-')[1]}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CapacityPage