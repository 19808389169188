import React from 'react'
import './select.scss'

interface SelectProps<T> {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  element: T | undefined,
  options: T[],
  keyValue: (t: T) => string
  iconDisabled?: boolean
  name?: (t: T) => string
  defaultOption?: string
}

export const Select = <T, >(props: SelectProps<T>) => {
  const { keyValue, name, element, options, defaultOption, iconDisabled } = props

  return <span className={iconDisabled ? '' : 'select-icon'}>
    <select className='default-select' onChange={props.onChange} value={element ? keyValue(element) : ''}>
    <option hidden disabled value=''>{defaultOption}</option>
      {options.map(option => (
        <option key={keyValue(option)} value={keyValue(option)}>{name ? name(option) : keyValue(option)}</option>
      ))}
    </select>
  </span>
}