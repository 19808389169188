import { type FC, useEffect, useRef, useState } from 'react'
import './orderBookTable.scss'
import { observer } from 'mobx-react'
import { type Order, OrderBook } from '../../classes/OrderBook'
import { useStores } from '../../use-stores'
import { type IMessage } from '@stomp/stompjs'
import axiosInstance from '../../axiosInstance'
import { IBO, LIMIT, OrderRequest, type OrderRequestType, OrderRequestTypeEnum, Side } from '../../classes/OrderRequest'
import { toast } from 'react-toastify'
import { type OrderBookEntry, type Relation } from '../../pages/OrderBook/OrderBookPage'
import { type StrategyInvocationDTO } from '../../classes/Order'
import OrderBookHeader from './OrderBookHeader'
import ManualOrderPlacer from '../ManualOrderPlacer/ManualOrderPlacer'
import OrderBookTopBar from './OrderBookTopBar'
import OrderBookRows from './OrderBookRows'
import { canBeCancelled } from '../Orders/Orders'
import { type Trade } from '../../classes/Trade'
import { BidFilledIndicator } from './BidFilledIndicator'
import { id, type Portfolio } from '../../classes/Portfolio'
import { useSubscribe } from '../../hooks/useSubscribe'
import { Ownership } from '../../classes/Ownership'
import { getOwnershipEpex, useProductOrders } from './useProductOrders'
import { isValidTradeHandle } from '../Executor/ExecutorHelpers'

const OTHER = 'OTHER'
const STRATEGY_ID_PREFIX = 'orderbook:'

interface OrderBookDeltaUpdateEvent {
  contractId: string
  areaId: number
  orderBookDelta: OrderBookDelta
}

interface OrderBookDelta {
  buyOrders: Order[]
  sellOrders: Order[]
  contractId: string
  deliveryAreaId: number
  revisionNo: number
}

interface OrderBookState {
  orderBook: OrderBook | undefined
  backlog: OrderBookDeltaUpdateEvent[]
}

export const formatPrice = (price: number): string => {
  return (price / 100.0).toLocaleString('dk-DA', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const formatQty = (qty: number): string => {
  return (qty / 1000).toLocaleString('dk-DA', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  })
}

export class RowEntry {
  constructor(
    public id: string,
    public ownership: Ownership,
    public fillsQty: boolean,
    public price: number,
    public qty?: number
  ) {}
}

/**
 * Sort by price descending, then by createdAt ascending
 */
function bidCompare(a: Order, b: Order): number {
  if (a.price !== b.price) return b.price - a.price

  return a.createdAt.localeCompare(b.createdAt)
}

/**
 * Sort by price ascending, then by createdAt ascending
 */
function askCompare(a: Order, b: Order): number {
  if (a.price !== b.price) return a.price - b.price

  return a.createdAt.localeCompare(b.createdAt)
}

function determineOrderRequestType(orderRequestTypeEnum: OrderRequestTypeEnum, clipSize: number): OrderRequestType {
  switch (orderRequestTypeEnum) {
    case OrderRequestTypeEnum.LIMIT:
      return new LIMIT()
    case OrderRequestTypeEnum.IBO:
      return new IBO(clipSize)
  }
}

interface OrderBookTableProps {
  orderBookEntry: OrderBookEntry
  close: () => void
  swap: (relation: Relation) => void
  isFirst: boolean
  changeProduct: (next: boolean) => void
}

const OrderBookTable: FC<OrderBookTableProps> = observer(({ orderBookEntry, close, swap, isFirst, changeProduct }) => {
  const { portfolio, contractId, orderBookName } = orderBookEntry
  const { socketStore, configStore, orderStore, orderBookStore, manualOrderPlacerStore } = useStores()
  const { socket, connected } = socketStore
  const { handle } = configStore
  const { orders, strategies } = orderStore
  const [orderBook, setOrderBook] = useState<OrderBookState>({
    orderBook: undefined as OrderBook | undefined,
    backlog: [] as OrderBookDeltaUpdateEvent[],
  })
  const { orderRequestTypeEnum, defaultQuantities, orderBookQtyLimit, clipSize } = orderBookStore
  // Quantities for the header
  // BoC (bid) | Q1 (bid) | Q2 (bid) | Q3 (bid) | Q1 (ask) | Q2 (ask) | Q3 (ask) | BoC (ask) | DoP (bid) | DoP (ask)
  const [quantities, setQuantities] = useState(defaultQuantities)
  const [latestTrade, setLatestTrade] = useState<Trade | undefined>(undefined)
  const scrollElementRef = useRef<HTMLDivElement>(null)
  const orderRequestType = determineOrderRequestType(orderRequestTypeEnum, clipSize)
  const areaId = portfolio.eic

  const ownedStrategyId = `${STRATEGY_ID_PREFIX}${id(portfolio)}:${contractId}:${handle}`
  const ownStrategies = strategies
    .filter((s) => id(s.portfolio) === id(portfolio))
    .filter((s) => s.invocation.contractId === contractId)
    .filter((s) => s.invocation.tradeHandle === handle)

  // Keep a map of our order for checking the ownership in EPEX order books
  const ordersForProduct = useProductOrders(contractId, portfolio)
  const bidRowEntries = orderBook.orderBook ? orderBookToRows(orderBook.orderBook, true) : []
  const askRowEntries = orderBook.orderBook ? orderBookToRows(orderBook.orderBook, false) : []

  // Send notification if this portfolio disconnects from the backend.
  useSubscribe('/topic/portfolios', (p: Portfolio) => {
    if (!p.active && id(p) === id(portfolio)) {
      toast.error(`${p.name} (${p.exchange.replace('_', ' ')}) disconnected.`, { autoClose: false })
    }
  })

  function isOwnedByTrader(order: Order): boolean {
    const orderEvent = orders.find((o) => o.newOrderId === order.orderId)

    if (orderEvent === undefined) return false

    const isDirectlyOwned = orderEvent.strategyId === ownedStrategyId
    const isOwnedByOwnStrategy = ownStrategies.some((s) => s.id === orderEvent.strategyId)

    return isDirectlyOwned || isOwnedByOwnStrategy
  }

  function determineOwnership(order: Order): Ownership {
    if (order === undefined || order.ownership === OTHER) {
      return Ownership.OTHER
    } else if (isOwnedByTrader(order)) return Ownership.OWN
    else return Ownership.COMPANY
  }

  function orderBookToRows(orderBook: OrderBook, bids: boolean): RowEntry[] {
    // Sort orders
    const ordersSorted = bids ? orderBook.buys.sort(bidCompare) : orderBook.sells.sort(askCompare)

    // Get line quantities
    const lineQuantity = bids ? quantities[1] : quantities[4]

    // Find index of first order that is above the line quantity
    const lineIndex = ordersSorted.reduce<{ idx: number; qty: number }>(
      ({ idx, qty: qtyAcc }, o, i) => {
        const qty = qtyAcc + (o.qty ?? 0)

        if (idx !== -1) return { idx, qty: qtyAcc } // If we have already found the index, return it
        if (qty >= lineQuantity) return { idx: i, qty } // If the quantity is above the line quantity, return the index
        return { idx: -1, qty } // If the quantity is below the line quantity, add the quantity and return -1
      },
      { idx: -1, qty: 0 }
    ).idx

    // Pick function based on Exchange
    const findOwnership: (order: Order) => Ownership =
      portfolio.exchange === 'NORD_POOL'
        ? determineOwnership
        : getOwnershipEpex(ordersForProduct, ownedStrategyId, handle)

    return ordersSorted.map((order, idx) => {
      const ownership = findOwnership(order)
      const qty = ownership !== Ownership.OWN ? order.qty : ordersForProduct.get(order.orderId)?.quantity
      return new RowEntry(order.orderId, ownership, lineIndex === idx, order.price, qty)
    })
  }

  function isValidTrade(qty: number): boolean {
    if (!isValidTradeHandle(handle)) {
      toast.error('Please set a trade handle in the settings')
      return false
    }

    if (qty > orderBookQtyLimit) {
      toast.error(`Quantity is too large. Max trade quantity is currently set to: ${orderBookQtyLimit / 1000}MW`)
      return false
    }

    return true
  }

  async function submitTrade(isBuy: boolean, price?: number, qty?: number) {
    if (price === undefined || qty === undefined) {
      toast.error('Price or quantity is undefined')
      return
    }

    if (!isValidTrade(qty)) return

    const side = isBuy ? Side.BUY : Side.SELL

    const orderRequest = new OrderRequest(
      portfolio.exchange,
      ownedStrategyId,
      [contractId],
      portfolio.eic,
      side,
      handle,
      price,
      qty,
      orderRequestType
    )

    const res = await axiosInstance.post('api/trades/trade', orderRequest)

    if (res.status === 200) {
      return
    }

    toast.error('Could not submit trade')
  }

  async function submitBoCTrade(isBuy: boolean, limit?: number, qty?: number) {
    if (limit === undefined || qty === undefined) {
      toast.error('limit or quantity is undefined')
      return
    }

    const increment = configStore.getSettingsValue('increment')

    if (increment === undefined) {
      toast.error('Please set increment in the main Tradehelper window under settings')
      return
    }

    if (!isValidTrade(qty)) return

    const variables = {
      '@type': 'BestOrderContinuous',
      quantity: qty,
      increment,
      limit,
      orderType: orderBookStore.orderRequestTypeEnum,
      clipSize: orderBookStore.clipSize
    }

    const invocation: StrategyInvocationDTO = {
      exchange: portfolio.exchange,
      contractId,
      areaId: portfolio.eic,
      buy: isBuy,
      tradeHandle: handle,
      variables,
    }

    // Post strategy invocation
    axiosInstance.post('api/strategies', invocation).catch((err) => {
      const error = err.response.data.message // If it is a custom error from backend, find this message
      if (error) toast.error(error)
      else toast.error(err.message)
    })
  }

  function updateOrderBook(orderBookDelta: OrderBookDelta, prevOrderBook: OrderBook): OrderBook {
    const updateOrders = (newOrder: Order, prevOrders: Order[]) => {
      const prevIndex = prevOrders.findIndex((o) => o.orderId === newOrder.orderId)

      if (prevIndex > -1 && newOrder.deleted) prevOrders.splice(prevIndex, 1)
      else if (prevIndex > -1) prevOrders[prevIndex] = newOrder
      else if (!newOrder.deleted) prevOrders.push(newOrder)
    }

    const newBuys = prevOrderBook.buys
    orderBookDelta.buyOrders.forEach((buy) => {
      updateOrders(buy, newBuys)
    })

    const newSells = prevOrderBook.sells
    orderBookDelta.sellOrders.forEach((sell) => {
      updateOrders(sell, newSells)
    })

    const ask = newSells.reduce<Order | undefined>((acc, sell) => {
      if (acc === undefined) return sell
      if (askCompare(acc, sell) > 0) return sell
      return acc
    }, undefined)

    const bid = newBuys.reduce<Order | undefined>((acc, buy) => {
      if (acc === undefined) return buy
      if (bidCompare(acc, buy) > 0) return buy
      return acc
    }, undefined)

    return new OrderBook(newBuys, newSells, orderBookDelta.revisionNo, ask, bid)
  }

  function cancelAll() {
    ownStrategies
      .filter((strategy) => canBeCancelled(strategy))
      .forEach((strategy) => {
        axiosInstance.put(`api/strategies/${strategy.id}/cancel`)
      })

    axiosInstance.put(`api/strategies/${ownedStrategyId}/cancel`)
  }

  /**
   * Process backlog of order book events
   * @param prevOrderBook The previous order book to be updated
   * @param backlog The backlog of [OrderBookDeltaUpdateEvent].
   *
   * @returns A new [OrderBookState] with the (possibly) updated order book and backlog
   */
  function processBacklog(prevOrderBook: OrderBook, backlog: OrderBookDeltaUpdateEvent[]): OrderBookState {
    let updatedOrderBook = prevOrderBook

    // Sorting function for deltas uses revision numbers for ordering
    const deltaSort = (a: OrderBookDeltaUpdateEvent, b: OrderBookDeltaUpdateEvent) =>
      a.orderBookDelta.revisionNo - b.orderBookDelta.revisionNo

    // Copy deltas
    const copy = [...backlog]
    // Apply them to order book -> only take the revisions that are newer than current order book
    copy
      .sort(deltaSort)
      .filter((delta) => {
        const condition = delta.orderBookDelta.revisionNo > prevOrderBook.latestRevision
        if (!condition) {
          console.log(
            `Skipping update with ${delta.orderBookDelta.revisionNo} as it is not GT ${prevOrderBook.latestRevision}`
          )
        }
        return condition
      })
      .forEach((delta) => {
        if (delta.orderBookDelta.revisionNo !== updatedOrderBook.latestRevision + 1) {
          console.log(
            `Applying delta ${delta.orderBookDelta.revisionNo} to orderbook with revision ${updatedOrderBook.latestRevision}`
          )
        }
        updatedOrderBook = updateOrderBook(delta.orderBookDelta, updatedOrderBook)
      })

    // Return updated order book en empty backlog
    return { orderBook: updatedOrderBook, backlog: [] }
  }

  useEffect(() => {
    if (!connected) return
    const topic = `/topic/order-book/${portfolio.exchange}/${portfolio.eic}/${contractId}`

    const conn = socket.subscribe(topic, (message: IMessage) => {
      const event: OrderBookDeltaUpdateEvent = JSON.parse(message.body)

      setOrderBook((prev) => {
        // Check if the order book has been fetched yet
        if (prev.orderBook === undefined) {
          // The order book has not been fetched yet, fill backlog instead of updating
          return {
            orderBook: undefined,
            backlog: [...prev.backlog, event],
          }
        }

        // Order book has been fetched, update it
        return processBacklog(prev.orderBook, [...prev.backlog, event])
      })
    })

    return conn.unsubscribe
  }, [connected])

  useSubscribe(`/topic/ticker/${portfolio.eic}/${contractId}`, (trade: Trade) => {
    setLatestTrade(trade)
  })

  useEffect(() => {
    axiosInstance
      .get<OrderBook>(`api/order-books/${portfolio.exchange}/${portfolio.eic}/${contractId}`)
      .then((res) => res.data)
      .then((newOrderBook) => {
        setOrderBook((prev) => processBacklog(newOrderBook, prev.backlog))
      })
  }, [])

  useEffect(() => {
    const { exchange, eic } = portfolio
    axiosInstance
      .get<Trade | undefined>(`api/public-trades/latest?exchange=${exchange}&contractId=${contractId}&eicCode=${eic}`)
      .then((res) => res.data)
      .then((trade) => {
        setLatestTrade((prev) => {
          if (prev !== undefined) return prev
          return trade
        })
      })
  }, [])

  return (
    <div className="order-book-table">
      <div className="table-container">
        <OrderBookTopBar
          areaEic={portfolio.eic}
          contractId={contractId}
          swap={swap}
          orderBookName={orderBookName}
          cancelAll={cancelAll}
          isFirst={isFirst}
          changeProduct={changeProduct}
          close={close}
        />
        <BidFilledIndicator ownedStrategyId={ownedStrategyId} ownStrategies={ownStrategies} latestTrade={latestTrade} />
        {manualOrderPlacerStore.contractId === contractId && manualOrderPlacerStore.areaId === areaId && <ManualOrderPlacer />}
        <OrderBookHeader
          quantities={quantities}
          setQuantities={setQuantities}
          areaEic={portfolio.eic}
          contractId={contractId}
        />
        <div ref={scrollElementRef} className="body">
          <OrderBookRows
            bidRowEntries={bidRowEntries}
            askRowEntries={askRowEntries}
            submitTrade={submitTrade}
            submitBoCTrade={submitBoCTrade}
            contractId={contractId}
            quantities={quantities}
            scrollElementRef={scrollElementRef}
            areaId={areaId}
          />
        </div>
      </div>
    </div>
  )
})

export default OrderBookTable
