import React, { type CSSProperties, useState } from 'react'
import { useSubscribe } from '../../hooks/useSubscribe'
import { type OMT } from '../../classes/OrderManagementTransactionCount'
import './nordpool-menu.scss'

const CRITICAL = 9000 // Our limit for one hour is 10K

// This component shows information relevant to Nord Pool exchange
const NordPoolMenu = () => {
  const [omt, setOmt] = useState<OMT | undefined>(undefined)

  useSubscribe('/topic/nord-pool/omt', (omt: OMT) => {
    setOmt(old => {
      if (old && old.timestamp.localeCompare(omt.timestamp)) return omt
      if (!old) return omt
      return old
    })
  })

  if (!omt) return null

  // Color it red if we are close to being disconnected
  const coloring: CSSProperties = omt.count > CRITICAL ? { color: 'red' } : {}

  return <div className='nord-pool-menu-wrapper'>
    <div className='nord-pool-menu'>
      <div className='title'>Nord Pool</div>
      <div style={coloring} title={'Number of orders created/modified in last hour'}>
        <span>{" - OMT: "}</span>
        {omt.count}
      </div>
    </div>
  </div>
}

export default NordPoolMenu