import React, { type FC, Fragment, type SetStateAction, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { lowerCaseAllButFirst, splitPascalCase, upperCaseFirstLetter, type Variables } from './Executor'
import {
  isNumberField,
  isOffsetField,
  isSelectField,
  type NumberField,
  NumberFieldType,
  type OffsetField,
  type StrategySchema,
} from '../../classes/StrategySchema'
import { NumberFieldComponent, SelectFieldComponent } from './Fields'
import BuySellButton from '../BuySellButtons/BuySellButton'
import { getMultiplier } from '../../Util'
import { type Dictionary, type StrategyInvocationDTO } from '../../classes/Order'
import { type Portfolio } from '../../classes/Portfolio'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import { max, min } from '../PortfolioTable/PortfolioTable'
import useKeyDownListener from '../../hooks/useKeyDownListener'
import { defaultAssignSelectFields, hasError, setSelect, setVariable, isValidTradeHandle } from './ExecutorHelpers'
import { Select } from '../Select/Select'

interface ExecutorProps {
  schema: StrategySchema
  setSchema: (s: StrategySchema | undefined) => void
}

const validateBase = (portfolio: Portfolio | undefined, tradeHandle: string) => {
  return portfolio !== undefined && isValidTradeHandle(tradeHandle)
}

const validateVariables = (
  schema: StrategySchema | undefined,
  invocation: Dictionary<any> | undefined,
  multiPart: Dictionary<MultiPriceDecider>
) => {
  if (schema === undefined || invocation === undefined) return false
  if (schema.fields.some((f) => hasError(f.name, invocation[f.name]))) return false
  return schema.fields.every((field) => invocation[field.name] !== undefined || multiPart[field.name] !== undefined)
}

// Prices can either be offset from spot, bid, ask or just an absolute value
type MultiPriceDeciderType = 'Spot' | 'Bid' | 'Ask' | 'Value'
// Combination of offset type and offset value
interface MultiPriceDecider {
  decider: MultiPriceDeciderType
  value: number
}

type PropField = NumberField | OffsetField
interface FieldProps {
  field: PropField
  variables: Dictionary<MultiPriceDecider>
  setVariable: React.Dispatch<SetStateAction<Dictionary<MultiPriceDecider>>>
  options: MultiPriceDeciderType[]
}

const MultiNumberField: FC<FieldProps> = ({ field, variables, setVariable, options }) => {
  const [priceDecider, setPriceDecider] = useState(options[0])
  const [offsetValue, setOffsetValue] = useState<number | undefined>(undefined)

  // Only set variable when offsetValue is set
  useEffect(() => {
    if (offsetValue !== undefined) {
      setVariable((dict) => {
        const tmp = { ...dict }
        tmp[field.name] = { decider: priceDecider, value: offsetValue }
        return tmp
      })
    }
  }, [priceDecider, offsetValue])

  const multiplier = getMultiplier(NumberFieldType.EUR)
  return (
    <div key={field.name} className="field-group">
      <label title={isNumberField(field) ? field.type : ''}>{upperCaseFirstLetter(field.name)}</label>
      <div className='multi-field'>
        <Select
          onChange={e => setPriceDecider(e.target.value as MultiPriceDeciderType)}
          element={priceDecider}
          options={options}
          keyValue={n => n}
        />
        <div className='eur' style={{ width: 'unset' }}>
        <input
          value={offsetValue !== undefined ? offsetValue / multiplier : undefined}
          type="number"
          onChange={(e) => {
            setOffsetValue(Number(e.target.value) * multiplier)
          }}
        />
        </div>
      </div>
    </div>
  )
}

const MultiExecutor: FC<ExecutorProps> = observer(({ schema, setSchema }) => {
  const [variables, setVariables] = useState<Variables>(
    defaultAssignSelectFields(schema.fields, { '@type': schema.name })
  )
  const [multiNumberVariables, setMultiNumberVariables] = useState<Dictionary<MultiPriceDecider>>(
    {} as Dictionary<MultiPriceDecider>
  )
  const { configStore, socketStore, spotStore } = useStores()
  const { getPortfolio, pickedProducts, handle } = configStore

  const [buy, setBuy] = useState(false)

  if (schema.name === 'MultiLayerBestOrderContinuous') {
    setSchema(undefined)
  }

  const description =
    getPortfolio != null ? `For ${pickedProducts.length} contracts in ${getPortfolio.name}` : 'No product picked'

  const submitEnabled =
    validateBase(getPortfolio, handle) &&
    pickedProducts.length > 1 &&
    validateVariables(schema, variables, multiNumberVariables) &&
    socketStore.connected

  // We submit strategy on 'Enter'
  useKeyDownListener(
    'Enter',
    () => {
      if (submitEnabled) submitStrategyInvocations()
    },
    [schema]
  )

  // Check which options are available for fields.
  const allProductsHaveSpot = () =>
    pickedProducts
      .map((p) => (getPortfolio?.eic ? spotStore.getSpotPrice(getPortfolio.eic, p.deliveryStart) : undefined))
      .every((s) => s !== undefined)

  const allProductsHaveBid = () => pickedProducts.every((p) => p.bid !== min)
  const allProductsHaveAsk = () => pickedProducts.every((p) => p.ask !== max)

  // Check that all the multi decider type offset exists for all picked products.
  const checkMultiFieldValidity = () => {
    for (const key of Object.keys(multiNumberVariables)) {
      const priceDecider = multiNumberVariables[key].decider

      switch (priceDecider) {
        case 'Spot':
          if (!allProductsHaveSpot()) return priceDecider
          break
        case 'Bid':
          if (!allProductsHaveBid()) return priceDecider
          break
        case 'Ask':
          if (!allProductsHaveAsk()) return priceDecider
      }
    }
    return undefined
  }

  // Submit all strategy invocations
  const submitStrategyInvocations = () => {
    const portfolio = getPortfolio
    // Need to check this for typescript
    if (portfolio == null || pickedProducts.length < 2 || variables == null) return

    // We check if we have all the required values for using an offset
    const error = checkMultiFieldValidity()

    if (error) {
      toast.error(`Missing values: Cannot use '${error}' as offset.`)
      return
    }

    // DTOs to post
    const invocations: Array<StrategyInvocationDTO | undefined> = pickedProducts.map((product) => {
      const invocationVariables = { ...variables }

      // Use this to check if something goes wrong in the forEach below
      let error: boolean = false

      // For each multi number variable, calculate the correct price
      Object.keys(multiNumberVariables).forEach((key) => {
        const priceDecider = multiNumberVariables[key]

        let price: number | undefined

        if (priceDecider.decider === 'Ask') {
          price = product.ask + priceDecider.value
        } else if (priceDecider.decider === 'Bid') {
          price = product.bid + priceDecider.value
        } else if (priceDecider.decider === 'Spot') {
          const spotPrice = spotStore.getSpotPrice(portfolio.eic, product.deliveryStart)
          if (spotPrice === undefined) console.log(`Could not find spot price for ${product.name} in ${portfolio.name}`)
          else {
            price = spotPrice.price + priceDecider.value
          }
        } else if (priceDecider.decider === 'Value') {
          price = priceDecider.value
        }

        // If price is undefined, we failed for spot price. Should not happen as the option does not appear without prices defined
        if (price === undefined) {
          toast.error(`Could not place strategy for ${product.name}.`)
          error = true
        } else {
          invocationVariables[key] = price
        }
      })

      if (error) return undefined

      return {
        exchange: portfolio.exchange,
        contractId: product.contractId,
        areaId: portfolio.eic,
        buy,
        tradeHandle: handle,
        variables: invocationVariables,
      }
    })

    const promises = invocations
      .filter((s) => s !== undefined) // Filter out bad ones
      .map(async (invocation) => {
        return await axiosInstance.post('api/strategies', invocation)
      })

    // If just one went through, we reset the state
    Promise.any(promises).then(() => {
      configStore.clearPickedProducts()
      setSchema(undefined)
    })

    // For every error, toast the message we got
    Promise.allSettled(promises).then((results) => {
      results.forEach((result) => {
        if (result.status === 'rejected') {
          const err = result.reason
          const error = err.response.data.message // If it is a custom error from backend, find this message
          if (error) toast.error(error)
          else toast.error(err.message)
        }
      })
    })
  }

  // We skip MW fields and increment
  const isMultiNumberField = (field: NumberField) =>
    (field.type === NumberFieldType.EUR && field.name !== 'increment') || isOffsetField(field)

  return (
    <Fragment>
      <div className="heading">
        <p className="strategy-name">{lowerCaseAllButFirst(splitPascalCase(schema.name).join(' '))}</p>
        <p>{description}</p>
      </div>

      <div>
        <div className="form">
          {schema.fields.map((field) => {
            if ((isNumberField(field) && isMultiNumberField(field)) || isOffsetField(field)) {
              return (
                <MultiNumberField
                  key={field.name}
                  field={field}
                  variables={multiNumberVariables}
                  setVariable={setMultiNumberVariables}
                  options={['Spot', 'Bid', 'Ask', 'Value']}
                />
              )
            } else if (isNumberField(field)) {
              return (
                <NumberFieldComponent
                  key={field.name}
                  field={field}
                  variables={variables}
                  setVariable={setVariable(setVariables)}
                />
              )
            } else if (isSelectField(field)) {
              return <SelectFieldComponent key={field.name} field={field} setSelect={setSelect(setVariables)} />
            } else return <Fragment key={field.name}></Fragment>
          })}
          <div className="field-group">
            <BuySellButton buy={buy} f={setBuy} />
            <button disabled={!submitEnabled} className="place-order-button" onClick={submitStrategyInvocations}>
              {`Place ${buy ? 'buy' : 'sell'}`}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
})

export default MultiExecutor
