import { SimpleCronExpression } from '../../CronParser'
import { type Timing } from '../../stores/TimingStore'

const TimingEntry = ({
  timing,
  removeTiming,
}: {
  timing: Timing
  removeTiming: () => void
}) => {
  const startCronExpression = SimpleCronExpression.fromCronExpression(
    timing.start
  )
  const endCronExpression = SimpleCronExpression.fromCronExpression(timing.end)

  return (
    <div className="timing-input">
      <label>
        <b>{timing.trigger}</b>
      </label>
      <div className="controls" onClick={removeTiming}>
        ✕
      </div>
      <div className="timing-info">
        <table className="timing-info">
          <thead>
            <tr>
              <td></td>
              <td>Hour</td>
              <td>Minute</td>
              <td>Second</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="firstColumn">Start</td>
              <td>{startCronExpression.hour}</td>
              <td>{startCronExpression.minute}</td>
              <td>{startCronExpression.second}</td>
            </tr>
            <tr>
              <td className="firstColumn">End</td>
              <td>{endCronExpression.hour}</td>
              <td>{endCronExpression.minute}</td>
              <td>{endCronExpression.second}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TimingEntry
