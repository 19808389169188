import React from 'react'

interface ModifiableCellProps<T extends number> {
  disabled: boolean
  original: T
  modified: T
  equal: (a: T, b: T) => boolean,
  onChange: React.InputHTMLAttributes<any>['onChange']
  divisor: number,
  suffix?: string
  className?: React.HTMLAttributes<T>['className']
}

// This represents a table cell that can be modified
export const ModifiableCell = <T extends React.InputHTMLAttributes<T>['value']>(
  { disabled, original, modified, equal, divisor, onChange, className }: ModifiableCellProps<number>,
) => {
  const hasBeenModified = !equal(original, modified)

  // If disabled, we just render a regular div
  if (disabled) {
    return <div className={className}>
      {(original / divisor).toString()}
    </div>
  }

  return <div
    className={className}
    style={{ display: 'flex' }}
  >
    <div
      style={{
        visibility: hasBeenModified ? 'visible' : 'hidden',
        fontStyle: 'italic',
        color: 'var(--text-secondary)',
        width: '25%',
      }}
    >{original / divisor}</div>

    <input
      type='number'
      value={(modified / divisor).toString()}
      onChange={onChange}
      style={{
        justifySelf: 'center',
        fontWeight: hasBeenModified ? 'bolder' : '',
        width: '50%',
      }}
    />
  </div>
}