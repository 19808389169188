export class OrderBook {
  constructor(
    public buys: Order[],
    public sells: Order[],
    public latestRevision: number,
    public ask?: Order,
    public bid?: Order
  ) {}
}

export interface Order {
  orderId: string
  price: number
  qty?: number
  createdAt: string
  deleted: boolean
  ownership: string
}

export interface IOrderBook {
  buys: Order[]
  sells: Order[]
  latestRevision: number
}