import type { HalfAuction } from '../../classes/Interconnector'
import type { AuctionSide } from './SpreadRow/SpreadRow'
import { curriedCalculatePosition } from './SpreadRow/calculatePosition'
import type { Product } from '../../classes/Product'
import type { Portfolio } from '../../classes/Portfolio'
import type { SpreadTrade } from '../../classes/SpreadTrade'
import { getPortfolioDivisor, volumeReducer } from './calculationHelpers'

// Get loss function based on the auction and auction side
export const getLossFunction = (halfAuction: HalfAuction, side: AuctionSide) => {
  const { lossFactorStrategy, lossFactor, importRoundingStrategy, exportRoundingStrategy } = halfAuction

  // If we import, we subtract loss factor. If we export, we either have full position or we add loss factor
  switch (side) {
    case "export":
      return lossFactorStrategy === 'BOTH' ? curriedCalculatePosition(lossFactor, exportRoundingStrategy) : (n: number) => n
    case "import":
      return curriedCalculatePosition(-lossFactor, importRoundingStrategy)
  }
}

// Get list of mid-interconnector nominations that would explain [value]
const findAllPossibleNominations = (halfAuction: HalfAuction, side: AuctionSide, value: number) => {
  if (value === 0) return [0]

  const calculation = getLossFunction(halfAuction, side)
  const validNominations: number[] = []

  // Track nomination value and the position we are at
  let nomination = 0
  let position = 0

  while (position <= value) {
    position = calculation(nomination += 100)

    if (position === value) {
      validNominations.push(nomination)
    }
  }

  return validNominations
}

/**
 * Calculate the mid-interconnector nomination based on the imbalances
 * - If no value matches both import and export side, it returns the maximum value combined for both sides
 * @param importPortfolio
 * @param exportPortfolio
 * @param halfAuction describes the loss factor calculation step
 * @param trades all trades for given halfAuction
 * @param contracts contracts split into export and import lists
 */
export const calculateMidPointNomination = (
  importPortfolio: Portfolio,
  exportPortfolio: Portfolio,
  halfAuction: HalfAuction,
  trades: SpreadTrade[],
  contracts: { 'exports': Product[], 'imports': Product[] }
) => {
  const importTrades = trades
    .filter(t => t.area === halfAuction.import)
    .filter(t => contracts.imports.map(c => c.contractId).includes(t.contractId))

  const exportTrades = trades
    .filter(t => t.area === halfAuction.export)
    .filter(t => contracts.exports.map(c => c.contractId).includes(t.contractId))

  const importVolume = importTrades.reduce(volumeReducer, 0) / getPortfolioDivisor(importPortfolio)
  const exportVolume = exportTrades.reduce(volumeReducer, 0) / getPortfolioDivisor(exportPortfolio)

  // Do not count balances in the wrong direction
  const importNominations = importVolume < 0 ? findAllPossibleNominations(halfAuction, 'import', Math.abs(importVolume)) : [0]
  const exportNominations = exportVolume > 0 ? findAllPossibleNominations(halfAuction, 'export', Math.abs(exportVolume)) : [0]
  const combined = [...new Set([...exportNominations, ...importNominations])]

  for (const value of combined) {
    if (exportNominations.includes(value) && importNominations.includes(value)) {
      return value
    }
  }

  return Math.max(...combined)
}