import { type SpotPrice } from '../classes/SpotPrice'
import { makeAutoObservable, reaction } from 'mobx'
import { type ConfigStore } from './ConfigStore'
import { toast } from 'react-toastify'
import { getSpotPrices } from '../actions/SpotPrices'

export class SpotStore {
  public spotPrices: SpotPrice[] = []

  private readonly configStore: ConfigStore

  constructor(configStore: ConfigStore) {
    makeAutoObservable(this)
    this.configStore = configStore

    reaction(
      () => this.configStore.getPortfolio,
      (portfolio, prev) => {
        if (portfolio != null && portfolio !== prev) {
          getSpotPrices(portfolio)
            .then(spotPrices => { this.setSpotPrices(spotPrices) })
            .catch(() =>
              toast.error(`Could not fetch spot prices for ${portfolio.name}`)
            )
        }
      }
    )
  }

  private readonly setSpotPrices = (prices: SpotPrice[]) => {
    this.spotPrices = prices
  }

  /**
   * Get spot price for specific area and delivery start combination
   * @param eicCode
   * @param deliveryStart
   */
  getSpotPrice = (eicCode: string, deliveryStart: string) => {
    return this.spotPrices.find(
      (price) =>
        price.eic === eicCode && price.deliveryStart === deliveryStart
    )
  }
}
