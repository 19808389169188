import { type FC, useEffect, useState } from 'react'
import { Row, HCell } from '../Table/Table'
import { Side } from '../../classes/OrderRequest'
import { useStores } from '../../use-stores'
import { type StrategyInvocation } from '../../classes/Order'
import { type Trade } from '../../classes/Trade'

const FILLED_INDICATOR_TIMEOUT = 500

interface BidFilledIndicatorProps {
  ownedStrategyId: string
  ownStrategies: StrategyInvocation[]
  latestTrade: Trade | undefined
}

export const BidFilledIndicator: FC<BidFilledIndicatorProps> = ({ ownedStrategyId, ownStrategies, latestTrade }) => {
  const { orderStore, orderBookStore, configStore } = useStores()
  const { trades } = orderStore
  const { orderFilledSoundEnabled, latestTradePriceEnabled } = orderBookStore
  const { areas } = configStore
  const [bidFilled, setBidFilled] = useState(false)
  const [askFilled, setAskFilled] = useState(false)
  const [lastBidFilled, setLastBidFilled] = useState(-1)
  const [lastAskFilled, setLastAskFilled] = useState(-1)
  const [bidFilledTimeout, setBidFilledTimeout] = useState(0)
  const [askFilledTimeout, setAskFilledTimeout] = useState(0)
  const latestTradePrice = latestTrade !== undefined ? latestTrade.price / 100 : '-'
  const buyArea = areas.find((a) => a.deliveryAreaId === latestTrade?.buyArea)?.areaCode
  const sellArea = areas.find((a) => a.deliveryAreaId === latestTrade?.sellArea)?.areaCode

  /**
   * Called when an order is filled to update the timeouts for the filled indicators
   */
  function orderFilled(isBuy: boolean) {
    const now = Date.now()
    const newTimeout = now + FILLED_INDICATOR_TIMEOUT

    // Play sound to indicate that an order has been filled
    if (orderFilledSoundEnabled) {
      const orderFilledAudio = new Audio('/audio/order-filled.mp3')
      orderFilledAudio.play()
    }

    if (isBuy) {
      setBidFilledTimeout((prev) => Math.max(prev, newTimeout))
    } else {
      setAskFilledTimeout((prev) => Math.max(prev, newTimeout))
    }
  }

  /**
   * If the timeout is ahead of the current time, set the filled state to true.
   * Otherwise, set it to false
   */
  function handleTimeout(filledTimeout: number, setFilled: (b: boolean) => void) {
    const now = new Date().getTime()
    const diff = filledTimeout - now

    if (diff < 0) {
      setFilled(false)
      return
    }

    setFilled(true)

    const timeout = setTimeout(() => {
      setFilled(false)
    }, diff)

    return () => {
      clearTimeout(timeout)
    }
  }

  /**
   * Update filled indicators for both bid and ask
   */
  function updateFillTimeouts() {
    updateFillTimeout(true)
    updateFillTimeout(false)
  }

  /**
   * Update filled indicator for either bid or ask
   * Finds appropriate trades owned by trader and updates the filled indicator
   * if a new trade has been made since the last update.
   */
  function updateFillTimeout(isBuy: boolean) {
    const lastFilled = isBuy ? lastBidFilled : lastAskFilled
    const side = isBuy ? Side.BUY : Side.SELL

    const ownedTrades = trades
      .filter((t) => t.direction === side)
      .filter((t) => t.strategyId === ownedStrategyId || ownStrategies.some((s) => s.id === t.strategyId))

    const latestBid = ownedTrades.reduce((acc, t) => {
      const timeParsed = Date.parse(t.tradeTime)
      return Math.max(timeParsed, acc)
    }, 0)

    // If this is the first update, set the last filled to the latest bid and return
    if (lastFilled === -1) {
      if (isBuy) {
        setLastBidFilled(latestBid)
      } else {
        setLastAskFilled(latestBid)
      }
      return
    }

    if (latestBid <= lastFilled) {
      return
    }

    if (isBuy) {
      setLastBidFilled((prev) => Math.max(prev, latestBid))
    } else {
      setLastAskFilled((prev) => Math.max(prev, latestBid))
    }

    orderFilled(isBuy)
  }

  updateFillTimeouts()

  useEffect(() => handleTimeout(bidFilledTimeout, setBidFilled), [bidFilledTimeout])

  useEffect(() => handleTimeout(askFilledTimeout, setAskFilled), [askFilledTimeout])

  return (
    <div className="header">
      <Row>
        <HCell className="side">
          <div className="center">Bid</div>
          {bidFilled && <div className="dot-right">🟢</div>}
        </HCell>
        <>
          {latestTradePriceEnabled && (
            <HCell className="side">
              <div className="dot-left">{buyArea}</div>
              <div className="center">{latestTradePrice}</div>
              <div className="dot-right">{sellArea}</div>
            </HCell>
          )}
        </>
        <HCell className="side">
          <div className="center">Ask</div>
          {askFilled && <div className="dot-left">🔴</div>}
        </HCell>
      </Row>
    </div>
  )
}
