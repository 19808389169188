import { observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useStores } from '../../use-stores'
import { id } from '../../classes/Portfolio'
import { UpArrow } from '../Icons/Icons'
import { groupBy, shortPortfolioName } from '../../Util'
import './area-tree.scss'

const shortExchangeName = {
  'NORD_POOL': 'NPool', 'EPEX': 'EPEX',
}

const AreaTree = observer(() => {
  // Default to everything open
  const [closed, setClosed] = useState<Set<string>>(new Set())

  const { configStore } = useStores()
  const { getPortfolio, portfolios, setPortfolio } = configStore
  const grouped = groupBy(portfolios, p => p.exchange)

  useEffect(() => {
    if (!getPortfolio) {
      configStore.setPortfolio(0)
    }
  }, [portfolios])

  const isPicked = (pId: string) => getPortfolio ? id(getPortfolio) === pId : false

  return <div className='area-tree'>
    <ul>
      {[...grouped.entries()]
        .map(([exchange, children]) => {
          const isOpen = !closed.has(exchange)

          const onClickHandler = () => {
            setClosed(prev =>
              prev.has(exchange)
                ? new Set([...prev].filter(e => e !== exchange))
                : new Set([...prev, exchange])
            )
          }

          return <Fragment key={exchange}>
            <li className='grouping' onClick={onClickHandler}>
              {shortExchangeName[exchange].replace('_', ' ')}
              <UpArrow className={`button-icon ${isOpen ? 'opened' : 'closed'}`} />
            </li>

            {isOpen && children.map(p => (
              <li
                key={p.name}
                className={isPicked(id(p)) ? 'picked' : 'clickable'}
                onClick={() => {
                  setPortfolio(portfolios.findIndex(p2 => id(p2) === id(p)))
                }}
              >
                <span className='indentation'>{'  '}</span>
                <div className='portfolio-name' title={p.name}>
                  {shortPortfolioName(p)}
                </div>
                <span className='button-icon' />
              </li>
            ))}
          </Fragment>
        })}
    </ul>
  </div>
})

export default AreaTree