import { type AllocationAuction } from '../../../classes/Auction'
import { type FC, Fragment, useRef, useState } from 'react'
import { AuctionHeader } from '../AuctionMenu/AuctionHeader'
import { useResizeObserver } from '../../../hooks/useResizeObserver'
import { type AllocationBid, type OrderPlanStatus } from '../../../classes/OrderPlan'
import { getAllocationBids, getOfferedCapacity } from '../../../actions/Auctions'
import AllocationTable from '../../../components/AllocationTable/AllocationTable'
import { AcceptOrReject } from '../AuctionMenu/AcceptOrReject'
import { observer } from 'mobx-react'
import { useAbortController } from '../../../hooks/useAbortController'
import { AllocationAuctionStore } from './AllocationAuctionStore'
import { AllocationAuctionHeader } from './AllocationAuctionHeader'
import { changeStatus, saveChanges } from './helpers'
import { errorHandler } from '../../SpreadHelper/errorHandler'

// If all plans have the same status, return it. Else, return undefined.
const getSingleStatusOrNone = (bids: AllocationBid[]) => {
  const statuses = new Set<OrderPlanStatus>()
  bids.forEach(bid => statuses.add(bid.status))
  if (statuses.size === 1) return [...statuses][0]
  else return undefined
}

interface AllocationMenuProps {
  auction: AllocationAuction
}

const AllocationMenu: FC<AllocationMenuProps> = observer(({ auction }) => {
  const [store] = useState(() => new AllocationAuctionStore())

  // Height of curve table. Equal to height of this component minus the height of the headers
  const [height, setHeight] = useState(600)
  const headerRef = useRef<any>()

  const menuRef = useResizeObserver((e) => {
    const headersHeight = headerRef.current.getBoundingClientRect().height ?? 0
    setHeight(e.contentRect.height - (headersHeight + 1))
  })

  // Update bids when status changes
  useAbortController(controller => {
    if (auction.status !== 'NONE') getBids(controller.signal)
  }, [auction.status, auction.updateTime])

  // Clear bids and fetch new bids when we change auction
  useAbortController(controller => {
    store.clear()
    if (auction.status !== 'NONE') getBids(controller.signal)
    if (auction.status !== 'NONE') getOfferedCapacities(controller.signal)
  }, [auction.id])

  const getBids = (signal: AbortSignal) => {
    getAllocationBids(auction, signal)
      .then(bids => store.addBids(bids))
      .catch(errorHandler('Could not get bids for allocation auction'))
  }

  const getOfferedCapacities = (signal: AbortSignal) => {
    getOfferedCapacity(auction, signal)
      .then(capacities => store.setOfferedCapacities(capacities))
      .catch(errorHandler('Could not get offered capacity'))
  }

  const discardChanges = (done: () => void) => {
    store.resetModifications()
    done()
  }
  
  let acceptMenu
  if (Object.keys(store.modifiedBids).length > 0) {
    acceptMenu = <AcceptOrReject
      acceptAction={saveChanges(store, auction)}
      rejectAction={discardChanges}
      acceptName={'Save'}
      rejectName={'Discard'}
    />
  } else if (getSingleStatusOrNone(store.actualBids) === 'PENDING') {
    acceptMenu = <AcceptOrReject
      acceptAction={changeStatus(store, 'SUBMITTED')}
      rejectAction={changeStatus(store, 'DISCARDED')}
    />
  } else {
    acceptMenu = null
  }

  return <div className='auction-menu' ref={menuRef}>
    <div className='headers-wrapper' ref={headerRef}>
      <AuctionHeader auction={auction} />
      <AllocationAuctionHeader bids={store.actualBids}>
        <Fragment>{acceptMenu}</Fragment>
      </AllocationAuctionHeader>
    </div>
    <div className='table-container'>
      {store.actualBids.length > 0 && <AllocationTable store={store} height={height} />}
    </div>
  </div>
})

export default AllocationMenu