export interface NumberField {
  '@type': 'NumberField'
  name: string
  type: NumberFieldType
}

export enum NumberFieldType {
  INT = 'INT',
  EUR = 'EUR',
  MW = 'MW',
}

export interface SelectField {
  '@type': 'SelectField'
  name: string
  options: string[]
}

export interface OffsetField {
  '@type': 'OffsetNumberField'
  name: string
}

export interface ListField {
  '@type': 'ListField'
  name: string
  listType: Field[]
}

export type Field = NumberField | SelectField | OffsetField | ListField

export interface StrategySchema {
  name: string
  fields: Field[]
}

/**
 * Check whether a Field is a NumberField or not.
 * @param field
 */
export function isNumberField(field: Field): field is NumberField {
  return field['@type'] === 'NumberField'
}

export function isSelectField(field: Field): field is SelectField {
  return field['@type'] === 'SelectField'
}

export function isOffsetField(field: Field): field is OffsetField {
  return field['@type'] === 'OffsetNumberField'
}

export function isListField(field: Field): field is ListField {
  return field['@type'] === 'ListField'
}
