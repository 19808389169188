import { observer } from 'mobx-react'
import OrderBookRowsSide from './OrderBookRowsSide'
import { type RowEntry } from './OrderBookTable'
import { type FC } from 'react'

interface OrderBookRowsProps {
  bidRowEntries: RowEntry[]
  askRowEntries: RowEntry[]
  submitTrade: (isBuy: boolean, price?: number, qty?: number) => Promise<void>
  submitBoCTrade: (isBuy: boolean, limit?: number, qty?: number) => Promise<void>
  contractId: string
  quantities: number[]
  scrollElementRef: React.RefObject<HTMLDivElement>
  areaId: string
}

const OrderBookRows: FC<OrderBookRowsProps> = observer(
  ({ bidRowEntries, askRowEntries, submitTrade, submitBoCTrade, contractId, quantities, scrollElementRef, areaId }) => {
    return (
      <>
        <div className="rows">
          <OrderBookRowsSide
            rows={bidRowEntries}
            isBids={true}
            submitTrade={submitTrade}
            submitBoCTrade={submitBoCTrade}
            contractId={contractId}
            quantities={quantities}
            scrollElementRef={scrollElementRef}
            areaId={areaId}
          />
        </div>
        <div className="rows">
          <OrderBookRowsSide
            rows={askRowEntries}
            isBids={false}
            submitTrade={submitTrade}
            submitBoCTrade={submitBoCTrade}
            contractId={contractId}
            quantities={quantities}
            scrollElementRef={scrollElementRef}
            areaId={areaId}
          />
        </div>
      </>
    )
  }
)

export default OrderBookRows
