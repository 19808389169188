import axios, { type AxiosError } from 'axios'

// Check a promise settled result is fulfilled
const isFulfilled = <T, >(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> =>
  result.status === 'fulfilled'

// Check that a promise settled result is rejected
const isRejected = (result: PromiseSettledResult<any>): result is PromiseRejectedResult =>
  result.status === 'rejected'

class Settled<T, > {
  readonly results: Array<PromiseSettledResult<T>>

  constructor(values: Array<PromiseSettledResult<T>>) {
    this.results = values
  }

  // Perform action on fulfilled promises. Returns this object
  fulfilled(lambda: (t: T[]) => void) {
    lambda(this.results.filter(isFulfilled).map(promise => promise.value))
    return this
  }

  // Perform action on rejected promises as AxiosErrors. Returns this object
  rejected(lambda: (reasons: Array<AxiosError<T, any>>) => void) {
    const errors = this.results
      .filter(isRejected)
      .filter(r => axios.isAxiosError(r.reason))
      .map(r => r.reason as AxiosError<T, any>)
    lambda(errors)
    return this
  }
}

// Convenience function for creating Settled object
export const settled = <T, >(values: Array<PromiseSettledResult<T>>) => {
  return new Settled(values)
}