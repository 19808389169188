import type { Portfolio } from '../classes/Portfolio'
import { useState } from 'react'
import type { Product } from '../classes/Product'
import { useAbortController } from './useAbortController'
import { getContracts } from '../actions/Portfolios'
import { errorHandler } from '../pages/SpreadHelper/errorHandler'

// Get all contracts, is empty list until the state is fetched
export const useContracts = (portfolio: Portfolio | undefined) => {
  const [contracts, setContracts] = useState<Product[]>([])

  useAbortController(controller => {
    if (portfolio === undefined) return

    getContracts(portfolio, controller.signal)
      .then(contracts => setContracts(contracts))
      .catch(errorHandler(`Could not get contracts for ${portfolio.name}`))
  }, [portfolio])

  return contracts
}
