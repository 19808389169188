import { makeAutoObservable } from 'mobx'
import {
  type Field,
  isNumberField,
  type StrategySchema,
  isOffsetField,
} from '../classes/StrategySchema'

/**
 * Filter for finding unique fields.
 */
const unique = (value: Field, index: number, self: Field[]) =>
  self.findIndex((x) => x.name === value.name) === index

export class ExecutorStore {
  public schemas: StrategySchema[] = []
  // This is the currently picked schema
  public schema: StrategySchema | undefined

  constructor() {
    makeAutoObservable(this)
  }

  setSchemas = (schemas: StrategySchema[]) => {
    this.schemas = schemas
  }

  setSchema = (schema: StrategySchema | undefined) => {
    this.schema = schema
  }

  get getAllNumberFields() {
    const fields = this.schemas
      .flatMap((schema) => schema.fields)
      .filter((schema) => isNumberField(schema))
      .filter(unique)

    return fields
  }

  get getAllOffsetFields() {
    const fields = this.schemas
      .flatMap((schema) => schema.fields)
      .filter((field) => isOffsetField(field))
      .filter(unique)

    return fields
  }
}
