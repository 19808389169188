import './active-orders.scss'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { Cell, HCell, Row } from '../Table/Table'
import { formatProductName } from '../../Util'
import { Cross } from '../Icons/Icons'
import { useState } from 'react'
import { type OrderEvent } from '../../classes/Order'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'

// Cancel order by chain id
const cancelOrder = async (chainId: string) =>
  await axiosInstance
    .post(`api/trades/orders/${chainId}/deactivate`)
    .catch(() => toast.error('Could not cancel order'))

// OrderEvent with an additional portfolio field
type OrderWithArea = OrderEvent & { portfolio?: string }

interface Column {
  name: string
  key: keyof OrderWithArea
}

const columns: Column[] = [
  { name: 'Prod', key: 'product' },
  { name: 'Area', key: 'portfolio' },
  { name: 'Dir', key: 'direction' },
  { name: 'Px', key: 'price' },
  { name: 'Qty', key: 'quantity' },
  { name: 'Tag', key: 'text' }
]


// This component shows a table of all active orders in trade helper
const ActiveOrders = observer(() => {
  const { orderStore, configStore } = useStores()
  const { orders} = orderStore
  const { portfolios } = configStore

  const [ascending, toggleAscending] = useState(true)
  const [sorting, setSorting] = useState<keyof OrderWithArea>('product')

  const changeSorting = (key: keyof OrderWithArea) => {
    setSorting(old => {
      if (old === key) {
        toggleAscending(curr => !curr)
        return key
      } else return key
    })
  }

  const rows = orders.filter(o => o.state === 'ACTI')
    .map(o => {
      // Enrich order events with portfolio name
      return {...o, portfolio: portfolios.find(p => p.eic === o.deliveryAreaId)?.name } as OrderWithArea
    })
    .sort((a, b) => {
      const res = (a[sorting] ?? -1) >= (b[sorting] ?? -1)
      return ascending ? res ? 1 : -1 : res ? -1 : 1
    })

  return <div className='active-orders'>
    <div style={{textAlign: 'left', fontWeight: 'bold'}}>Active orders</div>
    <div className="table">
      <div className="table-header" style={{display: 'flex', flexDirection: 'column'}}>
        <Row>
          <HCell></HCell>
          <>
          {columns.map(column => {
            const dir = sorting === column.key ? ascending ? '\u25BF' : '\u25B5' : ''
            const name = column.name + dir
            return (
              <HCell
                key={column.name}
                onClick={() => { changeSorting(column.key) }}
              >
                {name}
              </HCell>
            )
          })}
          </>
        </Row>
      </div>
      <div className='outer-body'>
        <div className='body' style={{height: `calc(${rows.length + 1} * var(--cell-height))`}}>
          {rows.map(order =>
            <Row key={order.orderId}>
              <Cell title={`Deactivate ${order.newOrderId}`} onClick={async () => { await cancelOrder(order.chainId) }}><Cross /></Cell>
              <Cell>{formatProductName(order.product ?? '')}</Cell>
              <Cell>{order.portfolio?.split('-')[1] ?? order.portfolio}</Cell>
              <Cell>{order.direction}</Cell>
              <Cell>{(order.price ?? 0) / 100}</Cell>
              <Cell>{(order.quantity ?? 0) / 1000}</Cell>
              <Cell>{order.text}</Cell>
            </Row>
          )}
        </div>
      </div>
    </div>
  </div>
})

export default ActiveOrders