import type { ExchangeRate, HalfAuction, TransmissionRightAndMarginalPrice } from '../classes/Interconnector'
import axiosInstance from '../axiosInstance'
import { type Moment } from 'moment/moment'
import type { SpreadOrder } from '../classes/SpreadOrder'
import type { SpreadTrade } from '../classes/SpreadTrade'
import type { Currency } from '../classes/Currency'

// Base path, and path for specific halfAuction
const base = 'api/interconnectors'
const path = (halfAuction: HalfAuction) => `${base}/${halfAuction.corridor}`

// Get all interconnectors
export const getInterconnectors = async (date: Moment, signal: AbortSignal) => await axiosInstance
  .get<HalfAuction[]>(base, { signal, params: { deliveryDate: date.format('YYYY-MM-DD') } })
  .then(res => res.data)

// Get transmission rights and marginal prices for an interconnector auction
export const getTransmissionInfo = async (date: Moment, halfAuction: HalfAuction, signal: AbortSignal) => await axiosInstance
  .get<TransmissionRightAndMarginalPrice[]>(
    `${path(halfAuction)}/transmission-info`,
    { signal, params: { deliveryDate: date.format('YYYY-MM-DD') } }
  )
  .then(res => res.data)

export const placeSpreadOrder = async (placeSpread: SpreadOrder) => await axiosInstance
  .post<string>(`${base}/trade`, placeSpread)
  .then(res => res.data)

// Get interconnector trades
export const getTrades = async (date: Moment, signal: AbortSignal | undefined) => await axiosInstance
  .get<SpreadTrade[]>(`${base}/trades`, { signal, params: { deliveryDate: date.format('YYYY-MM-DD') } })
  .then(res => res.data)

export const getExchangeRate = async (from: Moment, to: Moment, currency: Currency, signal: AbortSignal) => {
  return await axiosInstance
    .get<ExchangeRate[]>(`${base}/exchange-rates`,
      { signal, params: { 'from': from.toISOString(), 'to': to.toISOString(), currency } })
    .then(res => res.data)
}