import { makeAutoObservable } from 'mobx'
import { type Product } from '../classes/Product'
import { type TableUpdateEvent } from '../classes/TableUpdateEvent'
import { type Dictionary } from '../classes/Order'
import { type DeliveryArea } from '../classes/DeliveryArea'
import { exchanges, id, type Portfolio } from '../classes/Portfolio'

export const STRATEGY_SETTINGS = 'STRATEGY_SETTINGS'
export const TRADE_HANDLE = 'TRADE_HANDLE'

export class ConfigStore {
  allPortfolios: Portfolio[] = []
  public portfolio: number | undefined = undefined
  public products: Product[] = []
  public pickedProducts: Product[] = []
  public handle = localStorage.getItem(TRADE_HANDLE) || ''
  public areas: DeliveryArea[] = []
  private readonly localStorageSettings = localStorage.getItem(STRATEGY_SETTINGS)

  public strategySettings: Dictionary<number> | undefined = undefined
  public bidIndex = -1

  constructor() {
    makeAutoObservable(this)

    // If there is a strategy settings object in local storage, then populate this store with it
    if (this.localStorageSettings !== null) {
      this.strategySettings = JSON.parse(
        this.localStorageSettings
      ) as Dictionary<number>
    }
  }

  setTradeHandle = (handle: string, updateLocalStorage = true) => {
    this.handle = handle
    if (!updateLocalStorage) return
    localStorage.setItem('TRADE_HANDLE', handle)
  }

  updateSettingsField = (name: string, value: number | undefined) => {
    const dict = this.strategySettings != null ? this.strategySettings : {}

    // Value is undefined, it means it was deleted completely. So delete the value's entry from the dictionary.
    if (value === undefined) {
      delete dict[name]
    } else {
      dict[name] = value
    }

    localStorage.setItem(STRATEGY_SETTINGS, JSON.stringify(dict))
    this.strategySettings = dict
  }

  getSettingsValue = (name: string) => {
    if (this.strategySettings != null) return this.strategySettings[name]
    else return undefined
  }

  setStrategySettings = (settings: Dictionary<number>) => {
    this.strategySettings = settings
  }

  setPortfolios = (portfolios: Portfolio[]) => {
    this.allPortfolios = portfolios.sort((a, b) => a.name.localeCompare(b.name))
  }

  // Update portfolio with new version or add portfolio
  updatePortfolio = (portfolio: Portfolio) => {
    // Get the id of the currently picked portfolio
    const idPicked = this.getPortfolio ? id(this.getPortfolio) : undefined
    const existing = this.portfolios
    const updated = [...existing.filter(p => id(p) !== id(portfolio)), portfolio]
    // If the chosen portfolio becomes inactive, we set the portfolio to 'undefined'
    if (idPicked) {
      const isStillThere = updated.find(p => p.active && id(p) === idPicked)
      if (!isStillThere) {
        this.portfolio = undefined
      }
    }
    this.allPortfolios = updated.sort((a, b) => a.name.localeCompare(b.name))
  }

  // Get all active portfolios
  get portfolios() {
    return this.allPortfolios.filter(p => p.active)
  }

  setPortfolio = (idx: number) => {
    this.portfolio = idx
    this.pickedProducts = []
  }

  get getPortfolio() {
    return this.portfolio !== undefined
      ? this.portfolios[this.portfolio]
      : undefined
  }

  setProduct = (id: string | undefined) => {
    const productToAdd = this.products.find(p => p.contractId === id)
    if (productToAdd) {
      this.pickedProducts = [productToAdd]
    }
  }

  setProducts = (products: Product[]) => {
    this.products = products
  }

  setPickedProducts = (id: string) => {
    const isAlreadyPicked = this.pickedProducts.some(p => p.contractId === id)
    const pickedProducts = [...this.pickedProducts]
    if (isAlreadyPicked) {
      this.pickedProducts = pickedProducts.filter(p => p.contractId !== id)
    }
    else {
      const productToAdd = this.products.find(p => p.contractId === id)
      if (productToAdd) {
        this.pickedProducts = [...pickedProducts, productToAdd]
      }
    }
  }

  clearPickedProducts = () => {
    this.pickedProducts = []
  }

  updateProduct = (tableUpdateEvent: TableUpdateEvent) => {
    const productToUpdate = this.products.find(
      (p) => p.contractId === tableUpdateEvent.contractId
    )
    if (productToUpdate != null) {
      const products = this.products.filter(
        (p) => p.contractId !== tableUpdateEvent.contractId
      )
      productToUpdate.ask = tableUpdateEvent.ask
      productToUpdate.bid = tableUpdateEvent.bid
      products.push(productToUpdate)
      this.setProducts(products)
    }
  }

  // Return product if only one product is picked
  get getProduct() {
    return this.pickedProducts.length === 1 ? this.pickedProducts[0] : undefined
  }

  setAreas = (areas: DeliveryArea[]) => {
    this.areas = areas
  }

  // Get available exchanges.
  get exchanges() {
    return [...new Set(this.portfolios.map(p => p.exchange))]
      .sort((a, b) => exchanges.indexOf(a) - exchanges.indexOf(b))
  }
}
