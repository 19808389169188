import { useState } from 'react'
import { Timing } from '../../stores/TimingStore'

function TimeSelect({
  amount,
  time,
  setTime,
  includeAsterisk = true,
}: {
  amount: number
  time: string
  setTime: (time: string) => void
  includeAsterisk?: boolean
}) {
  const options = []
  if (includeAsterisk) {
    options.push(
      <option key={'*'} value="*">
        *
      </option>
    )
  }

  for (let i = 0; i < amount; i++) {
    options.push(
      <option key={i} value={i.toString()}>
        {i}
      </option>
    )
  }

  return (
    <select
      onChange={(e) => {
        setTime(e.target.value)
      }}
      value={time}
    >
      {options}
    </select>
  )
}

class TimeTriple {
  hour: string = '*'
  minute: string = '*'
  second: string = '0'
}

const TimingForm = ({
  closeForm,
  addTiming,
}: {
  closeForm: () => void
  addTiming: (timing: Timing) => void
}) => {
  const [start, setStart] = useState(new TimeTriple())
  const [end, setEnd] = useState(new TimeTriple())

  const [triggerName, setTriggerName] = useState('')

  function clearForm() {
    setStart(new TimeTriple())
    setEnd(new TimeTriple())
    setTriggerName('')
  }

  function clearAndCloseForm() {
    clearForm()
    closeForm()
  }

  function toCron(hour: string, minute: string, second: string) {
    return `${second} ${minute} ${hour} * * *`
  }

  function save() {
    const startCron = toCron(start.hour, start.minute, start.second)
    const endCron = toCron(end.hour, end.minute, end.second)
    const timing = new Timing(triggerName, startCron, endCron)

    addTiming(timing)

    clearAndCloseForm()
  }

  return (
    <div className="timing-input">
      <label>
        <input
          className="trigger-input"
          placeholder="Trigger name"
          type='text'
          onChange={(e) => {
            setTriggerName(e.target.value)
          }}
          value={triggerName}
        />
      </label>
      <div onClick={clearAndCloseForm}>✕</div>
      <div className="timing-info">
        <table className="timing-info">
          <thead>
            <tr>
              <td></td>
              <td>Hour</td>
              <td>Minute</td>
              <td>Second</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Start</td>
              <td>
                <TimeSelect
                  amount={24}
                  time={start.hour}
                  setTime={(time: string) => {
                    setStart(Object.assign({}, start, { hour: time }))
                  }}
                />
              </td>
              <td>
                <TimeSelect
                  amount={60}
                  time={start.minute}
                  setTime={(time: string) => {
                    setStart(Object.assign({}, start, { minute: time }))
                  }}
                />
              </td>
              <td>
                <TimeSelect
                  amount={60}
                  time={start.second}
                  setTime={(time: string) => {
                    setStart(Object.assign({}, start, { second: time }))
                  }}
                  includeAsterisk={false}
                />
              </td>
            </tr>
            <tr>
              <td className="firstColumn">End</td>
              <td>
                <TimeSelect
                  amount={24}
                  time={end.hour}
                  setTime={(time: string) => {
                    setEnd(Object.assign({}, end, { hour: time }))
                  }}
                />
              </td>
              <td>
                <TimeSelect
                  amount={60}
                  time={end.minute}
                  setTime={(time: string) => {
                    setEnd(Object.assign({}, end, { minute: time }))
                  }}
                />
              </td>
              <td>
                <TimeSelect
                  amount={60}
                  time={end.second}
                  setTime={(time: string) => {
                    setEnd(Object.assign({}, end, { second: time }))
                  }}
                  includeAsterisk={false}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <button disabled={triggerName.length === 0} onClick={save}>
          Save
        </button>
      </div>
    </div>
  )
}

export default TimingForm
