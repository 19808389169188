import { type FC, useState } from 'react'
import { Spinner } from '../../../components/Icons/Icons'

interface AcceptOrRejectProps {
  acceptAction: (done: () => void) => void
  rejectAction: (done: () => void) => void
  acceptName?: string
  rejectName?: string
  height?: number | string
}

export const AcceptOrReject: FC<AcceptOrRejectProps> = ({ acceptAction, rejectAction, acceptName, rejectName, height }) => {
  const accept = acceptName ?? 'Accept'
  const reject = rejectName ?? 'Reject'
  const [loading, setLoading] = useState(false)

  // Set loading to false when operation finishes
  const callAction = (fun: (done: () => void) => void) => {
    setLoading(true)
    fun(() => {
      setLoading(false)
    })
  }

  // Add padding (2 top/bottom) and border height (1 top/bottom) to accept-reject and accept-reject-buttons
  const outerHeight = height ? { height: `calc(${height} + 6px)` } : {}

  return <div className='accept-reject' style={outerHeight}>
    <div className='accept-reject-buttons' style={outerHeight}>
      <div className='reject clickable' style={{ height, lineHeight: height }} onClick={() => {
        callAction(rejectAction)
      }}>{reject}
      </div>
      <div className='accept clickable' style={{ height, lineHeight: height }} onClick={() => {
        callAction(acceptAction)
      }}>{accept}
      </div>
    </div>
    {loading && <Spinner />}
  </div>
}