import { observer } from 'mobx-react'
import './manualOrderPlacer.scss'
import { useStores } from '../../use-stores'
import { useEffect, useRef } from 'react'
import { Cell, Row } from '../Table/Table'

interface Values {
  price: number
  quantity: number
  buy: boolean
}

const ManualOrderPlacer = observer(() => {
  const { uiStore, manualOrderPlacerStore, orderBookStore } = useStores()

  const { triggerCounter, buy, price, quantity, submitTrade, contractId } =
    manualOrderPlacerStore
  const { directOrdersEnabled } = orderBookStore

  const quantityRef = useRef<HTMLInputElement | null>(null)
  const values = getValues()

  function handlePlaceOrder() {
    if (values === null) return

    const { price, quantity, buy } = values
    submitTrade(buy, price, quantity)

    manualOrderPlacerStore.setContractId(undefined)
  }

  function handleQuantityClick(event: any) {
    event.target.select()
  }

  function handlePriceClick(event: any) {
    event.target.select()
  }

  function handleQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
    const quantity = event.target.value
    manualOrderPlacerStore.setQuantity(quantity)
  }

  function handlePriceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const price = event.target.value
    manualOrderPlacerStore.setPrice(price)
  }

  function getValues(): Values | null {
    const priceFormatted = price.replace(',', '.')
    const priceVal = parseFloat(priceFormatted)
    if (isNaN(priceVal)) return null

    const quantityFormatted = quantity.replace(',', '.')
    const quantityVal = parseFloat(quantityFormatted)
    if (isNaN(quantityVal)) return null

    return {
      price: priceVal * 100,
      quantity: quantityVal * 1000,
      buy,
    }
  }

  function cancel() {
    manualOrderPlacerStore.setContractId(undefined)
  }

  useEffect(() => {
    const listener = (key: any) => {
      if (key.code === 'Escape' && contractId !== undefined)
        manualOrderPlacerStore.setContractId(undefined)

      if (
        (key.code === 'Enter' || key.code === 'NumpadEnter') &&
        contractId !== undefined
      ) {
        handlePlaceOrder()
      }
    }

    // Close settings on escape or mouse click
    window.addEventListener('keydown', listener) // Close settings

    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [handlePlaceOrder, uiStore])

  useEffect(() => {
    if (triggerCounter === -1) return
    if (triggerCounter < 1 || quantityRef.current === null) {
      manualOrderPlacerStore.incrementTriggerCounter()
      return
    }

    quantityRef.current.select()
    manualOrderPlacerStore.resetTriggerCounter()
  }, [triggerCounter])

  return (
    <div className="manual-order-placer">
      <Row>
        <>{directOrdersEnabled && <Cell></Cell>}</>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>

        {buy ? (
          <>
            <Cell className="non-button-cell">
              <input
                ref={quantityRef}
                onChange={handleQuantityChange}
                value={quantity}
                onClick={handleQuantityClick}
              />
            </Cell>
            <Cell className="non-button-cell">
              <input
                onChange={handlePriceChange}
                value={price}
                onClick={handlePriceClick}
              />
            </Cell>
            <Cell className="non-button-cell">
              <button
                onClick={handlePlaceOrder}
                className="manual-order-button"
                disabled={values === null}
              >
                Place
              </button>
            </Cell>
            <Cell className="non-button-cell">
              <button onClick={cancel} className="manual-order-button">
                Cancel
              </button>
            </Cell>
          </>
        ) : (
          <>
            <Cell className="non-button-cell">
              <button onClick={cancel} className="manual-order-button">
                Cancel
              </button>
            </Cell>
            <Cell className="non-button-cell">
              <button
                onClick={handlePlaceOrder}
                className="manual-order-button"
                disabled={values === null}
              >
                Place
              </button>
            </Cell>
            <Cell className="non-button-cell">
              <input
                onChange={handlePriceChange}
                value={price}
                onClick={handlePriceClick}
              />
            </Cell>
            <Cell className="non-button-cell">
              <input
                ref={quantityRef}
                onChange={handleQuantityChange}
                value={quantity}
                onClick={handleQuantityClick}
              />
            </Cell>
          </>
        )}

        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <>{directOrdersEnabled && <Cell></Cell>}</>
      </Row>
    </div>
  )
})

export default ManualOrderPlacer
