import { type AllocationBid, type OrderPlanStatus } from '../../../classes/OrderPlan'
import { type FC } from 'react'
import { lowerCaseAllButFirst } from '../../../components/Executor/Executor'

interface AllocationBidHeaderProps {
  bids: AllocationBid[]
  children: JSX.Element
}

export const AllocationAuctionHeader: FC<AllocationBidHeaderProps> = ({ bids, children }) => {
  if (bids.length < 1) return null

  const statuses = new Set<OrderPlanStatus>(bids.map(bid => bid.status))

  let submittedTime: string | undefined
  if (statuses.size === 1) {
    const status = [...statuses][0]
    if (status === 'SUBMITTED') {
      // If submitted, we show the latest submitted time
      const bidSort = (a: AllocationBid, b: AllocationBid) => a.submittedTime && b.submittedTime ? a.submittedTime.localeCompare(b.submittedTime) : 0
      submittedTime = bids.sort(bidSort)[0].submittedTime
    }
  }
  const createdTime = bids.sort((a: AllocationBid, b: AllocationBid) => a.createdTime && b.createdTime ? a.createdTime.localeCompare(b.createdTime) : 0)[0].createdTime

  return <div className='plan-header'>

    {submittedTime && <div className='plan-header-elem'>
      <div style={{ fontWeight: 'bold' }}>Submitted at:</div>
      <div>{new Date(submittedTime).toLocaleString()}</div>
    </div>}
    <div className='plan-header-elem'>
      <div style={{ fontWeight: 'bold' }}>Created at:</div>
      <div>{new Date(createdTime).toLocaleString()}</div>
    </div>
    {statuses.size === 1 && <div className='plan-header-elem'>
      <div>Plan status(es):</div>
      <div>{lowerCaseAllButFirst([...statuses][0])}</div>
    </div>}
    {children}
  </div>
}