import { useStores } from '../use-stores'
import { useState } from 'react'
import type { Capacity } from '../classes/Capacity'
import { useAbortController } from './useAbortController'
import axiosInstance from '../axiosInstance'
import { errorHandler } from '../pages/SpreadHelper/errorHandler'
import { useSubscribe } from './useSubscribe'

// 400 MW
const CAP_LIMIT = 400000


/** Function for filtering capacities. Set 'not' to true if we want filterNot */
const equal = (param: Capacity, not = false) =>
  (test: Capacity) => {
    const areEqual =
      param.deliveryAreaTo === test.deliveryAreaTo &&
      param.deliveryAreaFrom === test.deliveryAreaFrom &&
      param.deliveryStart === test.deliveryStart

    return not ? !areEqual : areEqual
  }

// Check old capacity and new capacity to see if we need to attach an event to the capacity
const hasEvent = (oldCap: Capacity, newCap: Capacity) => {
  // If we go from 0 to something higher, we say we are coupling
  if (oldCap.inCapacity === 0 && newCap.inCapacity > 0) return 'coupling'
  if (oldCap.outCapacity === 0 && newCap.outCapacity > 0) return 'coupling'

  // If we go from at least 400MW to something below that, we say there is a chance of decoupling
  if (oldCap.inCapacity >= CAP_LIMIT && newCap.inCapacity < CAP_LIMIT) {
    return 'decoupling'
  }
  if (oldCap.outCapacity >= CAP_LIMIT && newCap.outCapacity < CAP_LIMIT) {
    return 'decoupling'
  }

  // If we were already decoupling before, keep that state if it is still true
  if (oldCap.event === 'decoupling' || oldCap.event === 'decoupling-still') {
    if (newCap.inCapacity < CAP_LIMIT || newCap.outCapacity < CAP_LIMIT) {
      return 'decoupling-still'
    }
  }

  // If we were coupling before and we are still under CAP_LIMIT, use coupling-still
  if (oldCap.event === 'coupling' || oldCap.event === 'coupling-still') {
    if (newCap.inCapacity < CAP_LIMIT && newCap.inCapacity > 0)
      return 'coupling-still'
    if (newCap.outCapacity < CAP_LIMIT && newCap.outCapacity > 0)
      return 'coupling-still'
  }

  // Else no event
  return undefined
}

// Returns (updated) capacities for this EIC code. Optional message for toast if they cannot be fetched from backend, else uses default.
// Updates capacities on backend reconnect
export const useCapacities = (eic: string, message: string | undefined = undefined) => {
  const { connectionStore } = useStores()
  const { backendConnectionChanged } = connectionStore

  const [capacities, setCapacities] = useState<Capacity[]>([])

  /** Update capacities with new value */
  const updateCapacity = (capacity: Capacity) => {
    setCapacities((caps) => {
      // If we have one for from, to and delivery start combination
      const current = caps.find(equal(capacity))

      if (!current) return [...caps, capacity]

      // If combination exists and has higher or equal seq number, do not replace
      if (current.eventSequenceNo >= capacity.eventSequenceNo) return caps

      const filtered = caps.filter(equal(capacity, true))
      return [...filtered, { ...capacity, event: hasEvent(current, capacity) }]
    })
  }

  useAbortController(controller => {
    axiosInstance
      .get<Capacity[]>(`api/capacities/${eic}`, { signal: controller.signal })
      .then((res) => {
        res.data.forEach((cap) => updateCapacity(cap))
      })
      .catch(errorHandler(message ?? `Could not get capacities for ${eic}`))
  }, [backendConnectionChanged])

  useSubscribe(`/topic/capacities/${eic}`, updateCapacity)

  return capacities
}