export interface Imbalance {
  // EIC code of area
  areaId: string
  contractId: string
  tradeHandle: string
  imbalance: number
  profit?: number
}

export class ResetRequest {
  constructor(
    public contractId: string,
    // EIC code of area
    public areaId: string,
    public tradeHandle: string
  ) {}
}
