import axiosInstance from '../axiosInstance'
import { type Portfolio } from '../classes/Portfolio'
import { type Product } from '../classes/Product'

// Get all tradeable portfolios
const getPortfolios = async (): Promise<Portfolio[]> => await axiosInstance
  .get<Portfolio[]>('/api/portfolios')
  .then((response) => response.data)

// Get all contracts for portfolio. Defaults to first option in portfolios if no productId is specified
const getContracts = async (p: Portfolio, signal?: AbortSignal, productId?: string): Promise<Product[]> => {
  const prodId = productId ?? p.products[0]
  const { contractType, productType } = parseProductId(prodId)
  return await axiosInstance
    .get<Product[]>(`/api/portfolios/${p.exchange}/${p.eic}/contracts?productType=${productType}&contractType=${contractType}`, { signal })
    .then((response) => response.data)
}

// Parse a product name to product type and contract type for querying
const parseProductId = (product: string) => {
  const contractType = product.includes('XBID') ? 'XBID' : 'LOCAL'

  const lowered = product.toLowerCase()
  if (lowered.includes('quarter')) return { contractType, productType: 'P15MIN' }
  if (lowered.includes('half')) return { contractType, productType: 'P30MIN' }
  return { contractType, productType: 'P60MIN' }
}

export {
  getPortfolios,
  getContracts
}
