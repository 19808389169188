import { type Portfolio } from '../classes/Portfolio'
import { type Product } from '../classes/Product'
import axiosInstance from '../axiosInstance'
import { type IOrderBook } from '../classes/OrderBook'

export const getOrderBook = async (
  portfolio: Portfolio,
  contract: Product,
  signal: AbortSignal | undefined,
) => await axiosInstance
  .get<IOrderBook>(`api/order-books/${portfolio.exchange}/${portfolio.eic}/${contract.contractId}`, { signal })
  .then(res => res.data)