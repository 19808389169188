import type { Portfolio } from '../../classes/Portfolio'
import type { SpreadTrade } from '../../classes/SpreadTrade'

/**
 * Get calculation divisor for product type
 * @param productId the name of the product type, e.g. XBID_Quarter_Hour_Power
 */
export const getProductDivisor = (productId: string | undefined): number => {
  const lowered = productId?.toLowerCase() ?? ''
  if (lowered.includes('quarter')) return 4
  if (lowered.includes('half')) return 2
  return 1
}

/** Get divisor for portfolio. Takes the first product, which is the default one */
export const getPortfolioDivisor = (portfolio: Portfolio): number =>
  getProductDivisor(portfolio.products[0]) // Get the absolute difference between two numbers

/** profitSign differs from volumeSign in that buying means money out, means minus */
export const profitSign = (trade: SpreadTrade) => trade.side === 'BUY' ? -1 : 1

/** volumeSign lets buying be positive and selling be negative, meaning having sold more yields minus imbalance number */
export const volumeSign = (trade: SpreadTrade) => trade.side === 'BUY' ? 1 : -1

/** Calculate signed volume (imbalance) from trades */
export const volumeReducer = (v: number, trade: SpreadTrade) => v + volumeSign(trade) * trade.quantity

/** Get absolute difference between two numbers */
export const difference = (a: number, b: number) => Math.abs(a - b)