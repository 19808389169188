import { type FC } from 'react'

interface AuctionMenuCheckboxInputProps {
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AuctionMenuCheckboxInput: FC<AuctionMenuCheckboxInputProps> = ({ label, checked, onChange }) => {
  return (
    <div className="auction-config-elem">
      <label>{label}:</label>
      <input type="checkbox" checked={checked} onChange={onChange} />
    </div>
  )
}

export default AuctionMenuCheckboxInput
