import { PublicClientApplication, type SilentRequest } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority:
      'https://login.microsoftonline.com/e5a29949-bfa7-4681-b21b-b1b0a986dab0',
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

const scopes = [
  `api://${process.env.REACT_APP_BACKEND_CLIENT_ID}/TradeHelper.Write`,
  `api://${process.env.REACT_APP_BACKEND_CLIENT_ID}/AuctionHelper.User`,
]

const msalInstance = new PublicClientApplication(msalConfig)

// Get auth token
export const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0]

  if (account) {
    const request: SilentRequest = { scopes, account }
    return await msalInstance.acquireTokenSilent(request)
      .then(result => result.accessToken)
  }

  return null
}

export default msalInstance