import { getMultiplier } from '../../Util'
import { type Field, isSelectField, type NumberFieldType } from '../../classes/StrategySchema'
import { type Variables } from './Executor'
import type React from 'react'
import { type SetStateAction } from 'react'
import { type Portfolio } from '../../classes/Portfolio'
import { type Product } from '../../classes/Product'

/** Check if field is withing limits. This function only checks 'quantity' field */
export const hasError = (name: string, value: number | undefined) =>
  name === 'quantity' && value !== undefined && value > 500000 // We have 200 MW limit for quantity.

/**
 * Validate trade handle that trade handle is allowed
 * @param tradeHandle the trade handle to check
 * @returns if trade handle is valid
 */
export const isValidTradeHandle = (tradeHandle: string) => {
  return tradeHandle.length === 3 || tradeHandle.endsWith('-ALG') || tradeHandle.startsWith('IDC_')
}

/**
 * Validate that these things are present, before we can submit.
 * @param portfolio
 * @param product
 * @param tradeHandle
 */
const validateBase = (portfolio: Portfolio | undefined, product: Product | undefined, tradeHandle: string) => {
  return portfolio !== undefined && product !== undefined && isValidTradeHandle(tradeHandle)
}

// Set an executor variable
const setVariable = (setVariables: React.Dispatch<SetStateAction<Variables>>) => {
  return (type: string, name: string, value: number) => {
    const multiplier = getMultiplier(type as NumberFieldType)
    setVariables((vars) => {
      const dict = { ...vars }
      dict[name] = Number(value.toFixed(2)) * multiplier
      return dict
    })
  }
}

// Set an executor select field
const setSelect = (setVariables: React.Dispatch<SetStateAction<Variables>>) => {
  return (name: string, value: string) => {
    setVariables((vars) => {
      const dict = { ...vars }
      dict[name] = value
      return dict
    })
  }
}

// Assign all select fields to their first option
const defaultAssignSelectFields = (fields: Field[], variables: Variables) => {
  // For each select field, default to the first option = 0
  fields.forEach((field) => {
    if (isSelectField(field)) {
      variables[field.name] = field.options[0]
    }
  })
  return variables
}

export { setVariable, validateBase, setSelect, defaultAssignSelectFields }
