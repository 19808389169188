import axiosInstance from '../../axiosInstance'
import { type Timing, type TimingStore } from '../../stores/TimingStore'
import { useStores } from '../../use-stores'

async function getTimings(): Promise<Timing[]> {
  const timingsJson = await axiosInstance.get('api/timings')
  const timings: Timing[] = timingsJson.data

  return timings
}

async function addTiming(
  timing: Timing,
  timingStore: TimingStore
): Promise<void> {
  await axiosInstance.post('api/timings', timing)
  await syncTimings(timingStore)
}

async function removeTiming(
  trigger: string,
  timingStore: TimingStore
): Promise<void> {
  await axiosInstance.delete(`api/timings/${trigger}`)
  await syncTimings(timingStore)
}

async function syncTimings(timingStore: TimingStore): Promise<void> {
  const timings = await getTimings()
  timingStore.setTimings(timings)
}

const useTimings = () => {
  const { timingStore } = useStores()

  const sync = async () => {
    await syncTimings(timingStore)
  }
  const add = async (timing: Timing) => {
    await addTiming(timing, timingStore)
  }
  const remove = async (trigger: string) => {
    await removeTiming(trigger, timingStore)
  }

  return { sync, add, remove }
}

export default useTimings
