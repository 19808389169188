import type { Currency } from './Currency'

export const exchanges = ['NORD_POOL', 'EPEX'] as const

export type Exchange = typeof exchanges[number]

// A portfolio represents a tradeable area. They will always have a name and an EIC code
export interface BasePortfolio {
  name: string
  eic: string
  exchange: Exchange
  products: string[]
  active: boolean
  currency: Currency
}

// A NordPoolPortfolio has an integer deliveryAreaId and a portfolioId
export interface NordPoolPortfolio extends BasePortfolio {
  exchange: 'NORD_POOL'
  deliveryAreaId: number
  portfolioId: string
}

// An EPEX portfolio has a account id
export interface EpexPortfolio extends BasePortfolio {
  exchange: 'EPEX'
  acctId: string
}

// Unique id for portfolio
export const id = (portfolio: Portfolio) => `${portfolio.exchange}-${portfolio.eic}`

export type Portfolio = NordPoolPortfolio | EpexPortfolio