import { observer } from 'mobx-react'
import {
  type Auction,
  isAuction,
  type BaseAuction,
  isAllocation,
  type AllocationAuction,
} from '../../../classes/Auction'
import { useEffect, useState, type FC } from 'react'
import { type AuctionConfig } from '../../../classes/AuctionConfig'
import { toast } from 'react-toastify'
import { putAllocationConfig, putAuctionConfig } from '../../../actions/Auctions'
import { type AllocationConfig } from '../../../classes/AllocationConfig'
import AuctionMenuTextInput from './AuctionMenuTextInput'
import AuctionMenuCheckboxInput from './AuctionMenuCheckboxInput'
import { Conditional } from '../../SpreadHelper/Conditional'

interface BaseAuctionConfigProps {
  auction: BaseAuction
}

interface AuctionConfigProps {
  auction: Auction
}

interface AllocationConfigProps {
  auction: AllocationAuction
}

export const BaseAuctionConfig: FC<BaseAuctionConfigProps> = observer(({ auction }) => {
  if (isAuction(auction)) return <AuctionConfigMenu auction={auction} />
  if (isAllocation(auction)) return <AllocationConfigMenu auction={auction} />
  return <></>
})

const AuctionConfigMenu: FC<AuctionConfigProps> = observer(({ auction }) => {
  const [config, setConfig] = useState<AuctionConfig>({ ...auction.auctionConfig })
  const [changed, setChanged] = useState(false)
  const maxHourQuantity = config.maxHourQuantity ? config.maxHourQuantity / 1000 : ''
  const maxTotalQuantity = config.maxTotalQuantity ? config.maxTotalQuantity / 1000 : ''

  function updateValue<K extends keyof AuctionConfig>(key: K, value: AuctionConfig[K]) {
    setConfig({
      ...config,
      [key]: value,
    })
    setChanged(true)
  }

  function resetConfig() {
    setConfig({ ...auction.auctionConfig })
    setChanged(false)
  }

  async function saveConfig() {
    await putAuctionConfig(config)
      .then(() => {
        setChanged(false)
        toast.success('Config saved')
      })
      .catch(() => toast.error('Failed to save config'))
  }

  useEffect(() => {
    resetConfig()
  }, [auction.auctionId, auction.auctionConfig.revision])

  return (
    <div className="auction-config">
      <AuctionMenuCheckboxInput
        label={'Auto Accept'}
        checked={config.autoAccept}
        onChange={(e) => {
          updateValue('autoAccept', e.target.checked)
        }}
      />
      <Conditional value={config.autoAccept || changed}>
        <AuctionMenuTextInput
          label="Max Quantity"
          value={maxHourQuantity}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isNaN(value)) return
            updateValue('maxHourQuantity', value * 1000)
          }}
        />

        <AuctionMenuTextInput
          label="Max Total Quantity"
          value={maxTotalQuantity}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isNaN(value)) return
            updateValue('maxTotalQuantity', value * 1000)
          }}
        />
      </Conditional>

      <Conditional value={changed}>
        <div className='auction-config-buttons'>
          <button className='clickable' onClick={saveConfig}>Save</button>
          <button className='clickable' onClick={resetConfig}>Discard</button>
        </div>
      </Conditional>
    </div>
  )
})

const AllocationConfigMenu: FC<AllocationConfigProps> = observer(({ auction }) => {
  const [config, setConfig] = useState<AllocationConfig>({ ...auction.allocationConfig })
  const [changed, setChanged] = useState(false)
  const maxBid = config.maxBid ? config.maxBid / 100 : ''
  const maxQuantity = config.maxQuantity ? config.maxQuantity / 1000 : ''
  const maxTotalQuantity = config.maxTotalQuantity ? config.maxTotalQuantity / 1000 : ''

  function updateValue<K extends keyof AllocationConfig>(key: K, value: AllocationConfig[K]) {
    setConfig({
      ...config,
      [key]: value,
    })
    setChanged(true)
  }

  function resetConfig() {
    setConfig({ ...auction.allocationConfig })
    setChanged(false)
  }

  async function saveConfig() {
    await putAllocationConfig(config)
      .then(() => {
        setChanged(false)
        toast.success('Config saved')
      })
      .catch(() => toast.error('Failed to save config'))
  }

  useEffect(() => {
    resetConfig()
  }, [auction.id, auction.allocationConfig.revision])

  return (
    <div className="auction-config">
      <AuctionMenuCheckboxInput
        label={'Auto Accept'}
        checked={config.autoAccept}
        onChange={(e) => {
          updateValue('autoAccept', e.target.checked)
        }}
      />

      <Conditional value={config.autoAccept || changed}>
        <AuctionMenuTextInput
          label="Max Bid"
          value={maxBid}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isNaN(value)) return
            updateValue('maxBid', value * 100)
          }}
        />

        <AuctionMenuTextInput
          label="Max Quantity"
          value={maxQuantity}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isNaN(value)) return
            updateValue('maxQuantity', value * 1000)
          }}
        />

        <AuctionMenuTextInput
          label="Max Total Quantity"
          value={maxTotalQuantity}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isNaN(value)) return
            updateValue('maxTotalQuantity', value * 1000)
          }}
        />
      </Conditional>
      <Conditional value={changed}>
        <div className='auction-config-buttons'>
          <button className='clickable' onClick={saveConfig}>Save</button>
          <button className='clickable' onClick={resetConfig}>Discard</button>
        </div>
      </Conditional>
    </div>
  )
})
