import type React from 'react';
import { useEffect, useState } from 'react'

// Listen for updates to a local storage item. Uses defaultState only if there is no value in local storage
export const useReadLocalStorage = (key: string, defaultState?: string | (() => string)) => {
  const [state, setState] = useState<string | null>(localStorage.getItem(key) ?? defaultState ?? null)

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      const { key, newValue } = event
      if (event.key !== key) return

      setState(newValue)
    }

    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  return state
}

export const useLocalStorage: <T>(key: string, defaultValue: T) => [T, React.Dispatch<React.SetStateAction<T>>] = <T,>(key: string, defaultValue: T) => {
  const storageItem = localStorage.getItem(key)
  const item: T = storageItem ? JSON.parse(storageItem) : defaultValue
  const [value, setValue] = useState<T>(item)

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [ value, setValue ]
}