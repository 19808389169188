import React, { type FC } from 'react'

interface ConditionalProps {
  value: boolean
  children: JSX.Element | JSX.Element[]
}

// Component for {boolean && <Component />} behavior
export const Conditional: FC<ConditionalProps> = ({ value, children }) => {
  if (!value) return null

  if (Array.isArray(children)) {
    return <>{children.map(c => c)}</>
  } else {
    return <>{[children].map(c => c)}</>
  }
}