import { Multiplier } from '../../../Util'
import { type Portfolio } from '../../../classes/Portfolio'
import { type Product } from '../../../classes/Product'
import React, { type FC, useEffect, useMemo } from 'react'
import { useOrderBook } from '../../../hooks/useOrderBook'
import { toast } from 'react-toastify'
import './order-book-cell.scss'
import type { SpreadTrade } from '../../../classes/SpreadTrade'
import { getPortfolioDivisor, volumeReducer } from '../calculationHelpers'
import { calculateCost, volumeToTrade } from './calculateCost'
import { Conditional } from '../Conditional'

// Format contract name without middle date 'PH-20240913-12' -> PH-12
const formatContractName = (contractName: string) => {
  const split = contractName.split('-')
  return split.length === 3 ? `${split[0]}${split[2]}` : contractName
}

interface OrderBookCellProps {
  portfolio: Portfolio
  contract: Product
  fullPosition: number
  // Set the cost of our position, so we can globally calculate the P/L for taking a position
  setTakePositionCost: (takeTrade: TakeTrade | undefined) => void
  trades: SpreadTrade[]
  // This boolean says we can trade more
  enabled: boolean
  className?: string
}

export interface TakeTrade {
  // Signed cost of position. Meaning, we sell = we have a positive number
  cost: number
  // The price to put order at
  pricePoint: number
  volume: number
}

// Creates a vertical line separator
const Separator = () => <span>|</span>

const OrderBookCell: FC<OrderBookCellProps> =
  ({ portfolio, contract, trades, fullPosition, className, enabled, setTakePositionCost }) => {

    // Use updated order book and calculate our current imbalance for this contract based on trades
    const orderBook = useOrderBook(portfolio, contract)
    const currentPos = useMemo(() => trades.reduce(volumeReducer, 0), [trades.length]) // List instance is going to update often, so use length

    useEffect(() => {
      // Make sure we set the takeTrade to undefined if we have no order book (so the trader cannot take position based on this)
      if (!orderBook) {
        setTakePositionCost(undefined)
        return
      }

      const takeTrade = calculateCost(orderBook, volumeToTrade(currentPos, fullPosition))

      if (takeTrade) {
        setTakePositionCost({ ...takeTrade, cost: takeTrade.cost / getPortfolioDivisor(portfolio) })
      } else {
        toast.error(`Not enough volume in order book: ${contract.name}`, { toastId: `${portfolio.eic}${contract.contractId}` })
        setTakePositionCost(undefined)
      }
    }, [orderBook, currentPos, fullPosition])

    const fullClassName = `order-book-cell ${className ?? ''} ${enabled ? 'enabled' : ''}`

    return <div className={fullClassName}>
      <div title={`${portfolio.name} (${contract.name})`}>{formatContractName(contract.name)}</div>
      <Separator />
      <div title={'Current position'}>{(currentPos / Multiplier.MW)}</div>
      <Conditional value={enabled}>
        <Separator />
        <div>{volumeToTrade(currentPos, fullPosition) / 1000}</div>
      </Conditional>
    </div>
  }

export default OrderBookCell