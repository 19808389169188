import type { IOrderBook, Order } from '../classes/OrderBook'
import { type Portfolio } from '../classes/Portfolio'
import { type Product } from '../classes/Product'
import { useState } from 'react'
import { useAbortController } from './useAbortController'
import { getOrderBook } from '../actions/OrderBooks'
import { useSubscribe } from './useSubscribe'
import { toast } from 'react-toastify'
import { CanceledError } from 'axios'

// Describes an update event for the order book
export interface DeltaEvent {
  contractId: string
  areaId: number
  orderBookDelta: OrderBookDelta
}

// Models actual changes to order book
interface OrderBookDelta {
  buyOrders: Order[]
  sellOrders: Order[]
  contractId: string
  deliveryAreaId: number
  revisionNo: number
}

// Models current state and a backlog of change events
interface OrderBookState {
  orderBook: IOrderBook | undefined
  backlog: DeltaEvent[]
}

// Sorting function for deltas uses revision numbers for ordering
const deltaSort = (a: DeltaEvent, b: DeltaEvent) => a.orderBookDelta.revisionNo - b.orderBookDelta.revisionNo

// Sort by price descending, then by createdAt ascending
const bidSort = (a: Order, b: Order) =>
  a.price !== b.price ? b.price - a.price : a.createdAt.localeCompare(b.createdAt)

// Sort by price ascending, then by createdAt ascending
const askSort = (a: Order, b: Order) =>
  a.price !== b.price ? a.price - b.price : a.createdAt.localeCompare(b.createdAt)

// Update an order book with a delta (update the orders). Returns
const updateOrderBook = (orderBook: IOrderBook, update: OrderBookDelta): IOrderBook => {
  const updateOrders = (prevOrders: Order[], newOrder: Order) => {
    const prevIndex = prevOrders.findIndex((o) => o.orderId === newOrder.orderId)

    if (prevIndex > -1 && newOrder.deleted) prevOrders.splice(prevIndex, 1)
    else if (prevIndex > -1) prevOrders[prevIndex] = newOrder
    else if (!newOrder.deleted) prevOrders.push(newOrder)

    return prevOrders
  }

  const buys = [...orderBook.buys]
  update.buyOrders.forEach((buy) => {
    updateOrders(buys, buy)
  })

  const sells = [...orderBook.sells]
  update.sellOrders.forEach((sell) => {
    updateOrders(sells, sell)
  })

  return { buys: buys.sort(bidSort), sells: sells.sort(askSort), latestRevision: update.revisionNo }
}

/**
 * Process backlog of order book events
 * @param prevOrderBook The previous order book to be updated
 * @param backlog The backlog of [OrderBookDeltaUpdateEvent].
 *
 * @returns A new [OrderBookState] with the (possibly) updated order book and backlog
 */
const processBacklog = (prevOrderBook: IOrderBook, backlog: DeltaEvent[]): OrderBookState => {
  // Apply them to order book -> only take the revisions that are newer than current order book
  const updated = [...backlog]
    .sort(deltaSort)
    .filter((delta) => delta.orderBookDelta.revisionNo > prevOrderBook.latestRevision)
    .reduce((orderBook, delta) => updateOrderBook(orderBook, delta.orderBookDelta), prevOrderBook)

  // Return updated order book en empty backlog
  return { orderBook: updated, backlog: [] }
}

// Return sorted order book
const sorted = (orderBook: IOrderBook) => {
  const { buys, sells } = orderBook
  return { ...orderBook, buys: buys.sort(bidSort), sells: sells.sort(askSort) }
}

const errorHandler = (message: string) => (err: any) => {
  if (err instanceof CanceledError) return
  toast.error(message)
}


// Get always updated (and sorted) order book instance
export const useOrderBook = (portfolio: Portfolio, contract: Product) => {
  const { eic, exchange } = portfolio

  const [orderBookState, setOrderBookState] = useState<OrderBookState>({
    orderBook: undefined,
    backlog: [],
  })

  // Get existing order book
  useAbortController(controller => {
    getOrderBook(portfolio, contract, controller.signal)
      .then(orderBook => {
        if (!orderBook) {
          toast.error(`Could not get order book for ${portfolio.name} ${contract.name}`)
          return
        }
        setOrderBookState(prev => processBacklog(sorted(orderBook), prev.backlog))
      })
      .catch(errorHandler(`Could not get order book ${contract.name}`))
  }, [])

  // Subscribe to updates
  useSubscribe(`/topic/order-book/${exchange}/${eic}/${contract.contractId}`, (delta: DeltaEvent) => {
    setOrderBookState(prev => {
      // Check if the order book has been fetched yet, add delta to backlog instead
      if (prev.orderBook === undefined) {
        return { orderBook: undefined, backlog: [...prev.backlog, delta] }
      }

      // Order book has been fetched, update it
      return processBacklog(prev.orderBook, [...prev.backlog, delta])
    })
  })

  return orderBookState.orderBook
}