import React, { type FC } from 'react'
import { Checkmark } from '../Icons/Icons'
import moment from 'moment/moment'
import { AcceptOrReject } from '../../pages/Auctions/AuctionMenu/AcceptOrReject'
import { changeBidStatus } from '../../actions/Auctions'
import { toast } from 'react-toastify'
import { type AllocationAuctionStore } from '../../pages/Auctions/AllocationMenu/AllocationAuctionStore'
import { type AllocationBid } from '../../classes/OrderPlan'

interface BidHeaderProps {
  store: AllocationAuctionStore,
  // Mark the status of this bid
  showIndividualStatus: boolean,
  // The bid to show the header fore
  bid: AllocationBid
}

// If the statuses of the two plans are different, we show them in the bid headers, so the trader can tell what is what
export const BidHeader: FC<BidHeaderProps> = ({ store, showIndividualStatus, bid }) => {
  // If they have the same status, it will appear in the AuctionHeader component
  if (!showIndividualStatus) return <>{bid.name}</>

  let status
  switch (bid.status) {
    case 'SUBMITTED':
      status = <Checkmark />
      break
    case 'PENDING':
      status = <Checkmark stroke={'rgb(204,204,57)'} />
  }

  // The space we want to see above and below the buttons if they are rendered.
  const totalButtonPadding = 12
  const acceptRejectHeight = `calc(var(--row-height) - ${totalButtonPadding}px)`

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
    <div style={{ display: 'flex', justifySelf: 'center', alignItems: 'center' }}>
      <div
        style={{ display: 'flex' }}
        title={bid.submittedTime
          ? `Submitted at ${moment.utc(bid.submittedTime).toDate().toLocaleString('da-DK')}`
          : `Created at ${moment.utc(bid.createdTime).toDate().toLocaleString('da-DK')}`
        }>{status}</div>
      <p style={{ margin: 'unset' }}>{bid.name}</p>
    </div>
    {bid.status === 'PENDING' && (
      <AcceptOrReject
        acceptAction={callback => {
          changeBidStatus(bid, 'SUBMITTED')
            .then(data => {
              store.addBid(data)
            })
            .catch(() => toast.error('Could not submit bid'))
            .finally(() => {
              callback()
            })
        }}
        rejectAction={callback => {
          changeBidStatus(bid, 'DISCARDED')
            .then(data => {
              store.addBid(data)
            })
            .catch(() => toast.error('Could not discard bid'))
            .finally(() => {
              callback()
            })
        }}
        height={acceptRejectHeight} />
    )}
  </div>
}