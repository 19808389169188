import type { Currency } from './Currency'


export type AuctionType = 'ID1' | 'ID2' | 'ID3' | 'ID4'
export type RoundingStrategy = 'NORMAL' | 'DOWN' | 'BANKERS'
export type LossFactorStrategy = 'BOTH' | 'SELLING'

export const key = (halfAuction: HalfAuction) => `${halfAuction.auctionName} (${halfAuction.tag})`

// Models one direction of an interconnector
export interface HalfAuction {
  auctionName: string
  lossFactor: number
  corridor: string
  lossFactorStrategy: LossFactorStrategy
  exportRoundingStrategy: RoundingStrategy
  importRoundingStrategy: RoundingStrategy
  tag: string
  export: string
  import: string
  auctions: IDAuction[]
}

// Models one ID auction
export interface IDAuction {
  type: AuctionType,
  deliveryStart: string
  lastDeliveryStart: string
}

export interface TransmissionRight {
  startTime: string
  transmissionRight: number
  corridor: string
}

export interface MarginalPrice {
  startTime: string
  marginalPrice: number
  corridor: string
}

// Models a combination of transmission right and marginal price for the same delivery period
export interface TransmissionRightAndMarginalPrice {
  transmissionRight: TransmissionRight
  marginalPrice: MarginalPrice
}

// Models an exchange rate from a currency to another (EUR to GBP only right now)
export interface ExchangeRate {
  fromCurrency: Currency,
  toCurrency: Currency,
  startTime: string,
  endTime: string,
  rate: number
}