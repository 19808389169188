/**
 * This class represents an order request. It can be passed to the trade service
 * to create a new order.
 */
export class OrderRequest {
  constructor(
    public exchange: string,
    public strategyId: string,
    public contractIds: string[],
    public eicCode: string,
    public side: Side,
    public text: string,
    public unitPrice: number,
    public quantity: number,
    public orderType: OrderRequestType
  ) {}
}

export enum OrderRequestTypeEnum {
  LIMIT = 'LIMIT',
  IBO = 'IBO',
}

export interface OrderRequestType {
  readonly '@type': 'LIMIT' | 'IBO'
}

export class IBO implements OrderRequestType {
  readonly '@type' = 'IBO'

  constructor(public clipSize: number) {}
}

export class LIMIT implements OrderRequestType {
  readonly '@type' = 'LIMIT'
}

export enum Side {
  SELL = 'SELL',
  BUY = 'BUY',
}
