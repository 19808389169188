import { CanceledError } from 'axios'
import { toast } from 'react-toastify'

// Use in Promise.catch. Does nothing if it is CanceledError, toasts given message if backend does not provide one
export const errorHandler = (message: string | undefined) => (err: any) => {
  if (err instanceof CanceledError) return

  const error = err?.response?.data?.message
  if (error) toast.error(error)
  else toast.error(message || 'Error occurred')
}