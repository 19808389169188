import React, { type CSSProperties, type FC } from 'react'
import './range.scss'

export interface Style extends CSSProperties {
  '--track-width'?: CSSProperties['width'];
}

interface RangeProps {
  max: number
  value: number
  onChange: React.ChangeEventHandler<HTMLInputElement>
  // Minimum value
  min?: number
  // Range step
  step?: number
  width?: CSSProperties['width']
}

const Range: FC<RangeProps> = ({ max, value, onChange, min, step, width }) => {
  const style: Style = width ? { '--track-width': width } : {}
  const minimum = min ?? 200
  const stepValue = step ?? 200
  return <input style={style} className='range' type='range' min={minimum} max={max} step={stepValue} value={value} onChange={onChange} />
}

export default Range