import axios from 'axios'
import { getToken } from './authConfig'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`,
})

axiosInstance.interceptors.request.use(
  async (config) => {

    const accessToken = await getToken()
    if (config.headers != null && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

export default axiosInstance
