import { makeAutoObservable } from 'mobx'
import { OrderRequestTypeEnum } from '../classes/OrderRequest'

/**
 * Filter for finding unique fields.
 */

const ORDER_FILLED_SOUND_ENABLED = 'ORDER_FILLED_SOUND_ENABLED'
const DIRECT_ORDERS_ENABLED = 'DIRECT_ORDERS_ENABLED'
const ORDER_REQUEST_TYPE = 'ORDER_REQUEST_TYPE'
const DEFAULT_QUANTITIES = 'DEFAULT_QUANTITIES'
const ORDER_BOOK_ALL_LIMIT = 'ORDER_BOOK_ALL_LIMIT'
const QUANTITY_LINE_ENABLED = 'QUANTITY_LINE_ENABLED'
const TRACKING_IMBALANCE = 'TRACKING_IMBALANCE'
const PROFIT_ENABLED = 'PROFIT_ENABLED'
const CLIP_SIZE = 'CLIP_SIZE'
const LATEST_TRADE_PRICE_ENABLED = 'LATEST_TRADE_PRICE_ENABLED'

export class OrderBookStore {
  orderFilledSoundEnabled: boolean
  directOrdersEnabled: boolean
  orderRequestTypeEnum: OrderRequestTypeEnum
  clipSize: number
  defaultQuantities: number[]
  orderBookQtyLimit: number
  quantityLineEnabled: boolean
  imbalanceTrackers: boolean[]
  profitEnabled: boolean
  latestTradePriceEnabled: boolean

  constructor() {
    this.orderFilledSoundEnabled = localStorage.getItem(ORDER_FILLED_SOUND_ENABLED) === 'true'
    this.directOrdersEnabled = localStorage.getItem(DIRECT_ORDERS_ENABLED) === 'true'
    this.orderRequestTypeEnum = JSON.parse(localStorage.getItem(ORDER_REQUEST_TYPE) as string) || OrderRequestTypeEnum.IBO
    this.defaultQuantities = JSON.parse(localStorage.getItem(DEFAULT_QUANTITIES) as string) || [
      10000, 10000, 5000, 5000, 10000, 10000, 10000, 10000,
    ]
    this.orderBookQtyLimit = parseInt(localStorage.getItem(ORDER_BOOK_ALL_LIMIT)!) || 20000
    this.quantityLineEnabled = localStorage.getItem(QUANTITY_LINE_ENABLED) === 'true'
    this.imbalanceTrackers = JSON.parse(localStorage.getItem(TRACKING_IMBALANCE) as string) || [
      true,
      false,
      true,
      true,
      false,
      true,
      true,
      true,
    ]
    this.profitEnabled = localStorage.getItem(PROFIT_ENABLED) === 'true'
    this.clipSize = parseInt(localStorage.getItem(CLIP_SIZE) as string) || 5000
    this.latestTradePriceEnabled = localStorage.getItem(LATEST_TRADE_PRICE_ENABLED) === 'true'

    makeAutoObservable(this)
  }

  setOrderFilledSoundEnabled(enabled: boolean) {
    this.orderFilledSoundEnabled = enabled
    localStorage.setItem(ORDER_FILLED_SOUND_ENABLED, enabled.toString())
  }

  setDirectOrdersEnabled(enabled: boolean) {
    this.directOrdersEnabled = enabled
    localStorage.setItem(DIRECT_ORDERS_ENABLED, enabled.toString())
  }

  setOrderRequestType(orderRequestTypeEnum: OrderRequestTypeEnum) {
    this.orderRequestTypeEnum = orderRequestTypeEnum
    localStorage.setItem(ORDER_REQUEST_TYPE, JSON.stringify(orderRequestTypeEnum))
  }

  setDefaultQuantities(defaultQuantities: number[]) {
    this.defaultQuantities = [...defaultQuantities]
    localStorage.setItem(DEFAULT_QUANTITIES, JSON.stringify(defaultQuantities))
  }

  setImbalanceTrackers(imbalanceTrackers: boolean[]) {
    this.imbalanceTrackers = [...imbalanceTrackers]
    localStorage.setItem(TRACKING_IMBALANCE, JSON.stringify(imbalanceTrackers))
  }

  setOrderBookQtyLimit(orderBookQtyLimit: number) {
    this.orderBookQtyLimit = orderBookQtyLimit
    localStorage.setItem(ORDER_BOOK_ALL_LIMIT, orderBookQtyLimit.toString())
  }

  setQuantityLineEnabled(enabled: boolean) {
    this.quantityLineEnabled = enabled
    localStorage.setItem(QUANTITY_LINE_ENABLED, enabled.toString())
  }

  setProfitEnabled(enabled: boolean) {
    this.profitEnabled = enabled
    localStorage.setItem(PROFIT_ENABLED, enabled.toString())
  }

  setClipSize(clipSize: number) {
    this.clipSize = clipSize
    localStorage.setItem(CLIP_SIZE, clipSize.toString())
  }

  setLatestTradePriceEnabled(enabled: boolean) {
    this.latestTradePriceEnabled = enabled
    localStorage.setItem(LATEST_TRADE_PRICE_ENABLED, enabled.toString())
  }
}
