import { observer } from 'mobx-react'
import React, { Fragment, useRef, useState } from 'react'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import { type Dictionary } from '../../classes/Order'
import './capacity-overview.scss'
import { type Area, type Capacity } from '../../classes/Capacity'
import { Cell, HCell, Row } from '../../components/Table/Table'
import { Cross } from '../../components/Icons/Icons'
import { getPortfolios } from '../../actions/Portfolios'
import { type Portfolio } from '../../classes/Portfolio'
import { useCapacities } from '../../hooks/useCapacities'
import { useContracts } from '../../hooks/useContracts'
import { capacityVolumeFormat, productToShortName } from '../../Util'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useAbortController } from '../../hooks/useAbortController'
import { errorHandler } from '../SpreadHelper/errorHandler'


// Get coloring (via className) from an event
const eventColor = (event: Capacity['event']) => {
  switch (event) {
    case 'coupling': return 'green'
    case 'decoupling': return 'red'
    case 'coupling-still': return 'green-still'
    case 'decoupling-still': return 'red-still'
    default: return ''
  }
}

// Check if area has an event in its capacities. Filter by delivery start, so we don't show events for closed hours.
// Takes first event, cannot do anything about events going both ways when signaling via colors
const areaHasEvent = (
  capacities: Capacity[],
  area: string,
  deliveryStart: string,
) => {
  const event = capacities
    .filter((capacity) => capacity.deliveryStart >= deliveryStart)
    .find((capacity) => capacity.deliveryAreaTo === area && capacity.event)
    ?.event

  return eventColor(event)
}

// Get next delivery start. Take time now, add 2 hours, and go to the start of that hour
const getNextDeliveryStart = () => {
  const date = new Date()
  date.setHours(date.getHours() + 2)
  date.setMinutes(0, 0, 0)
  return date.toISOString()
}

// Get capacity areas for an eic code
const getAreas = async (eic: string, signal: AbortSignal) => await axiosInstance
  .get<Area[]>(`api/capacities/${eic}/areas`, { signal })
  .then(res => res.data)

const CAP = 'CAP-'
const EXCLUDED = '-EXCLUDED'
const SORTING = '-SORTING'

const CapacityOverview = observer((props: { height: string, eic: string, remove: (a: string) => void }) => {
  const { height, eic, remove } = props

  // Areas that are not selected + the sorting order of items
  const [excluded, setExcluded] = useLocalStorage<Area[]>(CAP + eic + EXCLUDED, [])
  const [sorting, setSorting] = useLocalStorage<string[]>(CAP + eic + SORTING, [])

  const [areas, setAreas] = useState<Area[]>([])
  const [portfolio, setPortfolio] = useState<Portfolio | undefined>()

  const capacities = useCapacities(eic)
  const products = useContracts(portfolio)

  const selectedAreas = areas.filter(a => excluded.findIndex(e => e.code === a.code) === -1)

  useAbortController(controller => {
    const { signal } = controller

    getAreas(eic, signal)
      .then(aList => {
        setAreas(aList)
        // If sorting is empty, add sorting by area code (first time)
        if (sorting?.length < 1) {
          const sorted = aList.sort((a, b) => (a.code > b.code ? 1 : -1))
          setSorting(sorted.map(a => a.code))
        }
      })
      .catch(errorHandler(`Could not get capacity areas for ${portfolio?.name}`))

    getPortfolios()
      .then(portfolios => {
        setPortfolio(portfolios.find((p) => p.eic === eic && p.active))
      })
      .catch(() => toast.error('Could not get area name'))
  })

  const removeArea = (area: Area) => {
    setExcluded(existing => [area, ...existing])
  }

  // Create map of capacities
  const capacityMap: Dictionary<Capacity[]> = {}
  const deliveryStart = getNextDeliveryStart()

  // Fill map, deliveryStart -> capacities
  capacities
    .filter((capacity) => capacity.deliveryStart >= deliveryStart)
    .forEach((capacity) => {
      if (capacityMap[capacity.deliveryStart] === undefined) {
        capacityMap[capacity.deliveryStart] = [capacity]
      } else capacityMap[capacity.deliveryStart].push(capacity)
    })

  // Unselected areas that can be selected
  const selectableAreas = areas
    .filter((area) => excluded.findIndex((a) => a.code === area.code) > -1)
    .sort((a, b) => sorting.indexOf(a.code) - sorting.indexOf(b.code))
    .map((area) => (
      <div
        key={area.name}
        className={areaHasEvent(capacities, area.code, deliveryStart)}
        onClick={() => {
          setExcluded(existing => existing.filter(a => a.code !== area.code))
        }}
      >
        {area.name}
      </div>
    ))

  // Hold deliveryStart of marked row
  const [marked, setMarked] = useState<string | undefined>(undefined)

  // Create list of rows sorted by
  const rows = Object.keys(capacityMap)
    .sort()
    .map((deliveryStart) => {
      const product = products?.find((p) => p.deliveryStart === deliveryStart)
      const productName = product ? productToShortName(product) : ''

      return (
        <Row
          key={deliveryStart}
          onClick={() => {
            // If we click a new row, set that as marked, else we are clicking the same and we unmark it
            setMarked(old => old === deliveryStart ? undefined : deliveryStart)
          }}
          highlighted={marked === deliveryStart}
        >
          <Fragment>
            <Cell className='contract-column right'>
              {productName}
            </Cell>
            {capacityMap[deliveryStart]
              .filter(({ deliveryAreaTo }) => selectedAreas.some(({ code }) => code === deliveryAreaTo))
              .sort((a, b) => sorting.indexOf(a.deliveryAreaTo) - sorting.indexOf(b.deliveryAreaTo))
              .map((capacity) => (
                <Fragment key={capacity.deliveryAreaTo}>
                  <Cell className={`left ${eventColor(capacity.event)}`}>
                    {capacityVolumeFormat(capacity.outCapacity / 1000)}
                  </Cell>
                  <Cell className={`right ${eventColor(capacity.event)}`}>
                    {capacityVolumeFormat(capacity.inCapacity / 1000)}
                  </Cell>
                </Fragment>
              ))
            }
          </Fragment>
        </Row>
      )
    })

  // Contract column ref. Used for finding width that table is offset with
  const ccRef = useRef<any>()
  // Body ref. This has the full size of the table
  const bodyRef = useRef<any>()
  // Scroll over body tells us how much of screen is missing on left side
  const scrollRef = useRef<any>()
  // Index of area we are moving
  const [movingIndex, setMovingIndex] = useState<number | undefined>(undefined)

  const getWidthAndOffset = () => {
    const leftOffset = ccRef.current?.offsetWidth
    const fullWidth = bodyRef.current?.offsetWidth
    const fullColumnWidth = fullWidth - leftOffset
    const elemWidth = fullColumnWidth / selectedAreas.length
    return { elemWidth, leftOffset }
  }

  // Get index of column where we start drag
  const onDragStart = (e: React.DragEvent) => {
    const { elemWidth, leftOffset } = getWidthAndOffset()
    const { scrollLeft } = scrollRef.current

    const index = Math.floor(((e.clientX + scrollLeft) - leftOffset) / elemWidth)
    setMovingIndex(() => index)
  }

  // Get index of column where we end drag. Move movingIndex to the index of underlying area in the sorting array
  const onDragEnd = (e: React.DragEvent) => {
    const { elemWidth, leftOffset } = getWidthAndOffset()
    const { scrollLeft } = scrollRef.current

    // This is the index we are moving the value to
    const index = Math.floor(((e.clientX + scrollLeft) - leftOffset) / elemWidth)

    // Move movingIndex to the index of the area we are hovering above
    if (movingIndex !== undefined) {
      // Get the actual areas
      const area1 = selectedAreas[movingIndex]
      const area2 = selectedAreas[index]

      // Get index of area2 in the sorting array
      // Filter area1 one and add it at the index
      const area2Index = sorting.indexOf(area2.code)
      const filtered = sorting.filter(i => i !== area1.code)
      filtered.splice(area2Index, 0, area1.code)
      setSorting(filtered)
    }

    setMovingIndex(undefined)
  }

  return (
    <div className='capacity-overview' style={{ height: props.height }}>
      {/* We need to add toast container as this opens outside of the regular SPA, and hence cannot access the container from there */}
      <div className='capacity-menu'>
        <div style={{ display: 'flex', gap: '5px' }}>
          {`Capacities ${portfolio ? `(${portfolio?.name?.split('-')[1]})` : ''}`}
          <div onClick={() => {
            remove(eic)
          }}>
            <Cross />
          </div>
        </div>
        <div className='area-selector'>
          {/* Add a span of ',' between the areas */}
          {selectableAreas.map((area, idx) => {
            if (idx < selectableAreas.length - 1) {
              return (
                <Fragment key={area.key}>
                  {area}
                  <span>{', '}</span>
                </Fragment>
              )
            } else return area
          })}
        </div>
      </div>
      <div>
        <div className='table' ref={scrollRef}>
          <div className='header'>
            <Row>
              <Fragment>
                <Cell className='contract-column' reference={ccRef} />
                {selectedAreas
                  .sort((a, b) => sorting.indexOf(a.code) - sorting.indexOf(b.code))
                  .map((area) => (
                    <HCell
                      key={area.name}
                      onClick={() => {
                        removeArea(area)
                      }}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      {area.name}
                    </HCell>
                  ))
                }
              </Fragment>
            </Row>
          </div>
          {/** Outer body to scroll */}
          <div
            className='outer-body'
            style={{ height: `calc(${height} - 4px)` }}
          >
            <div className='body' style={{ height: `calc(${rows.length} * var(--cell-height)` }}
                 ref={bodyRef}>{rows}</div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default CapacityOverview
