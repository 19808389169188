import React, { type FC, Fragment, type ChangeEvent } from 'react';
import { type BidEntry, type Bid } from './BidEntry';
import { observer } from 'mobx-react';
import '../../CurveTable/curve-table.scss'
import { formatProductName, Multiplier } from '../../../Util'
import { parse, format } from 'date-fns';
import './bid-table.scss'


export interface ColumnConfig {
  label: string;
  key: keyof Bid;
  multiplier?: number;
}

const columns: ColumnConfig[] = [
  { label: "MW Change", key: "minChange" },
  { label: "Quantity", key: "quantity", multiplier: Multiplier.MW },
  { label: "Limit", key: "limit", multiplier: Multiplier.EUR },
];

const getDateFromProductId = (productId: string): string => {
  const parts = productId.split('-');
  const parsedDate = parse(parts[1], 'yyyyMMdd', new Date());
  return parsedDate ? format(parsedDate, 'dd/MM') : '';
};

interface TableProps {
  data: BidEntry[];
  modifyCell: (productId: string, bidIndex: number, key: keyof Bid, value: number) => void;
}

const BidTable: FC<TableProps> = observer(({ data, modifyCell }) => {
  const sortedData = [...data].sort((a, b) =>
    a.productId.localeCompare(b.productId)
  );

  const numColumns = 1 + data[0]?.bids.length * columns.length;
  const gridStyle = {
    '--columns': numColumns,
    '--rows': data.length + 1, // +1 for header
    maxHeight: 'var(--container-available)' // This is the available size within the modal
  } as React.CSSProperties;

  return (
    <div className="grid-table bid-table" style={gridStyle}>

      {/* Headers */}
      <div className="h l cell">
        Product - {getDateFromProductId(sortedData[0].productId)}
      </div>
      {sortedData[0]?.bids.map((_, bidIndex) =>
        columns.map((col) => (
          <div key={`header-${col.key}-${bidIndex}`} className="h cell">
            {col.label} {bidIndex + 1}
          </div>
        ))
      )}

      {/* Data Rows */}
      {sortedData.map((bidEntry, rowIndex) => (
        <Fragment key={`row-${rowIndex}`}>
          <div className="l cell">{formatProductName(bidEntry.productId)}</div>
          {bidEntry.bids.map((bid, bidIndex) =>
            columns.map((col) => (
              <div key={`cell-${rowIndex}-${bidIndex}-${col.key}`}
                className="cell">

                <input
                  className="input-field"
                  type="number"
                  value={bid[col.key] !== undefined && col.multiplier
                    ? bid[col.key] / col.multiplier : bid[col.key]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = Number(e.target.value);
                    const adjustedValue = col.multiplier
                      ? inputValue * col.multiplier : inputValue;
                    modifyCell(bidEntry.productId, bidIndex, col.key, adjustedValue);
                  }}
                />

              </div>
            ))
          )}
        </Fragment>
      ))}
    </div>
  );
});


export default BidTable;
