export function arraysEqual<T>(a: T[], b: T[]) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export function multiProductEquals(a: MultiProduct, b: MultiProduct) {
  return arraysEqual(a.contractIds, b.contractIds) && a.deliveryArea === b.deliveryArea
}

export class MultiProduct {
  constructor(public contractIds: string[], public deliveryArea: string | null) {}
}
