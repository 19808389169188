import './spread-helper.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import DatePicker from '../../components/DatePicker/DatePicker'
import { useAbortController } from '../../hooks/useAbortController'
import {
  type AuctionType,
  type HalfAuction,
  type IDAuction,
  key,
  type TransmissionRightAndMarginalPrice,
} from '../../classes/Interconnector'
import { getInterconnectors, getTransmissionInfo } from '../../actions/Interconnectors'
import { observer } from 'mobx-react'
import { Select } from '../../components/Select/Select'
import { toast } from 'react-toastify'
import { errorHandler } from './errorHandler'
import { SpreadAuction } from './SpreadAuction/SpreadAuction'
import { useSpreadTrades } from './hooks'

const SpreadHelper = observer(() => {
  const [date, setDate] = useState(moment())
  const [halfAuctions, setHalfAuctions] = useState<HalfAuction[]>([])
  const [halfAuction, setHalfAuction] = useState<HalfAuction | undefined>(undefined)
  const [auction, setAuction] = useState<IDAuction | undefined>(undefined)
  const [transmissionInfo, setTransmissionInfo] = useState<TransmissionRightAndMarginalPrice[]>([])

  const trades = useSpreadTrades(date)

  useAbortController(controller => {
    getInterconnectors(date, controller.signal)
      .then(data => setHalfAuctions(data))
      .catch(errorHandler('Could not get auctions'))
  }, [date])

  useAbortController(controller => {
    if (!halfAuction) return
    getTransmissionInfo(date, halfAuction, controller.signal)
      .then(infos => {
        if (infos.length === 0) toast.error('No transmission rights')
        setTransmissionInfo(infos)
      })
      .catch(errorHandler(`Could not get transmission rights for ${halfAuction.auctionName}`))
  }, [date, halfAuction])

  useEffect(() => {
    setHalfAuction(undefined)
  }, [halfAuctions])

  useEffect(() => {
    if (!halfAuction) return
    setAuction(undefined)
  }, [halfAuction])

  const handleHalfAuctionChange = (auctionKey: string) => {
    setHalfAuction(() => halfAuctions.find(a => key(a) === auctionKey))
  }

  const handleAuctionChange = (type: AuctionType) => {
    setAuction(() => halfAuction?.auctions.find(a => a.type === type))
  }

  return <div className='spread-helper'>
    <div className='options'>
      <DatePicker title={'Delivery date'} date={date} setDate={setDate} />
      <Select
        onChange={e => handleHalfAuctionChange(e.target.value)}
        element={halfAuction} options={halfAuctions}
        keyValue={a => key(a)}
        defaultOption={'-- Select direction --'}
        iconDisabled={true}
      />
      {halfAuction && (
        <Select
          onChange={e => handleAuctionChange(e.target.value as AuctionType)}
          element={auction} options={halfAuction?.auctions}
          keyValue={a => a.type}
          defaultOption={'-- Select auction --'}
          iconDisabled={true}
        />)}
    </div>
    {halfAuction && <SpreadAuction halfAuction={halfAuction} auction={auction} transmissionInfo={transmissionInfo} trades={trades} />}
  </div>
})

export default SpreadHelper
