import { observer } from 'mobx-react'
import { type RowEntry } from './OrderBookTable'
import OrderBookRow from './OrderBookRow'
import { Row } from '../Table/Table'
import React, { type FC, useEffect, useState } from 'react'

const ITEMS_PER_PAGE = 5

interface OrderBookRowsSideProps {
  rows: RowEntry[]
  isBids: boolean
  submitTrade: (isBuy: boolean, price?: number, qty?: number) => Promise<void>
  submitBoCTrade: (isBuy: boolean, limit?: number, qty?: number) => Promise<void>
  contractId: string
  quantities: number[]
  scrollElementRef: React.RefObject<HTMLDivElement>
  areaId: string
}

const OrderBookRowsSide: FC<OrderBookRowsSideProps> = observer(
  ({ rows, isBids, submitTrade, submitBoCTrade, contractId, quantities, scrollElementRef, areaId }) => {
    const [pageState, setPageState] = useState({
      page: 1,
      hasMore: false,
    })
    const rowsToRender = rows.slice(0, pageState.page * ITEMS_PER_PAGE)

    function load(page: number, overrideHasMore: boolean = false) {
      setPageState((prev) => {
        if (page === prev.page) return prev

        const newPageState = Object.assign({}, prev)

        if (!overrideHasMore && rows.length <= page * ITEMS_PER_PAGE) newPageState.hasMore = false
        if (overrideHasMore) newPageState.hasMore = true

        newPageState.page = page
        return newPageState
      })
    }

    function loadMore() {
      setPageState((prev) => {
        if (!prev.hasMore) return prev

        const newPageState = Object.assign({}, prev)
        newPageState.page += 1

        if (rows.length <= newPageState.page * ITEMS_PER_PAGE) newPageState.hasMore = false

        return newPageState
      })
    }

    useEffect(() => {
      const scrollElement = scrollElementRef.current
      if (scrollElement === null) return

      const { scrollHeight, clientHeight } = scrollElement

      if (scrollHeight <= clientHeight) {
        loadMore()
      }
    }, [rows, pageState.hasMore, scrollElementRef.current, pageState.page])

    useEffect(() => {
      const scrollElement = scrollElementRef.current
      if (scrollElement === null) return

      const callback = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollElement
        if (scrollTop < 1) {
          load(1, true)
          return
        }
        if (pageState.hasMore && scrollTop + clientHeight + 300 >= scrollHeight) {
          loadMore()
        }
      }

      scrollElement.addEventListener('scroll', callback)

      return () => {
        scrollElement.removeEventListener('scroll', callback)
      }
    }, [scrollElementRef.current, pageState.hasMore])

    useEffect(() => {
      if (pageState.page * ITEMS_PER_PAGE >= rows.length) return
      setPageState((prev) => Object.assign({}, prev, { hasMore: true }))
    }, [rows])

    return (
      <>
        {rowsToRender.map((row) => (
          <Row key={row.id} className={isBids ? 'bid-side' : 'ask-side'}>
            <OrderBookRow
              row={row}
              submitTrade={submitTrade}
              submitBoCTrade={submitBoCTrade}
              contractId={contractId}
              quantities={quantities}
              isBidRow={isBids}
              areaId={areaId}
            />
          </Row>
        ))}
      </>
    )
  }
)

export default OrderBookRowsSide
