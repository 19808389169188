import { type Curve } from '../../classes/OrderPlan'
import { type CSSProperties, type FC, Fragment } from 'react'
import './curve-table.scss'
import { copyToClipboard, groupBy, Multiplier } from '../../Util'
import { IoCopyOutline } from 'react-icons/io5'

interface CurveTableProps {
  curves: Curve[]
  height: number
  style?: { useColoring?: boolean }
}

export interface Style extends CSSProperties {
  '--columns': number;
  '--rows': number
}

// If cell coloring is enabled, show green for positive, red for negative values
const getCellColor = (enabled?: boolean) => (value: number | null) => {
  if (enabled) {
    if (value !== null && value > 0) return 'green'
    if (value !== null && value < 0) return 'red'
  }
  return undefined
}

const CurveTable: FC<CurveTableProps> = ({ curves, height, style }) => {
  // Get all unique prices, if there are multiple points with the same price, add them to the list
  const prices: number[] = []
  for (const curve of curves) {
    for (const [price, points] of groupBy(curve.points, p => p.price).entries()) {
      const count = prices.filter(p => p === price).length
      if (count < points.length) {
        prices.push(...Array.from({ length: points.length - count }, () => price))
      }
    }
  }

  // Get all columns/rows in sorted order
  const columns = [...prices].sort((a, b) => a - b)
  const rows = curves.sort((a, b) => new Date(a.deliveryStart).getTime() - new Date(b.deliveryStart).getTime())

  // Create dictionary row -> (price -> volume)
  // Also create sum dictionary for every price step
  const tableDictionary: Record<string, Array<number | null>> = {}
  const sums: number[] = columns.map(() => 0)

  for (const row of rows) {
    // Create dictionary per row (per curve)
    const rowDictionary: Array<number | null> = columns.map(() => null)

    // Copy the points to be able to remove them
    const points = [...row.points].sort((a, b) => b.volume - a.volume)

    // For each price step, assign the volume to the price step
    // Also add the volume to the sum for the price step
    for (const [index, price] of columns.entries()) {
      const pointIndex = points.findIndex(p => p.price === price)
      if (pointIndex === -1) continue

      const point = points[pointIndex]
      points.splice(pointIndex, 1)

      rowDictionary[index] = point?.volume ?? null
      sums[index] = (sums[index] ?? 0) + (point?.volume ?? 0)
    }

    tableDictionary[row.contractName] = rowDictionary
  }

  const coloring = getCellColor(style?.useColoring)

  // Write table value to string for Excel
  const writeToString = () => {
    const headers = ['', ...columns.map(c => c / Multiplier.EUR)].join('\t')
    const rowLines = rows.map(row => {
      const cn = row.contractName
      return [cn, ...columns.map((_, index) => {
        const value = tableDictionary[cn][index]
        return value !== null ? value / Multiplier.MW : ''
      })].join('\t')
    })
    return [headers, ...rowLines].join('\n')
  }

  return (
    <div
      style={{ '--columns': columns.length + 1, '--rows': rows.length + 2, height } as Style}
      className="grid-table"
    >
      { /** Add column headers */}
      <>
        <div className="h l cell" id="corner-cell" onClick={() => {
          copyToClipboard(writeToString())
        }}>
          <IoCopyOutline title={'Copy to clipboard'} />
        </div>
        {columns.map((c, index) => <div key={`col-${index}`} className="h cell">{c / Multiplier.EUR}</div>)}
      </>
      { /** Add body */}
      <>
        {rows.map(row => {
          const { contractName } = row

          // Return one row
          return (<Fragment key={contractName}>
            <div className="l cell">{contractName}</div>
            {columns.map((_, index) => {
              const value = tableDictionary[contractName][index]
              const cellValue = value !== null ? value / Multiplier.MW : null
              const color = coloring(cellValue)

              return <div
                key={`${contractName}-${index}`}
                className="cell"
                style={{ color }}
              >
                {cellValue}
              </div>
            })}
          </Fragment>)
        })}
      </>
      { /** Add sums */}
      <>
        <div className="f l cell">Sum</div>
        {columns.map((_, index) =>
          <div key={`sum-${index}`} className="f cell">{(sums[index] ?? 0) / Multiplier.MW}</div>,
        )}
      </>
    </div>
  )
}

export default CurveTable