/**
 * This class is used to parse a (simple) cron expression. This is to say that only
 * a limited subset of cron expressions are supported.
 */
export class SimpleCronExpression {
  second: number | '*' = '*'
  hour: number | '*' = '*'
  minute: number | '*' = '*'
  dayOfMonth: number | '*' = '*'
  month: number | '*' = '*'
  dayOfWeek: number | '*' = '*'
  year: number | '*' = '*'

  toCronExpression(): string {
    return `${this.second} ${this.minute} ${this.hour} ${this.dayOfMonth} ${this.month} ${this.dayOfWeek} ${this.year}`
  }

  static fromCronExpression(cronExpression: string): SimpleCronExpression {
    const cronFields = cronExpression.split(' ')
    const simpleCronExpression = new SimpleCronExpression()

    simpleCronExpression.second = parseField(cronFields[0])
    simpleCronExpression.minute = parseField(cronFields[1])
    simpleCronExpression.hour = parseField(cronFields[2])
    simpleCronExpression.dayOfMonth = parseField(cronFields[3])
    simpleCronExpression.month = parseField(cronFields[4])
    simpleCronExpression.dayOfWeek = parseField(cronFields[5])
    simpleCronExpression.year = parseField(cronFields[6])

    return simpleCronExpression
  }
}

function parseField(field: string): number | '*' {
  if (field === '*') {
    return '*'
  }
  return parseInt(field)
}
