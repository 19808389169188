import axiosInstance from '../axiosInstance'
import { type SpotPrice } from '../classes/SpotPrice'
import { type Portfolio } from '../classes/Portfolio'

// Get spot prices for portfolio
const getSpotPrices = async (portfolio: Portfolio): Promise<SpotPrice[]> => await axiosInstance
  .get<SpotPrice[]>(`/api/prices/${portfolio.eic}`)
  .then(response => response.data)

// Make backend fetch spot prices from provider
const fetchSpotPrices = async () => await axiosInstance
  .post('/api/prices/fetch')

export {
  getSpotPrices,
  fetchSpotPrices
}