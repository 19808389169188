import { useLayoutEffect, useRef } from 'react'

type Observable = HTMLDivElement | SVGSVGElement

// Use ResizeObserver API for ref. Takes lambda to invoke on resize. Returns reference.
export const useResizeObserver = <T extends Observable = HTMLDivElement> (callback: (e: ResizeObserverEntry) => void) => {
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => { callback(entries[0]) })
    if (ref.current) resizeObserver.observe(ref.current)

    return () => { resizeObserver.disconnect() }
  }, [ref, callback])

  return ref
}