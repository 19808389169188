import React, { type FC } from 'react'
import './modal.scss'

// Describes a button in the bottom of the modal
interface Control {
  name: string,
  action: () => void
  disabled?: boolean
}

interface ModalProps {
  children: React.ReactNode
  show: boolean
  buttons: Control[]
  onClickOutside: (() => void) | undefined
}

/**
 * This is a Modal.
 *
 * A css variable, --container-available, which describes the max height of the child-container is available within children
 * @param children the childe to render
 * @param show whether the modal is visible
 * @param buttons action buttons to add
 * @param onClickOutside action to add if user clicks outside modal
 * @constructor
 */
export const Modal: FC<ModalProps> = ({ children, show, buttons, onClickOutside }) => {
  if (!show) return null

  const clickBackgroundAction = onClickOutside ?? (() => {
  })

  return <div className={'modal-background'} onClick={clickBackgroundAction}>
    <div className={'modal-elem'}>
      <div
        className={'modal-container'}
        onClickCapture={e => e.stopPropagation()} // Stop clicks from reaching background
      >
        {children}
      </div>
      <div
        className={'modal-controls'}
        onClick={e => e.stopPropagation()} // Stop clicks from reaching background
      >
        {buttons
          .map(({ name, action, disabled }) => {
              const active = disabled !== undefined ? !disabled : true // Default to enabled

              return <div
                className={`button ${active ? '' : 'disabled'}`}
                key={name}
                onClick={active ? action : undefined}
              >
                {name}
              </div>
            }
          )}
      </div>
    </div>
  </div>
}