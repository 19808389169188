import React, { useEffect, useState } from 'react'
import './portfolioPicker.scss'
import Table from '../PortfolioTable/PortfolioTable'
import { type IMessage, type StompSubscription } from '@stomp/stompjs'
import { type TableUpdateEvent } from '../../classes/TableUpdateEvent'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { getContracts, getPortfolios } from '../../actions/Portfolios'
import { type Portfolio } from '../../classes/Portfolio'
import { useSubscribe } from '../../hooks/useSubscribe'


const PortfolioPicker = observer(() => {
  // Contains the product id from the portfolio that we want to see. Only set if user picks something that is not the default one
  const [productId, setProductId] = useState<string | undefined>(undefined)

  const { configStore, socketStore, connectionStore } = useStores()
  const { socket, connected } = socketStore
  const { portfolios, portfolio, products } = configStore
  const { backendConnectionChanged } = connectionStore
  const [subscription, setSubscription] = useState<
    StompSubscription | undefined
  >(undefined)

  useSubscribe('/topic/portfolios', (p: Portfolio) => {
    configStore.updatePortfolio(p)
  })

  const handleUpdate = (message: IMessage) => {
    const tableUpdateEvent: TableUpdateEvent = JSON.parse(message.body)
    configStore.updateProduct(tableUpdateEvent)
  }

  const subscribe = () => {
    if (portfolios.length === 0) return
    if (portfolio === undefined) return
    if (!socket.connected) return
    if (subscription != null) {
      subscription.unsubscribe()
    }
    const newSub = socket.subscribe(
      `/topic/spread/${portfolios[portfolio].exchange}/${portfolios[portfolio].eic}`,
      handleUpdate
    )
    setSubscription(newSub)
  }

  useEffect(() => {
    getPortfolios().then(portfolios => {
      configStore.setPortfolios(portfolios)
    })
  }, [])

  useEffect(() => {
    if (portfolio === undefined) return
    getContracts(portfolios[portfolio], undefined, productId)
      .then(products => { configStore.setProducts(products) })
  }, [portfolios, portfolio, productId, backendConnectionChanged])

  useEffect(() => {
    subscribe()
  }, [portfolio])

  useEffect(() => {
    // To make us subscribe to first topic
    if (subscription == null && connected) {
      subscribe()
    }
  }, [connected])

  return (
    <div className="portfolio-picker">
      <Table rows={products} productId={productId} setProductId={setProductId}/>
    </div>
  )
})

export default PortfolioPicker
