import { Cross } from '../Icons/Icons'
import React, { useMemo, useState } from 'react'
import { type EpexPortfolio } from '../../classes/Portfolio'
import './epex-menu.scss'
import { deactivateAllAccountOrders } from '../../actions/Epex'
import { observer } from 'mobx-react'
import { useSubscribe,  } from '../../hooks/useSubscribe'
import { type CashLimit } from '../../classes/CashLimit'

// This menu shows elements only relevant for EPEX trading
const EpexMenu = observer(({ portfolio }: { portfolio: EpexPortfolio }) => {
  const [cashLimit, setCashLimit] = useState<number | undefined>(undefined)
  const mbrId = useMemo(() => portfolio.acctId.replaceAll('-', ''), [portfolio.acctId])

  useSubscribe(`/topic/epex/${mbrId}/cash-limit`, (c: CashLimit) => {
    setCashLimit(c.cashLimit)
  }, [portfolio.acctId])

  return <div className='epex-menu-wrapper'>
    <div className='epex-menu'>
      <div>
        <span style={{ fontWeight: 'bold' }}>EPEX</span>
        {` - Acct ID: ${portfolio.acctId}`}
      </div>
      <div
        className={'clickable deac-all-button'}
        title={`Deactivate all orders for ${portfolio.acctId}`}
        onClick={() => { deactivateAllAccountOrders(portfolio).then(r => { console.log(r); }) }}
      >
        <Cross />
        {'Deact. All'}
      </div>
    </div>
    {cashLimit !== undefined && <div className='epex-menu'>
      <span style={{ fontWeight: 'bold' }}>Cash Limit:</span>
      <div>{`${cashLimit / 100}`}</div>
    </div>}
  </div>
})

export default EpexMenu