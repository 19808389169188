// We use a different base here, as the backend is different.
import { type Moment } from 'moment/moment'
import axiosInstance from '../axiosInstance'
import { AllocationAuction, Auction, type IAllocationAuction, type IAuction } from '../classes/Auction'
import { type AllocationBid, type BidValue, type OrderPlan, type OrderPlanStatus } from '../classes/OrderPlan'
import { type AuctionConfig } from '../classes/AuctionConfig'
import type { AuctionOfferedCapacity } from '../classes/AuctionOfferedCapacity'
import { type AllocationConfig } from '../classes/AllocationConfig'

const base = process.env.REACT_APP_AUCTIONS_URI

// Get auctions for a delivery day
export const getAuctions = async (date: Moment) => {
  const formatted = date.format('YYYY-MM-DD')
  return await axiosInstance
    .get<IAuction[]>(`${base}/api/auctions?deliveryDateFrom=${formatted}&deliveryDateTo=${formatted}`)
    .then((res) => res.data.map((d) => new Auction(d)))
}

// Get plans for an auction.
export const getOrderPlans = async (auction: Auction, signal: AbortSignal | undefined) =>
  await axiosInstance
    .get<OrderPlan[]>(`${base}/api/auctions/${auction.auctionId}/plans`, { signal })
    .then((res) => res.data)

// Accept or reject an order plan
export const updatePlanState = async (planId: string, accept: boolean) =>
  await axiosInstance.patch(`${base}/api/plans/${planId}/state`, { accept })

export const getAllocationAuctions = async (date: Moment) => {
  const deliveryDate = date.format('YYYY-MM-DD')
  return await axiosInstance
    .get<IAllocationAuction[]>(`${base}/api/allocation-auctions`, { params: { deliveryDate }})
    .then((res) => res.data.map((a) => new AllocationAuction(a)))
}

// Get current bids in the system for AllocationAuction
export const getAllocationBids = async (auction: AllocationAuction, signal: AbortSignal | undefined) =>
  await axiosInstance
    .get<AllocationBid[]>(`${base}/api/allocation-auctions/${auction.id}/bids`, { signal })
    .then((res) => res.data)

// Post new allocation bid
export const postAllocationBid = async (direction: string, date: Date, bidValues: BidValue[]) =>
  await axiosInstance
    .post<AllocationBid>(`${base}/api/allocation-bids`, { direction, date, bidValues })
    .then((res) => res.data)

// Change bid status to 'SUBMITTED' or 'DISCARDED'
export const changeBidStatus = async (bid: AllocationBid, newStatus: OrderPlanStatus) =>
  await axiosInstance
    .patch<AllocationBid>(`${base}/api/allocation-bids/${bid.bidId}`, { status: newStatus })
    .then((res) => res.data)

// Put new auction config
export const putAuctionConfig = async (config: AuctionConfig) =>
  await axiosInstance.put(`${base}/api/configs/auction`, config).then((res) => res.data)

// Get the offered capacities for an AllocationAuction
export const getOfferedCapacity = async (auction: AllocationAuction, signal: AbortSignal | undefined) =>
  await axiosInstance
    .get<AuctionOfferedCapacity[]>(`${base}/api/allocation-auctions/${auction.id}/offered-capacities`, { signal })
    .then((res) => res.data)

// Put new allocation config
export const putAllocationConfig = async (config: AllocationConfig) =>
  await axiosInstance.put(`${base}/api/configs/allocation`, config).then((res) => res.data)
