import './orderBookSettings.scss'
import { useStores } from '../../use-stores'
import { observer } from 'mobx-react'
import { OrderRequestTypeEnum } from '../../classes/OrderRequest'
import { formatQty } from '../OrderBookTable/OrderBookTable'
import { toast } from 'react-toastify'
import useKeyDownListener from '../../hooks/useKeyDownListener'
import { Select } from '../Select/Select'
import { identity } from '../../Util'

// Returns label-input div with checkbox
function LabeledCheckbox(props: { label: string; checked: boolean; onChange: () => void }) {
  return (
    <div className="label-input">
      <label>{props.label}</label>
      <input checked={props.checked} onChange={props.onChange} type="checkbox" />
    </div>
  )
}

const OrderBookSettings = observer(() => {
  const { uiStore, orderBookStore } = useStores()
  const className = uiStore.settingsVisible ? 'orderbook-settings visible' : 'settings'
  const {
    orderFilledSoundEnabled,
    directOrdersEnabled,
    orderRequestTypeEnum,
    defaultQuantities,
    orderBookQtyLimit,
    quantityLineEnabled,
    imbalanceTrackers,
    profitEnabled,
    clipSize,
    latestTradePriceEnabled,
  } = orderBookStore

  function handleQuantityChange(idx: number) {
    function aux(event: React.ChangeEvent<HTMLInputElement>) {
      const qty = event.target.value
      const newDefaultQuantities = defaultQuantities.slice()

      const quantityLimit = orderBookQtyLimit
      if (qty === '') {
        newDefaultQuantities[idx] = 0
        orderBookStore.setDefaultQuantities(newDefaultQuantities)
        return
      }

      const qtyFormatted = qty.replace(',', '.')
      const qtyNum = parseFloat(qtyFormatted)

      if (isNaN(qtyNum)) {
        toast.error('Quantity must be a number')
        return
      }
      if (qtyNum < 0 || qtyNum > quantityLimit / 1000) {
        toast.error(`Quantity must be between 0 and ${quantityLimit / 1000}`)
        return
      }
      if ((qtyNum * 10) % 1 !== 0) {
        toast.error('Quantity can only have one decimal place')
        return
      }

      newDefaultQuantities[idx] = qtyNum * 1000
      orderBookStore.setDefaultQuantities(newDefaultQuantities)
    }
    return aux
  }

  function handleImbalanceTrackerChange(idx: number) {
    function aux(event: React.ChangeEvent<HTMLInputElement>) {
      const newTrackingImbalance = imbalanceTrackers.slice()
      newTrackingImbalance[idx] = event.target.checked
      orderBookStore.setImbalanceTrackers(newTrackingImbalance)
    }

    return aux
  }

  useKeyDownListener('Escape', () => {
    if (uiStore.settingsVisible) uiStore.toggleSettings()
  })

  return (
    <div className={className}>
      <div className="settings-form">
        <div className="settings-header">
          <h4>Order Book</h4>
        </div>
        <div className="label-input">
          <label>Order Type</label>
          <Select
            onChange={(e) => {
              orderBookStore.setOrderRequestType(e.target.value as OrderRequestTypeEnum)
            }}
            element={orderRequestTypeEnum}
            options={Object.values(OrderRequestTypeEnum).map(t => t)}
            keyValue={identity}
          />
        </div>
        <LabeledCheckbox
          label={'DoP'}
          checked={directOrdersEnabled}
          onChange={() => {
            orderBookStore.setDirectOrdersEnabled(!directOrdersEnabled)
          }}
        />
        <LabeledCheckbox
          label={'Show Profit'}
          checked={profitEnabled}
          onChange={() => {
            orderBookStore.setProfitEnabled(!profitEnabled)
          }}
        />
        <LabeledCheckbox
          label={'Sound'}
          checked={orderFilledSoundEnabled}
          onChange={() => {
            orderBookStore.setOrderFilledSoundEnabled(!orderFilledSoundEnabled)
          }}
        />
        <LabeledCheckbox
          label={'Quantity Line'}
          checked={quantityLineEnabled}
          onChange={() => {
            orderBookStore.setQuantityLineEnabled(!quantityLineEnabled)
          }}
        />
        <LabeledCheckbox
          label={'Latest Trade Price'}
          checked={latestTradePriceEnabled}
          onChange={() => {
            orderBookStore.setLatestTradePriceEnabled(!latestTradePriceEnabled)
          }}
        />
        <div className='label-input'>
          <label>Quantity Limit</label>
          <span className='mw'>
            <input
              type='number'
              defaultValue={orderBookQtyLimit / 1000}
              min={0.1}
              onChange={(e) => {
                const newQtyLimit = parseFloat(e.target.value)

                if (isNaN(newQtyLimit)) {
                  return
                }

                orderBookStore.setOrderBookQtyLimit(newQtyLimit * 1000)
              }}
            />
          </span>
        </div>
        <div className="label-input">
          <label>Clip Size</label>
          <span className='mw'>
            <input
              type="number"
              defaultValue={clipSize / 1000}
              min={0.1}
              onChange={(e) => {
                const newClipSize = parseFloat(e.target.value)

                if (isNaN(newClipSize)) {
                  return
                }

                orderBookStore.setClipSize(newClipSize * 1000)
              }}
            />
          </span>
        </div>
        <div className="label-input">
          <label>Default Quantities:</label>
          <br />
          <div className="multi-quantity names">
            <p className="quantity">DoP</p>
            <p className="quantity">BoC</p>
            <p className="quantity">B2</p>
            <p className="quantity">B1</p>
            <p className="quantity">A1</p>
            <p className="quantity">A2</p>
            <p className="quantity">BoC</p>
            <p className="quantity">DoP</p>
          </div>
          <br />
          <div className="multi-quantity values">
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[6])}
              min={0.1}
              onChange={handleQuantityChange(6)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[0])}
              min={0.1}
              onChange={handleQuantityChange(0)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[1])}
              min={0.1}
              onChange={handleQuantityChange(1)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[2])}
              min={0.1}
              onChange={handleQuantityChange(2)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[3])}
              min={0.1}
              onChange={handleQuantityChange(3)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[4])}
              min={0.1}
              onChange={handleQuantityChange(4)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[5])}
              min={0.1}
              onChange={handleQuantityChange(5)}
            />
            <input
              className="quantity"
              type="text"
              defaultValue={formatQty(defaultQuantities[7])}
              min={0.1}
              onChange={handleQuantityChange(7)}
            />
          </div>

          <div className="multi-quantity checks">
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[6]}
              onChange={handleImbalanceTrackerChange(6)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[0]}
              onChange={handleImbalanceTrackerChange(0)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[1]}
              onChange={handleImbalanceTrackerChange(1)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[2]}
              onChange={handleImbalanceTrackerChange(2)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[3]}
              onChange={handleImbalanceTrackerChange(3)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[4]}
              onChange={handleImbalanceTrackerChange(4)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[5]}
              onChange={handleImbalanceTrackerChange(5)}
            />
            <input
              className="quantity"
              type="checkbox"
              checked={imbalanceTrackers[7]}
              onChange={handleImbalanceTrackerChange(7)}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default OrderBookSettings
