import { type Moment } from 'moment/moment'
import { type FC } from 'react'
import './date-picker.scss'
import { LeftArrow, RightArrow } from '../Icons/Icons'

interface DatePickerProps {
  title: string
  date: Moment
  setDate: (d: Moment) => void
}

const DatePicker: FC<DatePickerProps> = ({ title, date, setDate }) => {
  // Add days to date and call setDate with new date
  const add = (days: number) => { setDate(date.clone().add(days, 'days')) }

  return <div className='datepicker'>
    <div className='datepicker-inner'>
      <button className='clickable' onClick={() => { add(-1) }}><LeftArrow /></button>
      <div className='date'><p>{date.toDate().toLocaleDateString()}</p></div>
      <button className={'clickable'} onClick={() => { add(1) }}>{<RightArrow />}</button>
    </div>
  </div>
}

export default DatePicker