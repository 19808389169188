import { type Portfolio } from '../../../classes/Portfolio'
import { IoArrowForwardSharp } from 'react-icons/io5'
import React, { type FC } from 'react'

interface TradingDirectionProps {
  exportPortfolio: Portfolio
  importPortfolio: Portfolio
}

// This component renders the trading direction of an intraday interconnector auction
export const TradingDirection: FC<TradingDirectionProps> = ({ exportPortfolio, importPortfolio }) => {
  return <div className='trading-direction'>
    <div className='info-header'>Export area</div>
    <span />
    <div className='info-header'>Import area</div>
    <div>{exportPortfolio.name}</div>
    <div className='icon-holder'>
      <IoArrowForwardSharp />
    </div>
    <div>{importPortfolio.name}</div>
  </div>
}