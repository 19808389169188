import React, { useRef } from 'react'
import { type Notification } from '../classes/Notification'
import { toast, type ToastItem, type ToastOptions } from 'react-toastify'

/**
 * Returns function for handling notifications produced by the backend.
 *
 * These messages can be both error and info notifications that should be displayed to the user.
 * It stores an internal state of toast ids, so it will update messages according to their ID.
 */
const useNotifications = () => {
  const toastIds = useRef<string[]>([])

  /**
   * Handle messages produced by the backend.
   *
   * These messages can be both error and info notifications that should be displayed to the user.
   *
   * @param notification
   */
  const handleNotification = (notification: Notification) => {
    const { title, description, autoClose, type, id, isLoading } = notification
    const descriptionSuffix = description ? `: ${description}` : ''

    const time = !autoClose ? (
      <div
        style={{
          position: 'absolute',
          right: '8px',
          bottom: '8px',
          fontSize: '10px',
        }}
        className="time"
      >
        {new Date().toLocaleTimeString('da-DK')}
      </div>
    ) : null

    // Remove id from list if the toast is closed. This is so we make new toast if update arrives.
    const onChange = (e: ToastItem) => {
      if (e.status === 'removed') {
        toastIds.current = toastIds.current.filter(i => i !== e.id)
      }
    }

    toast.onChange(onChange)

    const options: ToastOptions = { toastId: id, autoClose, type, isLoading } as ToastOptions
    const text = (
      <div>
        {time}
        <span style={{ fontWeight: 'bold' }}>{`${title}`}</span>
        {descriptionSuffix}
      </div>
    )

    // If the current id already exists, we update the toast
    if (id && toastIds.current.includes(id)) {
      toast.update(id, {...options, render: text })
    }
    // Else we create a toast and save the id
    else if (id) {
      toastIds.current.push(id)
      toast(text, options)
    }
    else {
      toast(text, options)
    }
  }

  return handleNotification
}

export default useNotifications