import React, { useEffect, useState } from 'react'
import './executor.scss'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import { type Dictionary } from '../../classes/Order'
import {
  type Field,
  isNumberField,
  isOffsetField,
  isSelectField,
  type ListField,
  type NumberFieldType,
  type SelectField,
  type StrategySchema,
} from '../../classes/StrategySchema'
import { getMultiplier, OFFSET } from '../../Util'
import { type Timing } from '../../stores/TimingStore'
import SingleExecutor from './SingleExecutor'
import MultiExecutor from './MultiExecutor'

export type Variables = Dictionary<
  number | string | Array<Dictionary<number | string>>
>

// Split Pascal Case (class name) into string list
export const splitPascalCase = (s: string) => s.split(/(?=[A-Z])/)

// Uppercase the first letter of a string and return new string
export const upperCaseFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1)

export const lowerCaseAllButFirst = (s: string) =>
  s.charAt(0) + s.slice(1).toLowerCase()

const Executor = observer(() => {
  const { configStore, executorStore, spotStore, timingStore } = useStores()
  const { getPortfolio, getProduct, getSettingsValue } = configStore
  const { schema, setSchema, schemas, setSchemas } = executorStore

  const [variables, setVariables] = useState<Variables | undefined>(undefined)


  const getStrategySchemas = async (timings: Timing[]) => {
    try {
      const resp = await axiosInstance.get<StrategySchema[]>(
        'api/strategies/schemas'
      )
      const strategySchemas = resp.data

      // Add timings to bottleneck schema
      const bottleneckSchema = strategySchemas.find(
        (schema) => schema.name === 'Bottleneck'
      )
      if (bottleneckSchema === undefined) {
        toast.error(
          `Could not find bottleneck schema. 
          This should not happen, so please notify your friendly neighbourhood IT department`
        )
        return
      }

      const timingField: SelectField = {
        '@type': 'SelectField',
        name: 'trigger',
        options: timings.map((timing) => {
          return timing.trigger
        }),
      }

      bottleneckSchema.fields.push(timingField)
      setSchemas(strategySchemas)
    } catch {
      toast.error('Could not fetch strategies')
    }
  }

  useEffect(() => {
    getStrategySchemas(timingStore.getTimings) // Run once on startup
  }, [timingStore.getTimings])

  /**
   * Set all offset values, meaning values where the default is offset from spot price.
   */
  const setAllOffsetDefaults = () => {
    // Take out the current dictionary
    const current: Variables = Object.assign({}, variables)

    schema?.fields.forEach((field) => {
      // Check that it is a offset field
      if (isOffsetField(field)) {
        // Get settings value + spot price
        const offsetValue = getOffsetValue(field.name)
        if (!offsetValue) return

        // Set value in variables
        current[field.name] = offsetValue
      }
    })

    // Update state
    setVariables(current)
  }

  // Get value of offset default and spot price combined
  const getOffsetValue = (name: string) => {
    const eicCode = getPortfolio?.eic
    const deliveryStart = getProduct?.deliveryStart
    if (!eicCode || !deliveryStart) return

    const offsetValue = getSettingsValue(name + OFFSET)
    if (!offsetValue) return
    // Get spot price and set variable to spot price + offset
    const spotPrice = spotStore.getSpotPrice(eicCode, deliveryStart)
    if (spotPrice != null) {
      return spotPrice.price + offsetValue
    }
  }

  useEffect(() => {
    setAllOffsetDefaults()
  }, [getProduct])

  /**
   * Sets default values for variables in-place.
   * @param fields Fields to set default values for
   * @param defaultVariables Variables to set default values in
   */
  const assignDefaultVariables = (
    fields: Field[],
    defaultVariables: Variables
  ) => {
    // For each select field, default to the first option = 0
    fields.forEach((field) => {
      if (isSelectField(field)) {
        defaultVariables[field.name] = field.options[0]
      }
      else if (isNumberField(field)) {
        const defaultValue = getSettingsValue(field.name)
        if (defaultValue !== undefined) {
          defaultVariables[field.name] = defaultValue
        }
      }
      else if (isOffsetField(field)) {
        const value = getOffsetValue(field.name)
        if (value !== undefined) {
          defaultVariables[field.name] = value
        }
      }
      else {
        defaultVariables[field.name] = []
      }
    })
  }

  const chooseSchema = (strategySchema: StrategySchema) => {
    setSchema(strategySchema)
    // Create dictionary and set default values for SelectFields and add type for backend parsing.
    const defaultVariables: Variables = {
      '@type': strategySchema.name,
    }

    const fields = strategySchema.fields

    assignDefaultVariables(fields, defaultVariables)
    setVariables(defaultVariables)
  }

  const setListVariable = (
    name: string,
    idx: number,
    type: string,
    listName: string,
    value: number
  ) => {
    const multiplier = getMultiplier(type as NumberFieldType)
    if (variables === undefined) return

    // Create new dictionary as React render state changes are based on reference and not value
    const dict: Variables = Object.assign({}, variables)
    const listVariables: Dictionary<number | string> = (dict as any)[name][idx]
    listVariables[listName] = Number(value.toFixed(2)) * multiplier // Make sure we only have two decimal points
    setVariables(dict)
  }



  const handleListFieldChange = (field: ListField) => {
    const defaultListVariable: Dictionary<number | string> = {}
    const fields = field.listType

    // Set default values for list variables
    assignDefaultVariables(fields, defaultListVariable)

    const handler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const amount = Number(e.target.value)

      setVariables((prev) => {
        if (prev === undefined) return // variables should not be null, but check is still necessary
        const newVariables = Object.assign({}, prev)

        const listVariables = prev[field.name] as Array<Dictionary<number | string>>

        const newListVariables = listVariables.slice(0, amount)
        for (let i = newListVariables.length; i < amount; i++) {
          newListVariables.push(Object.assign({}, defaultListVariable))
        }

        newVariables[field.name] = newListVariables

        return newVariables
      })
    }

    return handler
  }

  // We do not support MultiLayerBestOrderContinuous for several products.
  if (schema === undefined) {
    return (
      <div className="executor empty">
        {schemas
          .filter(s => !(
            s.name === 'MultiLayerBestOrderContinuous'
            && configStore.pickedProducts.length > 1)
          )
          .map(s => (
          <div
            key={s.name}
            className="strategy-option"
            onClick={() => { chooseSchema(s) }}
          >
            {lowerCaseAllButFirst(splitPascalCase(s.name).join(' '))}
          </div>
        ))}
      </div>
    )
  }

  const executor = configStore.pickedProducts.length < 2 ? (
    <SingleExecutor
      schema={schema}
      defaultVariables={variables}
      handleListFieldChange={handleListFieldChange}
      setListVariable={setListVariable}
      setSchema={setSchema}
    />
  ) : (
    <MultiExecutor
      schema={schema}
      setSchema={setSchema}
    />
  )

  return (
    <div className="executor">
      {executor}
    </div>
  )
})

export default Executor
