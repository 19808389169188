import React, { type FC, Fragment, useEffect, useState } from 'react'
import { getMultiplier, identity } from '../../Util'
import { upperCaseFirstLetter, type Variables } from './Executor'
import {
  isNumberField,
  type ListField,
  type NumberField,
  NumberFieldType,
  type OffsetField,
  type SelectField,
} from '../../classes/StrategySchema'
import { type Dictionary } from '../../classes/Order'
import { hasError } from './ExecutorHelpers'
import { Select } from '../Select/Select'

type PropField = NumberField | OffsetField

interface FieldProps<T extends PropField> {
  field: T,
  variables: Variables | undefined,
  setVariable: (type: string, name: string, value: number) => void
}

// Get value from variables dictionary. Return undefined if nothing is present.
const getValue = (variables: Variables | undefined, name: string, type: NumberFieldType) => {
  if (variables === undefined) return undefined
  if (variables[name] === undefined) return undefined
  return Number(variables[name]) / getMultiplier(type)
}


const NumberFieldComponent: FC<FieldProps<NumberField>> = ({ field, variables, setVariable }) => {
  const value = getValue(variables, field.name, field.type)
  const error = value && hasError(field.name, value * getMultiplier(field.type)) ? 'error' : ''
  return (
    <div key={field.name} className={`field-group ${error}`}>
      <label title={field.type}>
        {upperCaseFirstLetter(field.name)}
      </label>
      {/* Use the field type 'eur' or 'mw' as wrapper element */}
      <span className={field.type.toLowerCase()}>
        <input
          value={value}
          type="number"
          onChange={(e) => { setVariable(field.type, field.name, Number(e.target.value)) }}
        />
      </span>
    </div>
  )
}

const OffsetFieldComponent: FC<FieldProps<OffsetField>> = ({ field, variables, setVariable }) => {
  const type = NumberFieldType.EUR
  const value = getValue(variables, field.name, type)
  return (
    <div key={field.name} className="field-group">
      <label title={NumberFieldType.EUR}>
        {upperCaseFirstLetter(field.name)}
      </label>
      <input
        value={value}
        type="number"
        onChange={(e) => { setVariable(type, field.name, Number(e.target.value)) }}
      />
    </div>
  )
}

interface SelectFieldProps {
  field: SelectField
  setSelect: (name: string, value: string) => void
}

const SelectFieldComponent: FC<SelectFieldProps> = ({ field, setSelect }) => {
  const [selectState, setSelectState] = useState(field.options[0])

  useEffect(() => { setSelect(field.name, selectState) }, [selectState])

  return (
    <div key={field.name} className="field-group">
      <label>{upperCaseFirstLetter(field.name)}</label>
      <Select onChange={(e) => setSelectState(e.target.value)}
              element={selectState}
              options={field.options}
              keyValue={identity}
      />
    </div>
  )
}

interface ListFieldProps {
  field: ListField
  variables: Variables | undefined,
  handleListFieldChange: (field: ListField) => (e: React.ChangeEvent<HTMLInputElement>) => void
  setListVariable: (name: string, idx: number, type: string, listName: string, value: number) => void
}

const ListFieldComponent: FC<ListFieldProps> = ({ field, variables, handleListFieldChange, setListVariable }) => {
  const noOfElems = (variables as any)[field.name].length
  return (
    <Fragment key={field.name}>
      <div className="field-group">
        <label title={field.name}>
          {`${upperCaseFirstLetter(field.name)} amount`}
        </label>
        <input
          value={noOfElems} // This cast is safe because we know it is a list field
          onChange={handleListFieldChange(field)}
          type="number"
        />
      </div>

      {[...Array(noOfElems).keys()].map((i) => (
        <div key={i} className="list-element">
          {field.listType.map((listField) => {
            if (isNumberField(listField)) {
              if (variables === undefined) return <Fragment key={listField.name} />

              const listVariables: Dictionary<number | string> = (variables as any)[field.name][i]

              // Curry this to match the NumberFieldComponent setVariable
              const setVariableFunction = (name: string, idx: number) =>
                (type: string, listName: string, value: number) => { setListVariable(name, idx, type, listName, value) }

              return <NumberFieldComponent
                key={listField.name}
                field={listField}
                variables={listVariables}
                setVariable={setVariableFunction(field.name, i)} />

            } else return <Fragment key={listField.name} />
          })}
        </div>
      ))}
    </Fragment>
  )
}


export {
  NumberFieldComponent,
  OffsetFieldComponent,
  SelectFieldComponent,
  ListFieldComponent
}