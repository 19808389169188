import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import './popupmenu.scss'

/** Open a popup window */
const openWindow = (url: string, width: number, height: number = 400) =>
  window.open(
    `/#/${url}`,
    `_blank`,
    `noreferrer, popup, width=${width}, height=${height}, top=100, toolbar=no, location=no, statusbar=no, menubar=no`
  )

const PopupMenu = observer(() => {
  const { configStore } = useStores()
  const { getPortfolio } = configStore
  const openCapacities = () => {
    const url = `capacities/${getPortfolio?.eic}`
    openWindow(url, 440)
  }
  const openOrderBook = () => openWindow('order-book', 546)

  return (
    <div className="popup-menu">
      <button
        title={`Open capacities for ${
          configStore.getPortfolio?.name.split('-')[1]
        }`}
        onClick={openCapacities}
        disabled={getPortfolio == null}
      >
        Capacities
      </button>
      <button title={'Open order book'} onClick={openOrderBook}>
        Order Book
      </button>
      <button title={'Open auctions'} onClick={() => { openWindow('auctions', 700, 600) }}>
        Auctions
      </button>
      <button title={'Open spreads'} onClick={() => { openWindow('spread', 1200, 600) }}>
        Spread
      </button>
    </div>
  )
})

export default PopupMenu
