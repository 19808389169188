import React, {type FC} from "react";
import {type StrategyInvocation} from "../../classes/Order";
import {observer} from "mobx-react";
import {useStores} from "../../use-stores";
import './strategy-filter.scss'
import {Cross} from "../Icons/Icons";
import { id, type Portfolio } from '../../classes/Portfolio'

/** Strategy filtering options */
export interface StrategyFilter {
  portfolio?: Portfolio
  contractId?: string
  tradeHandle?: string
}

/** No filtering */
const defaultFilter: StrategyFilter = {
  portfolio: undefined,
  contractId: undefined,
  tradeHandle: undefined
}

/** Check if any of the values are defined */
const filterIsDefined = (filter: StrategyFilter) => Object.keys(filter)
  .some(k => filter[k as keyof StrategyFilter] !== undefined)

/** Filter strategies by values in filter */
const filterStrategies = (filter: StrategyFilter) => (strategy: StrategyInvocation) => {
  const { invocation, portfolio } = strategy
  const { contractId, tradeHandle} = invocation
  if (filter.portfolio && id(filter.portfolio) !== id(portfolio)) return false
  if (filter.contractId && filter.contractId !== contractId) return false
  return !(filter.tradeHandle && filter.tradeHandle !== tradeHandle);
}

interface StrategyFilterProps {
  strategyFilter: StrategyFilter
  setStrategyFilter: React.Dispatch<React.SetStateAction<StrategyFilter>>
}

/** Menu for setting filtering options. Styling is in portfolioTable.scss */
const StrategyFilterComponent: FC<StrategyFilterProps> = observer((props) => {
  const { strategyFilter, setStrategyFilter } = props
  const { orderStore, configStore } = useStores()

  const { tradeHandles } = orderStore
  const { products, portfolios } = configStore

  // Get value or undefined if string is empty
  const getValue = (e: React.ChangeEvent<HTMLSelectElement>) =>
    e.target.value === '' ? undefined : e.target.value

  const displayClearButton = filterIsDefined(strategyFilter) ? 'unset' : 'hidden'

  return <div className={'filter-component'}>
    <div className='fields'>
      <div className='field'>
        <select
          onChange={(pId) => {
            setStrategyFilter(s => {
              return {...s, portfolio: portfolios.find(p => id(p) === getValue(pId)) }
            })
          }}
          value={strategyFilter.portfolio ? id(strategyFilter.portfolio) : undefined}
          style={{ fontWeight: strategyFilter.portfolio ? 'bold' : '' }}
        >
          <option value={''}>{'No portfolio'}</option>
          {portfolios.map(p => <option key={p.name} value={id(p)}>{p.name}</option>)}
        </select>
      </div>
      <div className='field'>
        <select
          onChange={(p) => {
            setStrategyFilter(s => { return {...s, contractId: getValue(p) } }) } }
          value={strategyFilter.contractId}
          style={{ fontWeight: strategyFilter.contractId ? 'bold' : '' }}
        >
          <option value={''}>{'No contract'}</option>
          {products
            .slice()
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(p => <option key={p.contractId} value={p.contractId}>{p.name}</option> )}
        </select>
      </div>
      <div className='field'>
        <select
          onChange={(p) => {
            setStrategyFilter(s => { return {...s, tradeHandle: getValue(p) } }) } }
          value={strategyFilter.tradeHandle}
          style={{ fontWeight: strategyFilter.tradeHandle ? 'bold' : '' }}
        >
          <option value={''}>{'No handle'}</option>
          {[...tradeHandles].map(h => <option key={h}>{h}</option>)}
        </select>
      </div>
    </div>
    <div
      className='clear-button clickable'
      title={'Clear filters'}
      style={{ visibility: displayClearButton, cursor: 'pointer' }}
      onClick={() => { setStrategyFilter(() => defaultFilter) }}
    >
      <Cross />
      {'Clear'}
    </div>
  </div>
})

export default StrategyFilterComponent
export {
  defaultFilter,
  filterStrategies,
  filterIsDefined
}