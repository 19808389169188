import { type AuctionStatus, type BaseAuction } from '../../../classes/Auction'
import { type CSSProperties, Fragment } from 'react'
import { Checkmark } from '../../../components/Icons/Icons'

interface SideBarElementProps<T extends BaseAuction> {
  title: string
  auctions: T[]
  auction: T | undefined
  setAuction: (a: T | undefined) => void
  sorting: (a: T, b: T) => number
}

// Get the style to show the status of the auction-portfolio
export const getStyle = (isPicked: boolean, a: BaseAuction) => {
  const style: CSSProperties = {}

  // If this auction is picked, we highlight the background
  if (isPicked) {
    style.backgroundColor = 'var(--highlight)'
  }

  // If status is 'None', we grey out the auction name
  if (a.status === 'NONE') {
    style.color = 'var(--text-secondary)'
  }

  // If we
  if (a.status === 'PENDING' || a.status === 'UPDATE_AVAILABLE') {
    style.fontStyle = 'italic'
  }

  // Add bold
  if (a.status === 'PENDING') {
    style.fontWeight = 'bold'
  }
  return style
}

// Get status symbol showing the auction-portfolio status
export const getStatusSymbol = (status?: AuctionStatus) => {
  // Default is no symbol. If submitted, a green checkmark. If update is available, we mark with yellow.
  if (status === 'SUBMITTED')
    return <Checkmark />

  if (status === 'UPDATE_AVAILABLE')
    return <Checkmark stroke={'rgb(204,204,57)'} />

  return <div className='icon-placeholder' />
}


// This is how we mark the different states in the UI.
// - None -> Greyed out
// - Pending -> Italic
// - Submitted -> Green checkmark
// - UpdateAvailable -> Yellow checkmark
export const SideBarElement = <T extends BaseAuction>(
  { title, auctions, auction, setAuction, sorting }: SideBarElementProps<T>,
) => {
  // If we have no auctions, we return no element.
  if (auctions.length === 0) return null

  return (<Fragment>
    <div className='title'>{title}</div>
    <ul className='auctions-bar'>
      {auctions.sort(sorting).map(a => {
        const isPicked = auction?.key() === a.key()
        const style: CSSProperties = getStyle(isPicked, a)

        const onClick = isPicked ? () => {
          setAuction(undefined)
        } : () => {
          setAuction(a)
        }

        // Default is no symbol. If submitted, a green checkmark. If update is available, we mark with yellow.
        const statusSymbol = getStatusSymbol(a.status)

        const className = isPicked ? 'auction-status' : 'auction-status clickable'

        return (
          <li
            className={className}
            key={a.key()}
            onClick={onClick}
            style={style}
          >
            <div className='auction-status-inner'>
              <div className='auction-status-name'>
                {a.uiName()}
              </div>
              {statusSymbol}
            </div>
          </li>
        )
      })}
    </ul>
  </Fragment>)
}