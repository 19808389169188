import { type BaseAuction } from '../../../classes/Auction'
import React, { type CSSProperties, type FC } from 'react'
import { getStatusSymbol, getStyle } from './SideBarElement'

interface AuctionListElementProps {
  // The picked auction
  auction?: BaseAuction
  // The element we render
  element: BaseAuction,
  onClick: (a: BaseAuction) => void
  // If used in tree view
  offset?: boolean
}

export const AuctionListElement: FC<AuctionListElementProps> = ({ auction, element, onClick, offset }) => {
  const isPicked = auction?.key() === element.key()
  const style: CSSProperties = getStyle(isPicked, element)

  // Default is no symbol. If submitted, a green checkmark. If update is available, we mark with yellow.
  const statusSymbol = getStatusSymbol(element.status)

  const className = isPicked ? 'auction-status' : 'auction-status clickable'

  return (
    <li
      className={className}
      key={element.key()}
      onClick={() => {
        onClick(element)
      }}
      style={style}
    >
      <div className='auction-status-inner'>
        <div className='auction-status-name'>
          {offset && <span style={{ whiteSpace: 'pre' }}>{'   '}</span>}
          {element.uiName()}
        </div>
        {statusSymbol}
      </div>
    </li>
  )
}