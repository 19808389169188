import React, { type FunctionComponent, type MouseEvent, useEffect, useMemo, useState } from 'react'
import './orders.scss'
import { type OrderUpdateEvent, type StrategyInvocation } from '../../classes/Order'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import axiosInstance from '../../axiosInstance'
import { lowerCaseAllButFirst, splitPascalCase } from '../Executor/Executor'
import { toast } from 'react-toastify'
import Tooltip from '../Tooltip/Tooltip'
import { Cross, Funnel, ICON_SIZE, Menu } from '../Icons/Icons'
import StrategyFilterComponent, {
  defaultFilter,
  filterIsDefined,
  filterStrategies,
  type StrategyFilter,
} from '../PortfolioTable/StrategyFilter'
import { id } from '../../classes/Portfolio'
import EpexMenu from '../EpexMenu/EpexMenu'
import { getOrders, getTrades } from '../../actions/Orders'
import NordPoolMenu from '../NordPoolMenu/NordPoolMenu'
import useNotifications from '../../hooks/useNotifications'
import { useSubscribe } from '../../hooks/useSubscribe'

// The number of strategies to show at a time
const PAGE = 50

// Compare by time, then by portfolio, then by contract. Just to keep consistent sorting
const sortStrategies = (a: StrategyInvocation, b: StrategyInvocation) => {
  if (a.invocationTime < b.invocationTime) return 1
  if (a.invocationTime > b.invocationTime) return -1

  if (id(a.portfolio) > id(b.portfolio)) return 1
  if (id(a.portfolio) < id(b.portfolio)) return -1

  return a.invocation.contractId > b.invocation.contractId ? 1 : -1
}

export const fetchStrategies = async () =>
  await axiosInstance.get<StrategyInvocation[]>('api/strategies')

const cancel = async (strategy: string) =>
  await axiosInstance
    .put(`api/strategies/${strategy}/cancel`)
    .catch(() => toast.error('Could not cancel strategy invocation'))

const cancelAll = async () =>
  await axiosInstance
    .post('api/strategies/cancel')
    .catch(() => toast.error('Could not cancel strategies'))

// Returns true when strategy invocation can be cancelled
export const canBeCancelled = (invocation: StrategyInvocation) =>
  invocation.state === 'ACTIVE' || invocation.state === 'WAITING'

// Get set of labels that have active
const labelHasActive = (invocations: StrategyInvocation[]) =>
  invocations
    .filter(canBeCancelled)
    .map((inv) => inv.invocation.tradeHandle)
    .filter((value, index, self) => self.indexOf(value) === index)

const cancelAllByLabel = async (
  label: string,
  invocations: StrategyInvocation[]
) => {
  const toCancel = invocations
    .filter(canBeCancelled)
    .filter((inv) => inv.invocation.tradeHandle === label)

  for (const inv of toCancel) {
    await cancel(inv.id)
  }
  await new Promise(() => {})
}

const Orders = observer(() => {
  const { orderStore, configStore, uiStore } = useStores()
  const { products, getPortfolio } = configStore
  const { strategies, strategyToOrders, strategyToTrades } = orderStore
  const [showSeparate, setShowSeparate] = useState(false) // Show separate cancellable labels
  const [mouse, setMouse] = useState<MouseEvent | undefined>(undefined)
  const [strategy, setStrategy] = useState<StrategyInvocation | undefined>(
    undefined
  )
  const [pageIndex, setPageIndex] = useState(PAGE)

  // State for filtering table
  const [showFilter, setShowFilter] = useState(false)
  const [strategyFilter, setStrategyFilter] = useState<StrategyFilter>(defaultFilter)
  const handleNotification = useNotifications()

  useEffect(() => {
    fetchStrategies()
      .then((resp) => {
        resp.data.forEach((invocation) => {
          orderStore.addStrategyInvocation(invocation)
        })
      })
      .catch(() => toast.error('Could not fetch current running strategies'))

    getTrades()
      .then(trades => {
        trades.forEach(trade => {
          orderStore.updateTrades(trade)
        })
      })

      .catch(() => toast.error('Could not fetch trades'))

    getOrders()
      .then(orders => {
        orders.forEach(order => {
          orderStore.updateOrders(order)
        })
      })
      .catch(() => toast.error('Could not fetch orders'))
  }, [])

  useSubscribe('/topic/trade', orderStore.updateTrades)
  useSubscribe('/topic/order-updates', orderStore.updateOrders)
  useSubscribe('/topic/strategies', orderStore.addStrategyInvocation)
  useSubscribe('/topic/notifications', handleNotification)

  const formatTime = (timeString: string) =>
    new Date(timeString).toLocaleTimeString('en-GB')

  // Change page on scroll to top or bottom
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget

    // Close filtering menu if we are scrolling. Looks dinky.
    if (scrollTop > 0) setShowFilter(() => false)
    if (scrollTop < 1) setPageIndex(PAGE)
    if (scrollTop + clientHeight >= scrollHeight)
      setPageIndex((val) => Math.min(val + PAGE, strategies.length))
  }

  const visibleStrategies = useMemo(() => strategies
      .slice()
      .filter(filterStrategies(strategyFilter))
      .sort(sortStrategies)
      .slice(0, pageIndex)
    , [strategies, strategyFilter, pageIndex])

  if (strategies.length === 0) {
    return <div className="orders">Nothing here...</div>
  }

  return (
    <div className="orders" onScroll={onScroll}>
      <Tooltip event={mouse} strategy={strategy} />
      {getPortfolio?.exchange === 'EPEX' ? <EpexMenu portfolio={getPortfolio} /> : <NordPoolMenu />}
      <div className="controls">
        <div className={'left-controls'}>
          <div
            className={'filtering'}
            title={'Filter strategies'}
          >
            <div
              onClick={() => { setShowFilter(prev => !prev) }}
              style={{display: 'flex'}}
            >
              <Funnel fill={filterIsDefined(strategyFilter) ? 'var(--text-primary)' : 'none'}/>
            </div>
          </div>
          <div className="cancel-separate" title={'Show active trade labels'}>
            <p
              onClick={() => {
                setShowSeparate((val) => !val)
              }}
            >
              <Menu />
            </p>
            {showSeparate &&
              labelHasActive(strategies).map((l) => (
                <p
                  style={{lineHeight: `${ICON_SIZE}px`}}
                  key={l}
                  title={`Cancel ${l} invocations`}
                  onClick={async () => {
                    await cancelAllByLabel(l, strategies)
                  }}
                >
                  {l}
                  <span><Cross /></span>
                </p>
              ))}
          </div>
        </div>
        <div
          className="cancel-all"
          onClick={async () => await cancelAll()}
          title={'Kill!'}
        >
          <p className="text">Cancel all</p>
          <Cross />
        </div>
      </div>
      {showFilter && (
        <StrategyFilterComponent
          key={`${strategyFilter.contractId}-${strategyFilter.portfolio ? id(strategyFilter.portfolio) : ''}-${strategyFilter.tradeHandle}`}
          strategyFilter={strategyFilter}
          setStrategyFilter={setStrategyFilter}
        />
      )}
      <div className="ongoing">
        {visibleStrategies.map((strategy) => {
          const { invocation, state, portfolio } = strategy
          const { contractId, tradeHandle} = invocation

          // Get actual portfolio and contract
          const contract = products.find((p) => p.contractId === contractId)

          return (
            <div key={strategy.id} className="order">
              <div
                className="order-info"
                onMouseEnter={(event: MouseEvent) => {
                  setMouse(event)
                  setStrategy(strategy)
                }}
                onMouseMove={(event: MouseEvent) => {
                  setMouse(event)
                }}
                onMouseLeave={() => {
                  setMouse(undefined)
                  setStrategy(undefined)
                }}
              >
                <p className="type">
                  {lowerCaseAllButFirst(splitPascalCase(strategy.type).join(' '))}
                </p>
                <p style={{userSelect: 'none'}}>
                  {strategy.portfolio.exchange.replace('_', ' ')}
                </p>
                <p
                  onDoubleClick={() => {
                    setStrategyFilter(s => { return {...s, portfolio } })
                  }}
                  style={{userSelect: 'none'}}
                >
                  {portfolio?.name}
                </p>
                <p
                  onDoubleClick={() => {
                    setStrategyFilter(s => { return {...s, contractId } })
                  }}
                  style={{userSelect: 'none'}}
                >
                  {contract?.name ?? strategyToOrders[strategy.id]?.[0]?.product}
                </p>
                <div className="time-handle-info">
                  <p>{state}</p>
                  <p
                    onDoubleClick={() => {
                      setStrategyFilter(s => { return {...s, tradeHandle } })
                    }}
                    style={{userSelect: 'none'}}
                  >{tradeHandle}</p>
                  <p className="time" title={strategy.invocationTime}>
                    {formatTime(strategy.invocationTime)}
                  </p>
                  {canBeCancelled(strategy) && (
                    <div
                      className="cancel-button"
                      onClick={async () => await cancel(strategy.id)}
                      title={'Cancel'}
                    >
                      &#x2715;
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`order-trades ${uiStore.showTrades ? '' : 'hidden'}`}
              >
                {strategyToOrders[strategy.id] !== undefined &&
                  strategyToOrders[strategy.id].map((o) => (
                    <OrderComponent key={o.orderId} order={o} />
                  ))}
                {strategyToTrades[strategy.id] !== undefined &&
                  strategyToTrades[strategy.id].map((t) => (
                    <OrderComponent key={t.tradeNo} order={t} />
                  ))}
              </div>
            </div>
        )}
        )}
      </div>
    </div>
  )
})

interface TradeProps {
  order: OrderUpdateEvent
}

const OrderComponent: FunctionComponent<TradeProps> = ({ order }) => {
  const format = (divisor: number, unit: string, num?: number) =>
    num ? `${num / divisor} ${unit}` : undefined
  const formatTime = (time?: string) =>
    time ? new Date(time).toLocaleTimeString('en-GB') : undefined
  return (
    <div className={'order'}>
      <p>{order.direction}</p>
      <p>{order.state}</p>
      <p>{format(100, 'EUR', order.price)}</p>
      <p>{format(1000, 'MW', order.quantity)}</p>
      <p title={order.tradeTime}>{formatTime(order.tradeTime)}</p>
    </div>
  )
}

export default Orders
