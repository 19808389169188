import React, { type FC, Fragment, useState } from 'react'
import { lowerCaseAllButFirst, splitPascalCase, type Variables } from './Executor'
import { isListField, isNumberField, isSelectField, type ListField, type StrategySchema } from '../../classes/StrategySchema'
import { ListFieldComponent, NumberFieldComponent, OffsetFieldComponent, SelectFieldComponent } from './Fields'
import BuySellButton from '../BuySellButtons/BuySellButton'
import { useStores } from '../../use-stores'
import { observer } from 'mobx-react'
import { hasError, setSelect, setVariable, validateBase } from './ExecutorHelpers'
import { type Dictionary, type StrategyInvocationDTO } from '../../classes/Order'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import useKeyDownListener from '../../hooks/useKeyDownListener'

interface ExecutorProps {
  schema: StrategySchema
  defaultVariables: Variables | undefined
  handleListFieldChange: (field: ListField) => (e: React.ChangeEvent<HTMLInputElement>) => void
  setListVariable: (name: string, idx: number, type: string, listName: string, value: number) => void
  setSchema: (s: StrategySchema | undefined) => void
}

/**
 * Validate that we have filled out the dictionary with values
 * @param schema  The correct dictionary
 * @param invocation The dictionary that we will post
 */
const validateVariables = (
  schema: StrategySchema | undefined,
  invocation: Dictionary<any> | undefined
) => {
  if (schema === undefined || invocation === undefined) return false
  if (schema.fields.some(f => hasError(f.name, invocation[f.name]))) return false
  return schema.fields.every((field) => invocation[field.name] !== undefined)
}


const SingleExecutor: FC<ExecutorProps> = observer((
  {
    schema,
    defaultVariables,
    handleListFieldChange,
    setListVariable,
    setSchema
  }) => {

  const [buy, setBuy] = useState(false)
  const [variables, setVariables] = useState(defaultVariables ?? {} as Variables)

  const { configStore, socketStore } = useStores()
  const { getPortfolio, getProduct, handle } = configStore

  const description = getPortfolio != null && getProduct != null
      ? `For ${getProduct.name} in ${getPortfolio.name}`
      : 'No product picked'

  const submitEnabled =
    validateBase(getPortfolio, getProduct, handle) &&
    validateVariables(schema, variables) &&
    socketStore.connected

  // We submit strategy on 'Enter'
  useKeyDownListener('Enter', () => {
    if (submitEnabled) submitStrategyInvocation()
  }, [schema])

  const submitStrategyInvocation = () => {
    // Need to check this for typescript
    if (getPortfolio == null || getProduct == null || variables == null) return

    // DTO to send
    const invocation: StrategyInvocationDTO = {
      exchange: getPortfolio.exchange,
      contractId: getProduct.contractId,
      areaId: getPortfolio.eic,
      buy,
      tradeHandle: handle,
      variables,
    }

    // Post strategy invocation
    axiosInstance
      .post('api/strategies', invocation)
      .then(() => {
        // If it was good response, clear state
        // Clear products and schema
        configStore.clearPickedProducts()
        setSchema(undefined)
      })
      .catch((err) => {
        const error = err.response.data.message // If it is a custom error from backend, find this message
        if (error) toast.error(error)
        else toast.error(err.message)
      })
  }


  return (
    <Fragment>
    <div className="heading">
      <p className="strategy-name">
        {lowerCaseAllButFirst(splitPascalCase(schema.name).join(' '))}
      </p>
      <span> </span>
      <p>{description}</p>
    </div>
      <div>
        <div className="form">
          {schema.fields.map((field) => {
            if (isNumberField(field)) {
              return <NumberFieldComponent
                key={field.name}
                field={field}
                variables={variables}
                setVariable={setVariable(setVariables)} />
            }
            else if (isListField(field)) {
              return <ListFieldComponent
                key={field.name}
                field={field}
                variables={variables}
                handleListFieldChange={handleListFieldChange}
                setListVariable={setListVariable} />
            }
            else if (isSelectField(field)) {
              return <SelectFieldComponent
                key={field.name}
                field={field}
                setSelect={setSelect(setVariables)} />
            }
            else {
              return <OffsetFieldComponent
                key={field.name}
                field={field}
                variables={variables}
                setVariable={setVariable(setVariables)} />
            }
          })}
          <div className="field-group">
            <BuySellButton buy={buy} f={setBuy} />
            <button
              disabled={!submitEnabled}
              className="place-order-button"
              onClick={submitStrategyInvocation}
            >
              {`Place ${buy ? 'buy' : 'sell'}`}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
})

export default SingleExecutor

