import type { IOrderBook } from '../../../classes/OrderBook'
import { Multiplier } from '../../../Util'
import { difference } from '../calculationHelpers'
import type { TakeTrade } from './OrderBookCell'

/**
 * Calculates the cost of getting volume from order book.
 *
 * Returns undefined if position is not available (not enough volume in the order book)
 * @param orderBook the order book to look for volume in
 * @param volume the volume we want to trade
 */
export const calculateCost = (orderBook: IOrderBook, volume: number): TakeTrade | undefined => {
  const orders = volume > 0 ? orderBook.sells : orderBook.buys
  const multiplier = Multiplier.MW * Multiplier.EUR * (volume > 0 ? -1 : 1)

  let missing = Math.abs(volume)
  let cost = 0
  for (const order of orders) {
    if (order.qty === undefined) continue

    if (order.qty >= missing) {
      return {
        cost: (cost + missing * order.price) / multiplier,
        pricePoint: order.price,
        volume,
      }
    }

    cost += order.qty * order.price
    missing -= order.qty
  }

  // If we reach this, it is because our volume is bigger than the order book depth
  return undefined
}

/**
 * Get the volume of the position we should take.
 *
 * Is the absolute differene between our current position and the new position we want.
 * @param currentPosition our current position for this order book
 * @param fullPosition the new position we want
 */
export const volumeToTrade = (currentPosition: number, fullPosition: number) => {
  const sign = Math.sign(fullPosition)
  return sign * difference(currentPosition, fullPosition)
}