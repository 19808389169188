import { toast } from 'react-toastify'
import { HCell, Row } from '../Table/Table'
import { useStores } from '../../use-stores'
import { type FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useSubscribe } from '../../hooks/useSubscribe'
import { type Imbalance } from '../../classes/Imbalance'

interface OrderBookHeaderProps {
  quantities: number[]
  setQuantities: (reducer: (prev: number[]) => number[]) => void
  contractId: string
  areaEic: string
}

const OrderBookHeader: FC<OrderBookHeaderProps> = observer(({ quantities, setQuantities, contractId, areaEic }) => {
  const { orderBookStore, configStore } = useStores()
  const { directOrdersEnabled, orderBookQtyLimit, imbalanceTrackers, defaultQuantities } = orderBookStore
  const { handle } = configStore
  const [headerValues, setHeaderValues] = useState<string[]>([])
  const [imbalance, setImbalance] = useState<number>(0)

  useSubscribe(`/topic/imbalances/${areaEic}/${contractId}/${handle}`, (imb: Imbalance) => {
    setImbalance(imb.imbalance)
  }, [areaEic, contractId, handle])

  function handleQuantityChange(idx: number) {
    function aux(event: React.ChangeEvent<HTMLInputElement>) {
      const qty = event.target.value.trim().replace(/^0*/g, '')

      setHeaderValues((prev: string[]) => {
        const newHeaderValues = prev.slice()
        newHeaderValues[idx] = qty
        return newHeaderValues
      })

      const _qtyFormatted = qty.replace(',', '.')
      const qtyFormatted = _qtyFormatted[_qtyFormatted.length - 1] === '.' ? _qtyFormatted.slice(0, -1) : _qtyFormatted

      const qtyNum = qtyFormatted === '' ? 0 : parseFloat(qtyFormatted)
      const quantityLimit = orderBookQtyLimit

      if (isNaN(qtyNum)) {
        toast.error('Quantity must be a number')
        return
      }
      if (qtyNum < 0 || qtyNum > quantityLimit / 1000) {
        toast.error(`Quantity must be between 0 and ${quantityLimit / 1000}`)
        return
      }
      if ((qtyNum * 10) % 1 !== 0) {
        toast.error('Quantity can only have one decimal place')
        return
      }

      setQuantities((prev) => {
        const newQuantities = prev.slice()
        newQuantities[idx] = qtyNum * 1000
        return newQuantities
      })
    }
    return aux
  }

  useEffect(() => {
    setQuantities((prev) => {
      const newQuantities = prev.slice()
      const buys = [0, 1, 2, 7]

      imbalanceTrackers.forEach((tracker, i) => {
        if (!tracker) return
        const imbalanceUnsigned = buys.includes(i) ? imbalance : -imbalance
        const newImbalance = Math.max(0, imbalanceUnsigned)

        newQuantities[i] = newImbalance !== 0 ? newImbalance : defaultQuantities[i]
      })

      return newQuantities
    })
  }, [imbalance, imbalanceTrackers, defaultQuantities])

  useEffect(() => {
    const newHeaderValues = quantities.map((qty) => qty / 1000 + '')

    setHeaderValues((prev) => {
      return newHeaderValues.map((newHeaderValue, i) => {
        const oldHeaderValue = prev[i]?.replace(',', '.')
        if (oldHeaderValue === undefined) return newHeaderValue

        const oldHeaderValueFormatted =
          oldHeaderValue[oldHeaderValue.length - 1] === '.' ? oldHeaderValue.slice(0, -1) : oldHeaderValue
        const oldHeaderValueNum = oldHeaderValueFormatted === '' ? 0 : parseFloat(oldHeaderValueFormatted)

        const newHeaderValueNum = parseFloat(newHeaderValue)

        if (newHeaderValueNum === oldHeaderValueNum) return oldHeaderValue

        return newHeaderValue
      })
    })
  }, [quantities])

  return (
    <div className="header">
      <Row>
        {!directOrdersEnabled ? (
          <></>
        ) : (
          <HCell>
            <input
              value={headerValues[6] || ''}
              onChange={handleQuantityChange(6)}
              onClick={(e) => {
                e.currentTarget.select()
              }}
            />
          </HCell>
        )}
        <HCell>
          <input
            value={headerValues[0] || ''}
            onChange={handleQuantityChange(0)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        <HCell>
          <input
            value={headerValues[1] || ''}
            onChange={handleQuantityChange(1)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        <HCell>
          <input
            value={headerValues[2] || ''}
            onChange={handleQuantityChange(2)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        <HCell className="non-button-cell">Qty</HCell>
        <HCell className="non-button-cell">Price</HCell>
        <HCell className="non-button-cell">Price</HCell>
        <HCell className="non-button-cell">Qty</HCell>
        <HCell>
          <input
            value={headerValues[3] || ''}
            onChange={handleQuantityChange(3)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        <HCell>
          <input
            value={headerValues[4] || ''}
            onChange={handleQuantityChange(4)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        <HCell>
          <input
            value={headerValues[5] || ''}
            onChange={handleQuantityChange(5)}
            onClick={(e) => {
              e.currentTarget.select()
            }}
          />
        </HCell>
        {!directOrdersEnabled ? (
          <></>
        ) : (
          <HCell>
            <input
              value={headerValues[7] || ''}
              onChange={handleQuantityChange(7)}
              onClick={(e) => {
                e.currentTarget.select()
              }}
            />
          </HCell>
        )}
      </Row>
    </div>
  )
})

export default OrderBookHeader
