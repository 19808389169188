import { type AllocationBid, type BidValue } from '../../../classes/OrderPlan'
import { makeAutoObservable } from 'mobx'
import { order } from '../AuctionMenu/AuctionMenu'
import { toast } from 'react-toastify'
import type { AuctionOfferedCapacity } from '../../../classes/AuctionOfferedCapacity'
import { Multiplier } from '../../../Util'

export type TableDict = Record<string, Record<string, BidValue>>

// Sorting for allocation bids
const bidSorting = (a: AllocationBid, b: AllocationBid) => {
  if (a.status === b.status) {
    return -1 * a.createdTime.localeCompare(b.createdTime)
  }
  return order.indexOf(a.status) - order.indexOf(b.status)
}

// Group by function, that takes the top per grouping category. Only used for allocations, so is here
const groupByAndTakeTop = <T, U extends string | number | symbol>(list: T[], grouping: (t: T) => U, sorting: (a: T, b: T) => number) => {
  const grouped = new Map<U, T[]>()
  for (const value of list) {
    const key = grouping(value)
    const lst = grouped.get(key) ?? []
    lst.push(value)
    grouped.set(key, lst)
  }

  return [...grouped.values()].map(lst => lst.sort(sorting)[0])
}

// Create a dictionary (direction -> (time -> BidValue))
const createTableDictionary = (bids: AllocationBid[]): TableDict => {
  // Create dictionary direction -> (time -> BidValue)
  const tableDictionary: Record<string, Record<string, BidValue>> = {}

  for (const column of bids) {
    const directionDict: Record<string, BidValue> = {}
    for (const value of column.bidValues) {
      directionDict[value.deliveryStart] = value
    }
    tableDictionary[column.name] = directionDict
  }
  return tableDictionary
}

// Class for sharing bid state between AllocationTable and AllocationMenu
export class AllocationAuctionStore {
  private bids: AllocationBid[] = []
  private capacities: AuctionOfferedCapacity[] | undefined = undefined
  modification: TableDict = {}
  modifyEnabled: boolean = true

  constructor() {
    makeAutoObservable(this)
  }

  setModifyEnabled(value: boolean) {
    this.modifyEnabled = value
  }

  // Get the top bid to show per direction.
  // Meaning, we want to see the newest (PENDING) or the latest SUBMITTED. Are returned sorting by direction
  get actualBids() {
    return groupByAndTakeTop(this.bids, b => b.name, bidSorting)
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  get columns() {
    return this.actualBids.map(b => b.name).sort((a, b) => a.localeCompare(b))
  }

  get rows() {
    const deliveryStartsSet = new Set<string>()
    for (const bid of this.actualBids) {
      for (const value of bid.bidValues) {
        deliveryStartsSet.add(value.deliveryStart)
      }
    }
    return [...deliveryStartsSet].sort((a, b) => a.localeCompare(b))
  }

  // Get offered capacities for auction as kW
  get offeredCapacities() {
    if (!this.capacities) return undefined

    const capacityMap = new Map<string, Map<string, number>>()

    for (const offeredCapacity of this.capacities) {
      const directionMap = new Map<string, number>()
      for (const value of offeredCapacity.offeredCapacity) {
        directionMap.set(value.deliveryStart, value.capacity)
      }
      capacityMap.set(offeredCapacity.direction, directionMap)
    }

    return capacityMap
  }

  // Get summed capacity per direction as MW
  get summedOfferedCapacity() {
    if (!this.capacities) return undefined

    const capacityMap = new Map<string, number>()

    for (const offeredCapacity of this.capacities) {
      let sum = 0
      for (const value of offeredCapacity.offeredCapacity) {
        console.log(sum, value.capacity)
        sum += value.capacity
      }
      capacityMap.set(offeredCapacity.direction, sum / Multiplier.MW)
    }

    return capacityMap
  }

  // Add or replace a single AllocationBid
  addBid = (bid: AllocationBid) => {
    const prev = [...this.bids]
    this.bids = [...prev.filter(b => b.bidId !== bid.bidId), bid]
  }

  // Add or replace several allocation bids. doNotNotify, means this function should not notify about changes
  addBids = (bids: AllocationBid[], doNotNotify: boolean = false) => {
    if (bids.length === 0) return
    const prev = [...this.bids]
    const newIds = bids.map(b => b.bidId)
    const newBids = [...prev.filter(b => !newIds.includes(b.bidId)), ...bids]

    // If the being modified is not empty, it means we are modifying a bid right now.
    // Notify user if new bid has been posted
    const isBeingModified = Object.keys(this.modifiedBids).length !== 0
    if (!doNotNotify && isBeingModified && newBids.length !== prev.length) {
      toast.info('New bid posted while modifying.', { autoClose: false, toastId: `${this.columns.join('-')}` })
    }

    this.bids = newBids

    // Only reset if we were not modifying
    if (!isBeingModified) {
      this.resetModifications()
    }
  }

  // Always returns the current actual bids as a table
  get tableDictionary() {
    return createTableDictionary(this.actualBids)
  }

  setOfferedCapacities(capacities: AuctionOfferedCapacity[] | undefined) {
    this.capacities = capacities
  }

  modifyCell = (direction: string, row: string, key: keyof Omit<BidValue, 'deliveryStart'>, value: number) => {
    const prev = { ...this.modification }
    const current = prev[direction][row]
    const modified = { ...current }
    modified[key] = value
    prev[direction][row] = modified
    this.modification = { ...prev }
  }

  // Discard current modifications
  resetModifications = () => {
    this.modification = this.tableDictionary
  }

  // Reset modification for specific direction only
  resetModification = (direction: string) => {
    const table = { ...this.tableDictionary }
    const modified = { ...this.modification }
    modified[direction] = table[direction]
    this.modification = { ...modified }
  }

  // Get bids that are currently modified compared to the actual bids
  get modifiedBids() {
    const modifiedBids: typeof this.modification = {}

    // For every bid visible
    for (const bid of this.actualBids) {
      const { name } = bid
      for (const row of this.rows) {
        const value = this.tableDictionary[name][row]
        const modified = this.modification[name][row]
        if (value.quantity !== modified.quantity || value.price !== modified.price) {
          modifiedBids[name] = this.modification[name]
          break
        }
      }
    }
    return modifiedBids
  }

  // Clear the list of bids in the store and the offered capacity values
  clear = () => {
    this.bids = []
    this.capacities = undefined
  }
}