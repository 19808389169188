// Post modified allocation bid(s) to backend
import { type AllocationAuctionStore } from './AllocationAuctionStore'
import { type AllocationBid, type OrderPlanStatus } from '../../../classes/OrderPlan'
import { changeBidStatus, postAllocationBid } from '../../../actions/Auctions'
import { settled } from './Settled'
import { toast } from 'react-toastify'
import { type AllocationAuction } from '../../../classes/Auction'
import type { AxiosError } from 'axios'

// Get the bidding direction from an AxiosError
const getDirection = (error:  AxiosError<AllocationBid, any>) => {
  const { config } = error
  return config?.data ? JSON.parse(config.data).direction : 'Unknown direction'
}

export const saveChanges = (store: AllocationAuctionStore, auction: AllocationAuction) => (done: () => void) => {
  const { deliveryDate } = auction
  const promises = Object
    .entries(store.modifiedBids)
    .map(async ([dir, bids]) => await postAllocationBid(dir, deliveryDate, Object.values(bids)))

  Promise.allSettled(promises)
    .then(values => {
      // Process fulfilled and rejected promises
      settled(values)
        .fulfilled(bids => { store.addBids(bids, true) })
        .rejected(errs => {
          if (errs.length === 0) toast.success('All bids saved')
          else errs.forEach(err => toast.error(`Could not save: ${getDirection(err)}`))
        })
    })
    .finally(() => done())
}

// Change status of plans to either 'SUBMITTED' or 'DISCARDED'
export const changeStatus = (store: AllocationAuctionStore, status: Exclude<OrderPlanStatus, 'PENDING' | 'REPLACED'>) => (done: () => void) => {
  const promises = store.actualBids
    .filter(bid => bid.status === 'PENDING')
    .map(async (bid) => await changeBidStatus(bid, status))

  Promise.allSettled(promises)
    .then(values => {
      // Process fulfilled and rejected promises
      settled(values)
        .fulfilled(bids => { store.addBids(bids) })
        .rejected(errs => {
          if (errs.length === 0) toast.success(`All bids ${status === 'SUBMITTED' ? 'submitted' : 'discarded'} ` )
          else errs.forEach(err => {
            const message = err.response?.data
            toast.error(message || getDirection(err))
          })
        })
    })
    .finally(() => done())
}