import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { Relation } from '../../pages/OrderBook/OrderBookPage'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import { type Imbalance, ResetRequest } from '../../classes/Imbalance'
import { formatPrice } from './OrderBookTable'
import { IoMdSettings } from 'react-icons/io'
import { useSubscribe } from '../../hooks/useSubscribe'
import { useState } from 'react'

const OrderBookTopBar = observer(
  ({
    areaEic,
    contractId,
    swap,
    orderBookName,
    cancelAll,
    isFirst,
    changeProduct,
    close,
  }: {
    areaEic: string
    contractId: string
    swap: (relation: Relation) => void
    orderBookName: string
    cancelAll: () => void
    isFirst: boolean
    changeProduct: (next: boolean) => void
    close: () => void
  }) => {
    const { configStore, orderBookStore, uiStore } = useStores()

    const { handle } = configStore
    const { profitEnabled } = orderBookStore
    const [imbalanceRecord, setImbalanceRecord] = useState<Imbalance | undefined>(undefined)

    useSubscribe(`/topic/imbalances/${areaEic}/${contractId}/${handle}`, (imb: Imbalance) => {
      setImbalanceRecord(imb)
    }, [areaEic, contractId, handle])

    const imbalance = imbalanceRecord?.imbalance ?? 0
    const profit = imbalanceRecord?.profit

    function resetImbalance() {
      const resetBody = new ResetRequest(contractId, areaEic, handle)

      axiosInstance.put(`api/imbalances/reset`, resetBody).catch(() => {
        toast.error('Failed to reset imbalance')
      })
    }

    return (
      <div className="title">
        <div className="left">
          <div
            className="btn"
            onClick={() => {
              swap(Relation.ABOVE)
            }}
          >
            ↑
          </div>
          <div
            className="btn"
            onClick={() => {
              swap(Relation.BELOW)
            }}
          >
            ↓
          </div>
          {imbalance !== 0 && (
            <div
              className="btn"
              title="Reset imbalance"
              onClick={() => {
                resetImbalance()
              }}
            >
              ⟲
            </div>
          )}
          {profitEnabled && profit != null && (
            <div
              className={`btn profit ${profit < 0 ? 'loss' : ''}`}
              title="Profit of imbalance"
            >
              {formatPrice(profit)}
            </div>
          )}
        </div>
        <div className="center">
          <div
            onClick={() => {
              changeProduct(false)
            }}
            className="arrow"
          >
            ‹
          </div>
          <div className='title'>{orderBookName}</div>
          <div
            onClick={() => {
              changeProduct(true)
            }}
            className="arrow"
          >
            ›
          </div>
        </div>
        <div className="right">
          <div className="btn" onClick={close}>
            ✕
          </div>
          <div className="btn" onClick={cancelAll}>
            Cancel All
          </div>
          {isFirst && (
            <IoMdSettings
              onClick={uiStore.toggleSettings}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </div>
    )
  }
)

export default OrderBookTopBar
