import { useEffect } from 'react'
import './App.scss'
import { observer } from 'mobx-react'
import { useStores } from './use-stores'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { getToken } from './authConfig'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import Root from './pages/Root/Root'
import OrderBookPage from './pages/OrderBook/OrderBookPage'
import useKeyDownListener from './hooks/useKeyDownListener'
import CapacityPage from './pages/Capacities/CapacityPage'
import Auctions from './pages/Auctions/Auctions'
import { getAuthority } from './actions/User'
import SpreadHelper from './pages/SpreadHelper/SpreadHelper'

const App = observer(() => {
  const { uiStore, configStore, socketStore, executorStore, userStore } = useStores()
  const { theme } = uiStore
  const { setToken } = socketStore
  const { setAuthority } = userStore

  useMsalAuthentication(InteractionType.Redirect)
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      getToken().then(token => { setToken(token) })
      getAuthority().then(auth => { setAuthority(auth) })
    }
  }, [isAuthenticated])

  useKeyDownListener(['Escape', 'KeyM'], (event: KeyboardEvent) => {
    // Remove table focus on escape
    if (event.code === 'Escape') {
      configStore.clearPickedProducts()
      executorStore.setSchema(undefined)
    }
    // Drop chosen schema and variables on 'Escape' keydown event
    if (event.altKey && event.code === 'KeyM') uiStore.setTheme('matrix')
  })

  const router = createHashRouter([
    {
      path: '/',
      element: <Root />,
    },
    {
      path: 'order-book',
      element: <OrderBookPage />,
    },
    {
      path: 'capacities/:areas',
      element: <CapacityPage />,
    },
    {
      path: 'auctions',
      element: <Auctions />
    },
    {
      path: 'spread',
      element: <SpreadHelper />
    },
  ])

  return (
    <div className="app" data-theme={theme || 'dark'}>
      <AuthenticatedTemplate>
        <ToastContainer />
        <RouterProvider router={router} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="app-container" style={{ height: '90vh' }} />
      </UnauthenticatedTemplate>
    </div>
  )
})

export default App
