import { useEffect, useState } from 'react'
import type { Portfolio } from '../../classes/Portfolio'
import { getPortfolios } from '../../actions/Portfolios'
import { errorHandler } from './errorHandler'
import { useAbortController } from '../../hooks/useAbortController'
import type { Moment } from 'moment'
import { type SpreadTrade, spreadTradeFromEvent } from '../../classes/SpreadTrade'
import { useSubscribe } from '../../hooks/useSubscribe'
import type { TradeEvent } from '../../classes/Order'
import { toast } from 'react-toastify'
import { getTrades } from '../../actions/Interconnectors'

// Get all portfolios, is empty list until the state is fetched
const usePortfolios = () => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>([])

  useEffect(() => {
    getPortfolios()
      .then(portfolios => setPortfolios(portfolios))
      .catch(errorHandler('Could not get portfolios'))
  }, [])

  return portfolios
}

const IDC = 'IDC'

// Keep a list of spread trades
const useSpreadTrades = (date: Moment): SpreadTrade[] => {
  const portfolios = usePortfolios()
  const [trades, setTrades] = useState<SpreadTrade[] | undefined>(undefined)

  useSubscribe('/topic/trade', (event: TradeEvent) => {
    if (!event.tradeHandle.includes(IDC)) return
    const currency = portfolios.find(p => p.eic === event.areaId)?.currency
    if (!currency) {
      // On the off-chance that we do not have the portfolios in yet. (Slow service, should not happen really)
      toast.error('Could not get currency for IDC trade. Refresh window.')
      return
    }
    setTrades(prev => [...prev!.filter(t => t.id !== event.tradeNo), spreadTradeFromEvent(event, currency)])
  })

  useAbortController(controller => {
    getTrades(date, controller.signal)
      .then(data => setTrades(data))
      .catch(errorHandler('Could not get trades'))
  }, [date])

  return trades ?? []
}

export {
  usePortfolios,
  useSpreadTrades
}