/**
 * This class represents an Bid Entry. It is used to create 
 * new IOC orders for NUCS. 
 */
export class BidEntry {
   constructor(
     public productId: string,
     public deliveryStart: string,
     public contractId: string,
     public bids: Bid[]
   ) {}
 }
 
 export interface Bid {
   minChange: number;
   quantity: number;
   limit: number;
 }


// /**
//  * The bidEntry in field and in the state always differ as
//  * some values are not present in the field. This function flattens
//  * and uses the arraysEqual function to compare the two arrays.  
// */
export function bidEntryEquals(a: any, b: any): boolean {
  if (a === b) return true;

  if (typeof a !== typeof b) return false;

  if (typeof a !== "object" || a === null || b === null) return false;

  if (Array.isArray(a) !== Array.isArray(b)) return false;

  if (Array.isArray(a)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!bidEntryEquals(a[i], b[i])) return false;
    }
    return true;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  
  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key)) return false;
    if (!bidEntryEquals(a[key], b[key])) return false;
  }

  return true;
}