import React, { type FunctionComponent } from 'react'
import './table.scss'

export interface CellProps {
  children?: JSX.Element | JSX.Element[] | any
  className?: string
  onClick?: () => void
  onInput?: (e: any) => void
  contentEditable?: boolean
  onBlur?: () => void
  onMouseDown?: () => void
  onDragStart?: React.DragEventHandler<HTMLDivElement>
  onDragEnd?: React.DragEventHandler<HTMLDivElement>
  reference?: React.MutableRefObject<any>
  title?: string
}

interface RowProps {
  highlighted?: boolean,
  onClick?: () => void,
  className?: string,
  children?: JSX.Element | JSX.Element[]
}

export const Row: FunctionComponent<RowProps> = ({ highlighted, onClick, className, children }) => {
  // Concat className
  const cn = 'row ' + (highlighted ? ' highlighted' : '') + (className ?? '')
  return <div className={cn} onClick={onClick}>{children}</div>
}

export const Cell: FunctionComponent<CellProps> = ({
  children,
  className,
  onClick,
  onMouseDown,
  reference,
  title
}) => (
  <div
    className={`cell ${className || ''}`}
    onClick={onClick}
    onMouseDown={onMouseDown}
    title={title}
    ref={reference}
  >
    {children}
  </div>
)

export const HCell: FunctionComponent<CellProps> = ({
  children,
  className,
  onClick,
  onInput,
  contentEditable = false,
  onBlur,
  onDragEnd,
  onDragStart
}) => (
  <div
    suppressContentEditableWarning
    onInput={onInput}
    contentEditable={contentEditable}
    className={`header-cell ${className || ''}`}
    onClick={onClick}
    onBlur={onBlur}

    // draggable stuff
    draggable={onDragStart !== undefined}
    onDragEnd={onDragEnd}
    onDragStart={onDragStart}
    // If this element is draggable, the other ones are as well
    onDragOver={(e) => { if (onDragEnd !== undefined) e.preventDefault() } }
  >
    {children}
  </div>
)
