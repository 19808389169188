import { type RoundingStrategy } from '../../../classes/Interconnector'
import BigNumber from 'bignumber.js'

const getRoundingMode = (roundingStrategy: RoundingStrategy) => {
  switch (roundingStrategy) {
    case 'NORMAL':
      return BigNumber.ROUND_HALF_UP
    case 'DOWN':
      return BigNumber.ROUND_DOWN
    case 'BANKERS':
      return BigNumber.ROUND_HALF_EVEN
  }
}

// Calculate the new position based on the old position and the amount requested by trader
const calculatePosition = (midPointNomination: number, lossFactor: number, roundingStrategy: RoundingStrategy) => {
  const allocation = BigNumber(midPointNomination).times(BigNumber(0.001))
  const loss = BigNumber(1).plus(BigNumber(lossFactor).times(BigNumber(0.01)))
  const withLossFactor = allocation.times(loss).dp(1, getRoundingMode(roundingStrategy))

  return withLossFactor.times(BigNumber(1000)).toNumber()
}

// Calculate the position to take based on the mid-interconnector nomination value
export const curriedCalculatePosition = (lossFactor: number, roundingStrategy: RoundingStrategy) => (midPointNomination: number) =>
  calculatePosition(midPointNomination, lossFactor, roundingStrategy)