import type { Moment } from 'moment'
import type { Portfolio } from '../../../classes/Portfolio'
import React, { type FC } from 'react'
import { DeliveryPeriod } from './DeliveryPeriod'
import { TradingDirection } from './TradingDirection'
import './intraday-auction-info.scss'


interface IntradayAuctionInfoProps {
  start: Moment,
  end: Moment,
  exportPortfolio: Portfolio,
  importPortfolio: Portfolio
}

// This component renders relevant information for an IDC auction. That is, delivery period and portfolios
const IntradayAuctionInfo: FC<IntradayAuctionInfoProps> = ({ start, end, exportPortfolio, importPortfolio }) => {
  return <div className='intraday-auction-info'>
    <DeliveryPeriod start={start} end={end} />
    <TradingDirection exportPortfolio={exportPortfolio} importPortfolio={importPortfolio} />
  </div>
}

export default IntradayAuctionInfo