import { makeAutoObservable } from 'mobx'

export class Timing {
  userDefined = true

  constructor(
    public trigger: string,
    public start: string,
    public end: string
  ) {}
}

export class TimingStore {
  private timings: Timing[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setTimings(timings: Timing[]) {
    this.timings = timings
  }

  get getTimings() {
    return this.timings
  }
}
