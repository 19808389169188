import { makeAutoObservable } from 'mobx'

export class ManualOrderPlacerStore {
  contractId?: string = undefined
  quantity: string = ''
  price: string = ''
  buy: boolean = false
  submitTrade: (isBuy: boolean, price: number, qty: number) => void = () => {}
  triggerCounter = -1
  areaId?: string

  constructor() {
    makeAutoObservable(this)
  }

  setQuantity(quantity: string) {
    this.quantity = quantity
  }

  setPrice(price: string) {
    this.price = price
  }

  setBuy(buy: boolean) {
    this.buy = buy
  }

  setContractId(contractId?: string) {
    this.contractId = contractId
  }

  setSubmitTrade(submitTrade: (isBuy: boolean, price: number, qty: number) => void) {
    this.submitTrade = submitTrade
  }

  setAreaId(areaId: string) {
    this.areaId = areaId
  }

  incrementTriggerCounter() {
    this.triggerCounter += 1
  }

  resetTriggerCounter() {
    this.triggerCounter = -1
  }
}
