import { useEffect, type DependencyList } from "react";

type KeyListener = (() => void) | ((k: KeyboardEvent) => void)


/**
 * Use keydown event listener for one or several keys
 * @param keyCode the key we want to react to
 * @param callback the function we call if the key is invoked
 * @param deps the dependencies for the internal useEffect call that registers the eventlistener
 */
const useKeyDownListener = (keyCode: string | string[], callback: KeyListener, deps?: DependencyList | undefined) => {
  // If it is string, put it into array, else use array as is.
  const keyCodeArray = Array.isArray(keyCode) ? keyCode : [keyCode]

  useEffect(() => {
    const handleKeyPress = (key: KeyboardEvent) => {
      if (keyCodeArray.includes(key.code)) callback(key)
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => { window.removeEventListener('keydown', handleKeyPress) }
  }, [deps ?? []])
}

export default useKeyDownListener