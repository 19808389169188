import {
  type OrderEvent,
  type StrategyInvocation,
  type TradeEvent,
} from '../classes/Order'
import { makeAutoObservable } from 'mobx'

export class OrderStore {
  public strategies: StrategyInvocation[] = []
  public orders: OrderEvent[] = []
  public trades: TradeEvent[] = []
  public strategyToOrders: Record<string, OrderEvent[]> = {} // Map strategy id to orders
  public strategyToTrades: Record<string, TradeEvent[]> = {} // Map strategy id to trades
  public tradeHandles: Set<string> = new Set<string>()

  constructor() {
    makeAutoObservable(this)
  }

  addStrategyInvocation = (strategy: StrategyInvocation) => {
    // Filter out the orders under same strategy with same direction
    const filtered = this.strategies.filter(
      (s) =>
        !(s.id === strategy.id && s.invocation.buy === strategy.invocation.buy)
    )
    filtered.push(strategy)
    this.strategies = filtered
    this.tradeHandles.add(strategy.invocation.tradeHandle)
  }

  // If it exists, remove old order with same id and add new, else just add
  updateOrders = (order: OrderEvent) => {
    if (order.state === 'PENDING') return // Do not add pending, as they move screen around and no one cares.

    const existing = this.orders.find(
      (o) => o.orderId === order.orderId && o.sequenceNo <= order.sequenceNo
    )
    if (existing != null) {
      const filtered = this.orders.filter((o) => o.orderId !== order.orderId)
      this.orders = [...filtered, order]
    }
    else if (existing != null && order.state === 'IACT') {
      const filtered = this.orders.filter((o) => o.orderId !== order.orderId)
      this.orders = [...filtered]
    }
    else {
      this.orders = [...this.orders, order]
    }

    // Update strategy to orders
    const strategyId = order.strategyId
    const existingStrategyOrders = this.strategyToOrders[strategyId] ?? []

    if (existing !== null) {
      const filtered = existingStrategyOrders.filter((o) => o.orderId !== order.orderId)

      if (order.state !== 'IACT')
        this.strategyToOrders[strategyId] = [...filtered, order]
      else
        this.strategyToOrders[strategyId] = [...filtered]
    }
    else {
      this.strategyToOrders[strategyId] = [...existingStrategyOrders, order]
    }
  }

  updateTrades = (trade: TradeEvent) => {
    const filtered = this.trades.filter((t) => t.tradeNo !== trade.tradeNo)
    filtered.push(trade)
    this.trades = filtered

    // Update strategy to trades
    const strategyId = trade.strategyId
    const existing = this.strategyToTrades[strategyId] ?? []
    const strategyTradesFiltered = existing.filter(
      (t) => t.tradeNo !== trade.tradeNo
    )
    strategyTradesFiltered.push(trade)
    this.strategyToTrades[strategyId] = strategyTradesFiltered
  }
}
