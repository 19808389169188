import { type FunctionComponent, type MouseEvent } from 'react'
import './tooltip.scss'
import { type Dictionary, type StrategyInvocation } from '../../classes/Order'
import { type Variables, upperCaseFirstLetter } from '../Executor/Executor'

const direction = (padding: number, buy: boolean) =>
  `${'Direction:'.padEnd(padding, ' ')}${buy ? '   Buy\n' : '  Sell\n'}`

const trigger = (padding: number, trig: string) =>
  'Trigger:'.padEnd(padding, ' ') + `${trig.padStart(6, ' ')}\n`

/**
 * Format a dictionary of strategy parameters
 * @param dict
 * @param buy
 */
export const formatDictionary = (dict: Variables, buy: boolean) => {
  // We could get divisor from Executor component, but it is too much hassle
  // TODO: Re-write to be more generic
  const divisor = (s: string) => {
    s = s.toLowerCase()
    if (s.includes('limit')) return 100
    if (s.includes('price')) return 100
    if (s.includes('increment')) return 100
    if (s.includes('initialvalue')) return 100
    if (s.includes('quantity')) return 1000
    if (s.includes('depth')) return 1000
    return 1
  }
  const paddingLength =
    Math.max(...Object.keys(dict).map((key) => key.length)) + 2 // For colon and 1 space
  const triggerString =
    dict.trigger !== undefined
      ? trigger(paddingLength, dict.trigger as string)
      : ''
  return (
    direction(paddingLength, buy) +
    triggerString +
    Object.keys(dict)
      .filter((param) => param !== '@type' && param !== 'trigger') // Skip type and NaN values
      .map((param) => {
        if (Array.isArray(dict[param])) {
          const listElems: Array<Dictionary<number | string>> = (dict as any)[
            param
          ]

          return (
            `${param}: ${(dict as any)[param].length} \n---\n` +
            listElems
              .map((elem) =>
                Object.entries(elem)
                  .map(([key, value]) => {
                    return (
                      `${key}: ` + (Number(value) / divisor(key)).toFixed(2)
                    )
                  })
                  .join('\n')
              )
              .join('\n\n') +
            '\n---\n'
          )
        }

        return (
          `${param}:`.padEnd(paddingLength, ' ') +
          (Number(dict[param]) / divisor(param)).toFixed(2).padStart(6, ' ')
        )
      })
      .map((str) => upperCaseFirstLetter(str))
      .join('\n')
  )
}

interface TooltipProps {
  event?: MouseEvent
  strategy?: StrategyInvocation
}

const Tooltip: FunctionComponent<TooltipProps> = ({ event, strategy }) => {
  return event === undefined || strategy === undefined ? (
    <div />
  ) : (
    <pre
      className="tooltip"
      onMouseMove={(e) => {
        e.preventDefault()
      }}
      style={{
        top: event.pageY,
        left: event.pageX,
      }}
    >
      {formatDictionary(strategy.invocation.variables, strategy.invocation.buy)}
    </pre>
  )
}

interface TooltipTextProps {
  event?: MouseEvent
  text?: string
}

export const TooltipText: FunctionComponent<TooltipTextProps> = ({
  event,
  text,
}) => {
  return event === undefined || text === undefined ? (
    <div />
  ) : (
    <pre
      className="tooltip"
      onMouseMove={(e) => {
        e.preventDefault()
      }}
      style={{
        top: event.pageY,
        left: event.pageX,
      }}
    >
      {text}
    </pre>
  )
}

export default Tooltip
