import { type AllocationBid, type BidValue } from '../../classes/OrderPlan'
import React, { type ChangeEvent, type CSSProperties, type FC, Fragment } from 'react'
import '../CurveTable/curve-table.scss'
import { copyToClipboard, Multiplier } from '../../Util'
import { IoCopyOutline } from 'react-icons/io5'
import { observer } from 'mobx-react'
import { type AllocationAuctionStore, type TableDict } from '../../pages/Auctions/AllocationMenu/AllocationAuctionStore'
import { BidHeader } from './BidHeader'
import { ModifiableCell } from './ModifiableCell'
import { type Style } from '../CurveTable/CurveTable'

interface AllocationTableProps {
  store: AllocationAuctionStore
  height: number
  style?: { useColoring?: boolean }
}

// Convert timestamp (as string) into power hour (PH) notation.
const getDisplayName = (s: string) => {
  return `PH${(new Date(s).getHours() + 1).toString().padStart(2, '0')}`
}

// Multiplies number by multiplier and checks that is has max decimals specified
const validateNumber = (multiplier: number, decimals: number) => (value: number) => {
  const decimalCheck = multiplier / Math.pow(10, decimals)
  return Math.round(value * multiplier / decimalCheck) * decimalCheck
}

// Will divide numerator by denominator if numerator is there, else returns undefined
const nullableDivideBy = (numerator: number | undefined, denominator: number) =>
  numerator === undefined ? undefined : numerator / denominator

// Write table value to string for Excel
const writeToString = (columns: AllocationBid[], rows: string[], modification: TableDict) => {
  const headers = ['', ...columns.map(c => `${c.name}\t`)].join('\t') // Double spanned
  const subHeaders = ['', ...columns.map(c => ['Quantity', 'Price'].join('\t'))].join('\t')

  const rowLines = rows.map(row => {
    const name = getDisplayName(row)

    const values = columns.map(column => {
      const bidValue = modification[column.name][row]
      return `${(bidValue.quantity / Multiplier.MW).toLocaleString()}\t${(bidValue.price / Multiplier.EUR).toLocaleString('da-DK')}`
    })

    return [name, ...values].join('\t')
  })
  return [headers, subHeaders, ...rowLines].join('\n')
}

// For cell spanning two grid columns
const span = (n: number): CSSProperties => ({ gridColumn: `span ${n}` })


const AllocationTable: FC<AllocationTableProps> = observer(({ store, height, style }) => {
  // If bids have different statuses, show in UI
  const showIndividualStatus = new Set(store.actualBids.map(bid => bid.status)).size > 1

  // Get all columns/rows in sorted order
  const columns = store.actualBids.sort((a, b) => a.name.localeCompare(b.name))
  const rows = store.rows

  // Dictionary of bids and dictionary of ongoing modifications
  const { tableDictionary, modification, offeredCapacities } = store

  const modify = (direction: string, row: string, validate: (n: number) => number, key: keyof Omit<BidValue, 'deliveryStart'>) => (e: ChangeEvent<HTMLInputElement>) => {
    store.modifyCell(direction, row, key, validate(Number(e.target.value)))
  }

  // Set the number of columns based on if we have offered capacity to show user
  const columnsStyle = offeredCapacities ? columns.length * 3 + 1 : columns.length * 2 + 1

  return (
    <div
      style={{ '--columns': columnsStyle, '--rows': rows.length + 3, height } as Style}
      className='grid-table'
    >
      { /** Add column headers */}
      <>
        <div className='h l cell' id='corner-cell' onClick={() => {
          copyToClipboard(writeToString(columns, rows, modification))
        }}>
          <IoCopyOutline title={'Copy to clipboard'} />
        </div>
        {columns.map(d => {
          return <div key={`col-${d.bidId}`} className='h cell' style={span(offeredCapacities ? 3 : 2)}>
            <BidHeader store={store} showIndividualStatus={showIndividualStatus} bid={d} />
          </div>
        })}
        <div className='h l cell'></div>
        {columns.map(d => <Fragment key={d.bidId}>
          {offeredCapacities && (
            <div className='h cell'>{'Capacity'}<span style={{ fontWeight: 'bold' }}> (MW)</span></div>
          )}
          <div className='h cell'>{'Quantity'}<span style={{ fontWeight: 'bold' }}> (MW)</span></div>
          <div className='h cell'>{'Bid'}<span style={{ fontWeight: 'bold' }}> (EUR)</span></div>
        </Fragment>)}
      </>
      { /** Add body */}
      <>
        {rows.map(row => {
          const displayName = getDisplayName(row)
          return (
            <Fragment key={displayName}>
              <div className='l cell'>{displayName}</div>
              {columns.map(column => {
                const direction = column.name
                const value = tableDictionary[direction][row]
                const modified = modification[direction][row]

                // Get the offered capacity for the hour, else the string '-' is used. Is only shown if the capacities are defined
                const offeredCapacity = nullableDivideBy(offeredCapacities?.get(direction)?.get(row), Multiplier.MW) ?? '-'

                return <Fragment key={column.bidId}>
                  {offeredCapacities && <div className='cell'>{offeredCapacity}</div>}
                  <ModifiableCell
                    className='cell'
                    disabled={!store.modifyEnabled}
                    original={value.quantity}
                    modified={modified.quantity}
                    divisor={Multiplier.MW}
                    equal={(a, b) => a === b}
                    onChange={modify(direction, row, validateNumber(Multiplier.MW, 1), 'quantity')}
                  />
                  <ModifiableCell
                    className='cell'
                    disabled={!store.modifyEnabled}
                    original={value.price}
                    modified={modified.price}
                    divisor={Multiplier.EUR}
                    equal={(a, b) => a === b}
                    onChange={modify(direction, row, validateNumber(Multiplier.EUR, 2), 'price')}
                  />
                </Fragment>
              })}
            </Fragment>
          )
        })
        }
      </>

      { /** Add sums */}
      <>
        <div className='f l cell'>Total exposure</div>
        {columns
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(column => {
            const originalMwExposure = column.bidValues.reduce((acc, value) => acc + value.quantity, 0)
            const originalExposure = column.bidValues.reduce((acc, value) => acc + value.quantity * value.price, 0)

            // If modification is ongoing
            const mwExposure = Object.values(modification[column.name]).reduce((acc, value) => acc + value.quantity, 0)
            const exposure = Object.values(modification[column.name]).reduce((acc, value) => acc + value.quantity * value.price, 0)
            const totalCapacity = store.summedOfferedCapacity?.get(column.name)

            return <Fragment key={column.bidId}>
              {offeredCapacities && <div className='f cell'>{`${totalCapacity} MW`}</div>}
              <div
                className='f cell'
                style={{ display: 'flex' }}
              >
                <div
                  style={{
                    visibility: mwExposure !== originalMwExposure ? 'visible' : 'hidden',
                    fontStyle: 'italic',
                    color: 'var(--text-secondary)',
                    width: '25%',
                  }}
                >
                  {originalMwExposure / (Multiplier.MW)}
                </div>
                <div
                  style={{
                    justifySelf: 'center',
                    width: '50%',
                    whiteSpace: 'nowrap',
                  }}
                >{`${mwExposure / (Multiplier.MW)} MW`}</div>
              </div>
              <div
                className='f cell'
                style={{ display: 'flex' }}
              >
                <div
                  style={{
                    visibility: exposure !== originalExposure ? 'visible' : 'hidden',
                    fontStyle: 'italic',
                    color: 'var(--text-secondary)',
                    width: '25%',
                  }}
                >
                  {(originalExposure / (Multiplier.MW * Multiplier.EUR)).toFixed(2)}
                </div>
                <div
                  style={{
                    justifySelf: 'center',
                    width: '50%',
                    whiteSpace: 'nowrap',
                  }}
                >{`${(exposure / (Multiplier.MW * Multiplier.EUR)).toFixed(2)} EUR`}</div>
              </div>
            </Fragment>
          })}
      </>
    </div>
  )
})

export default AllocationTable