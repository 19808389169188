import type React from 'react';
import { useEffect } from 'react'

// Use a use effect hook with an abort controller. For cancelling http requests mostly.
export const useAbortController = (call: (controller: AbortController) => void, deps: React.DependencyList = []) => {
  useEffect(() => {
    const controller = new AbortController()
    call(controller)
    return () => { controller.abort() }
  }, deps)
}