import { type SocketStore } from './SocketStore'
import { makeAutoObservable, reaction } from 'mobx'
import { type IMessage } from '@stomp/stompjs'
import { type ConnectionStatus } from '../classes/ConnectionStatus'
import axiosInstance from '../axiosInstance'
import { toast } from 'react-toastify'

export interface ConnectionStatusResponse {
  status: ConnectionStatus
  title?: string
}


export class ConnectionStore {

  private readonly socketStore: SocketStore
  private backendConnectionStatus: ConnectionStatus = 'DISCONNECTED'
  public backendConnectionChanged: number = 0

  constructor(socketStore: SocketStore) {
    this.socketStore = socketStore

    makeAutoObservable(this)

    reaction(
      () => this.socketStore.connected,
      (now, prev) => {
        if (now && !prev) {
          this.socketStore.socket.subscribe('/topic/connection-status', this.handleStatus)
        }
      })

    reaction(
      () => this.socketStore.token,
      (now, prev) => {
        if (now && !prev) {
          // Check if backend is connected
          axiosInstance
            .get<ConnectionStatus>('/api/status/connection-status')
            .then((res) => {
              if (res.data !== 'CONNECTED') {
                toast.error('Backend not connected to Nord Pool', { autoClose: false })
              }
              this.setBackendConnectionStatus(res.data)
            })
        }
      }
    )

    // This is to notify components to refresh state after connection has been restored
    reaction(
      () => this.backendConnectionStatus,
      (now, prev) => {
        if (now === 'CONNECTED' && prev !== 'CONNECTED') {
          this.setBackendConnectionChanged(Math.random())
        }
      }
    )

  }

  setBackendConnectionStatus(status: ConnectionStatus) {
    this.backendConnectionStatus = status
  }

  setBackendConnectionChanged(num: number) {
    this.backendConnectionChanged = num
  }

  /** Get connection status, along with reason */
  get connectionStatus(): ConnectionStatusResponse {
    // If socket is connected, we show the status of backend. If not, we show status of ws connection
    if (this.socketStore.connected) {
      if (this.backendConnectionStatus === 'CONNECTED') {
        return { status: 'CONNECTED' }
      }
      else if (this.backendConnectionStatus === 'CONNECTING') {
        return { status: 'CONNECTING', title: 'Backend is connecting to Nord Pool' }
      }
      else {
        return { status: 'DISCONNECTED', title: 'Backend is disconnected from Nord Pool'}
      }
    } else {
      return { status: 'DISCONNECTED', title: 'UI not connected to backend' }
    }
  }

  private readonly handleStatus = (message: IMessage) => {
    this.backendConnectionStatus = JSON.parse(message.body) as ConnectionStatus
  }
}