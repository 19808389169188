import type { TradeEvent } from './Order'
import type { Currency } from './Currency'
import type { Direction } from './BaseTypes'

export interface SpreadTrade {
  id: string,
  area: string,
  contractId: string,
  quantity: number,
  unitPrice: number,
  currency: Currency,
  side: Direction,
  label: string
}

export const spreadTradeFromEvent = (trade: TradeEvent, currency: Currency = 'EUR'): SpreadTrade => {
  return {
    id: trade.tradeNo,
    area: trade.areaId,
    contractId: trade.contractId,
    quantity: trade.quantity,
    unitPrice: trade.price,
    currency,
    side: trade.direction,
    label: trade.tradeHandle,
  }
}