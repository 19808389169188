import { observer } from 'mobx-react'
import { Cell } from '../Table/Table'
import { formatPrice, formatQty, type RowEntry } from './OrderBookTable'
import { useStores } from '../../use-stores'
import { Ownership } from '../../classes/Ownership'
import { type FC } from 'react'

interface OrderBookRowProps {
  row?: RowEntry
  submitTrade: (isBuy: boolean, price?: number, qty?: number) => Promise<void>
  submitBoCTrade: (isBuy: boolean, limit?: number, qty?: number) => Promise<void>
  contractId: string
  quantities: number[]
  isBidRow: boolean
  areaId: string
}

// Map from ownership to corresponding className
const ownershipMap = new Map<Ownership, string>([
  [Ownership.OWN, 'own'],
  [Ownership.COMPANY, 'company'],
  [Ownership.OTHER, ''],
])

const OrderBookRow: FC<OrderBookRowProps> = observer(
  ({ row, submitTrade, submitBoCTrade, contractId, quantities, isBidRow, areaId }) => {
    const { manualOrderPlacerStore, orderBookStore } = useStores()
    const { directOrdersEnabled, quantityLineEnabled } = orderBookStore

    function handleManualOrder(bid: boolean, price: number, qty: number) {
      return () => {
        manualOrderPlacerStore.setSubmitTrade(submitTrade)
        manualOrderPlacerStore.setContractId(contractId)
        manualOrderPlacerStore.setBuy(!bid)
        manualOrderPlacerStore.setPrice((price / 100).toString())
        manualOrderPlacerStore.setQuantity((qty / 1000).toString())
        manualOrderPlacerStore.setAreaId(areaId)
        manualOrderPlacerStore.incrementTriggerCounter()
      }
    }

    // For CSS properties
    const ownership = row?.ownership !== undefined ? ownershipMap.get(row.ownership) : ''
    const quantityLine = quantityLineEnabled && row?.fillsQty ? 'quantity' : ''

    if (isBidRow) {
      return (
        <>
          {row?.qty === undefined ? (
            <>
              {directOrdersEnabled && <Cell />}
              <Cell />
              <Cell />
              <Cell />
            </>
          ) : (
            <>
              {directOrdersEnabled && (
                <Cell>
                  <div
                    onMouseDown={() => {
                      submitTrade(true, row.price + 1, quantities[6]) // Cast is safe since we know that price is defined
                    }}
                    className="button"
                  >
                    DoP
                  </div>
                </Cell>
              )}
              <Cell>
                <div
                  onMouseDown={async () => {
                    await submitBoCTrade(false, row.price, quantities[0])
                  }}
                  className="button"
                >
                  BoC
                </div>
              </Cell>
              <Cell>
                <div
                  onMouseDown={async () => {
                    await submitTrade(false, row.price, quantities[1])
                  }}
                  className="button"
                >
                  {formatQty(quantities[1])}
                </div>
              </Cell>
              <Cell>
                <div
                  onMouseDown={async () => {
                    await submitTrade(false, row.price, quantities[2])
                  }}
                  className="button"
                >
                  {formatQty(quantities[2])}
                </div>
              </Cell>
            </>
          )}
          <div className={`px-qty-wrap clickable ${ownership} ${quantityLine}`}>
            <Cell onClick={handleManualOrder(true, row?.price || 0, row?.qty || 0)} className={`non-button-cell`}>
              {row?.qty !== undefined ? formatQty(row.qty) : '-'}
            </Cell>
            <Cell onClick={handleManualOrder(true, row?.price || 0, row?.qty || 0)} className={`non-button-cell`}>
              {`${row?.price !== undefined ? formatPrice(row.price) : '-'}`}
            </Cell>
          </div>
        </>
      )
    }

    return (
      <>
        <div className={`px-qty-wrap clickable ${ownership} ${quantityLine}`}>
          <Cell onClick={handleManualOrder(false, row?.price || 0, row?.qty || 0)} className={`non-button-cell`}>
            {row?.price !== undefined ? formatPrice(row?.price) : '-'}
          </Cell>
          <Cell onClick={handleManualOrder(false, row?.price || 0, row?.qty || 0)} className={`non-button-cell`}>
            {row?.qty !== undefined ? formatQty(row?.qty) : '-'}
          </Cell>
        </div>
        {row?.qty === undefined ? (
          <>
            <Cell />
            <Cell />
            <Cell />
            {directOrdersEnabled && <Cell />}
          </>
        ) : (
          <>
            <Cell>
              <div
                onMouseDown={async () => {
                  await submitTrade(true, row.price, quantities[3])
                }}
                className="button"
              >
                {formatQty(quantities[3])}
              </div>
            </Cell>
            <Cell>
              <div
                onMouseDown={async () => {
                  await submitTrade(true, row.price, quantities[4])
                }}
                className="button"
              >
                {formatQty(quantities[4])}
              </div>
            </Cell>
            <Cell>
              <div
                onMouseDown={async () => {
                  await submitBoCTrade(true, row.price, quantities[5])
                }}
                className="button"
              >
                BoC
              </div>
            </Cell>
            {directOrdersEnabled && (
              <Cell>
                <div
                  onMouseDown={() => {
                    submitTrade(false, row.price - 1, quantities[7]) // Cast is safe since we know that price is defined
                  }}
                  className="button"
                >
                  DoP
                </div>
              </Cell>
            )}
          </>
        )}
      </>
    )
  }
)

export default OrderBookRow
