import { observer } from 'mobx-react'
import { type MultiProduct } from './MultiProduct'
import { useStores } from '../../../use-stores'

interface MultiProductCounterProps {
  multiProduct: MultiProduct
}

const MultiProductCounter = observer(({ multiProduct }: MultiProductCounterProps) => {
  const deliveryAreaId = multiProduct.deliveryArea

  if (deliveryAreaId == null) {
    return null
  }

  const { configStore } = useStores()
  const { portfolios } = configStore

  const portfolio = portfolios.find((p) => p.eic === deliveryAreaId)
  const portfolioName = portfolio ? portfolio.name.split('-')[1] : deliveryAreaId

  return (
    <p>
      {multiProduct.contractIds.length} in {portfolioName}
    </p>
  )
})

export default MultiProductCounter
